import React from 'react';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: 66,
    width: '100%',
    display: 'block',
  },
}));

function BodyBottomOffset() {
  const classes = useStyles();

  return (
    <div className={classes.root}/>
  );
}

BodyBottomOffset.propTypes = {
};

export default BodyBottomOffset;