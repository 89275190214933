import React from 'react';
import {createMuiTheme} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  ThemeProvider,
  // StylesProvider,
} from '@material-ui/styles';
import {createConfigProvider} from 'lib/Core/ConfigProvider';
import {Provider} from 'react-redux';
import theme from './theme';
import Routes from './Routes';
import store, {getHistory} from './store';
import I18nLangProvider from 'component/I18nLang/Context';
import config from './config';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import getEnv from './tanggram';

/**
 * Setup bugsnag.
 */
// const bugsnagClient = bugsnag({
//     apiKey: getEnv().bugsnagApiKey,
//     appVersion: getEnv().appVersion
//   }).use(bugsnagReact, React);
Bugsnag.start({
  apiKey: getEnv().bugsnagApiKey,
  appVersion: getEnv().appVersion,
  plugins: [new BugsnagPluginReact()],
});
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const ConfigProvider = createConfigProvider(config);

/**
 * Create the app.
 */
function App(props) {
  // if (!isMobile()) {
  //   return (
  //     <DesktopErrorPage/>
  //   );
  // }

  const muiTheme = createMuiTheme(theme);

  return (
    <ErrorBoundary>
      {/*<StylesProvider injectFirst>*/}
        <CssBaseline />
        <ThemeProvider theme={muiTheme}>
          <ConfigProvider>
            <Provider store={store}>
              <I18nLangProvider>
                <Routes history={getHistory()}/>
              </I18nLangProvider>
            </Provider>
          </ConfigProvider>
        </ThemeProvider>
      {/*</StylesProvider>*/}
    </ErrorBoundary>
  );
}

export default App;
