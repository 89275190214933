import {put, takeLeading} from 'redux-saga/effects';
import createState from './state';
import {dataStatus, transformApiData} from '../transform';
import {isCustomer, isMerchant, isAdmin} from 'logic/service/auth';
import {
  getApiResponse,
  handleError,
  showLoading,
  hideLoading,
} from '../actions';
import {TNumber, TString, TBool, TObject, declareObject, declareTypes} from 'lib/Core/prop_types';
import TDataStatus from '../data_status';
import {takeOneExclusive} from '../helper';
import getS3ImagePath from 'logic/utils/getS3ImagePath';
import verifyAuthSession from '../../service/api/method/verify_auth_session';
// import useLogin from '../../service/api/method/user_login';
import defaultValue from 'logic/utils/defaultValue';

export const TUser = declareObject({
  id: TNumber,
  identity: declareTypes([
    TString,
    TNumber,
  ]).isRequired,
  isActive: declareTypes([TBool, TNumber]).isRequired,
  isEmailVerified: declareTypes([TBool, TNumber]).isRequired,
  isMobileVerified: declareTypes([TBool, TNumber]).isRequired,
  isPinEnabled: declareTypes([TBool, TNumber]).isRequired,
  email: TString,
  createdAt: declareTypes([TString, TObject]),
  updatedAt: declareTypes([TString, TObject]),
  mobile: TString,
  avatar: TString,
  nickname: TString,
});

export const TUserSession = declareObject({
  status: TDataStatus.isRequired,
  isLoggedIn: TBool.isRequired,
  user: TUser.isRequired,
  roles: declareObject({
    isCustomer: TBool.isRequired,
    isMerchant: TBool.isRequired,
    isAdmin: TBool.isRequired,
  }).isRequired,
});

export const userSessionLoading = 'userSessionLoading.1';

export default createState(
  'userSession',
  {
    status: dataStatus.INIT,
    isLoggedIn: false,
    user: {
      identity: '',
      isActive: false,
      isEmailVerified: false,
      isMobileVerified: false,
      isPinEnabled: false,
      email: '',
      mobile: '',
      avatar: '',
      nickname: '',
    },
    roles: {
      isCustomer: false,
      isMerchant: false,
      isAdmin: false,
    },
  },
  ({addSaga, reduce, reduceError}) => {
    addSaga(function* () {
      yield takeOneExclusive(5000, 'SYNC_USER_SESSION', function* (action) {
        try {
          yield put(showLoading());
          const apiData = yield verifyAuthSession();
          yield put(getApiResponse('SYNC_USER_SESSION_R', apiData));
          yield put(hideLoading());
        } catch (e) {
          yield put(handleError('SYNC_USER_SESSION_F', e));
          yield put(hideLoading());
        }
      });
    });

    addSaga(function* () {
      yield takeLeading('SYNC_USER_SESSION_RT', function* (action) {
        try {
          yield put(showLoading(userSessionLoading));
          const apiData = yield verifyAuthSession();
          yield put(getApiResponse('SYNC_USER_SESSION_R', apiData));
          yield put(hideLoading(userSessionLoading));
        } catch (e) {
          yield put(handleError('SYNC_USER_SESSION_F', e));
          yield put(hideLoading(userSessionLoading));
        }
      });
    });

    reduce('SYNC_USER_SESSION_R', function (state, action, initState) {
      let data = {...state};
      const apiData = transformApiData(action.data);
      // data.status = dataStatus.SUCCESS;
      if (apiData.status === dataStatus.SUCCESS) {
        data.status = dataStatus.SUCCESS;
        const user = apiData.data.user;
        if (user && user.mobile) {
          data.isLoggedIn = true;
          data.user = {
            id: user.id,
            identity: user.identity,
            isActive: user.isActive,
            isEmailVerified: user.isEmailVerified,
            isMobileVerified: user.isMobileVerified,
            isPinEnabled: user.isPinEnabled,
            email: user.email,
            mobile: user.mobile,
            avatar: user.avatar,
            nickname: defaultValue(user.nickname, ''),
          };
          data.roles = {
            isCustomer: isCustomer(user),
            isMerchant: isMerchant(user),
            isAdmin: isAdmin(user),
          };
        }else {
          data = {...initState, ...{status: dataStatus.FAILURE}};
        }
      } else {
        data = {...initState, ...{status: dataStatus.FAILURE}};
      }
      if (data.user.avatar === null || data.user.avatar === '') {
        data.user.avatar = getS3ImagePath('/default_avatar.png');
      }
      return data;
    });
    reduceError('SYNC_USER_SESSION_F');
  }
);

export const userSession = {
  sync: () => ({type: 'SYNC_USER_SESSION'}),
  syncRT: () => ({type: 'SYNC_USER_SESSION_RT'}),
};
