import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/styles';
// import {NotificationActiveSVG, NotificationInactiveSVG} from '../SVG';
import withPageControl from '../HOC/withPageControl';
import {TObject, TString} from 'lib/Core/prop_types';
import ShutterModal from '../ShutterModal';
import registry from 'logic/service/registry';
import {TFunction} from 'lib/Core/prop_types';
import {getWindowInnerWidth} from 'viewport';
import Menu from './Menu';
import {SHUTTER_STATE} from '../ShutterModal';
// import RightDrawerMenu from './RightDrawerMenu';
import getImagePath from 'logic/utils/getImagePath';

const useStyles = makeStyles(theme => ({
  '@keyframes rDrawerBtnIn': {
    '0%': {
      opacity: 0,
    },
  },
  '@keyframes rDrawerBtnOut': {
    '0%': {
      opacity: 1,
    },
  },
  '@keyframes rDrawerBtnRightIn': {
    '0%': {
      opacity: 1,
      transform: `rotate(0deg)`,
    },
  },
  '@keyframes rDrawerBtnRightOut': {
    '0%': {
      opacity: 1,
      transform: `rotate(90deg)`,
    },
  },
  list: {
    marginTop: 18,
  },
  b: {
    position: 'absolute',
    bottom: 0.1 * getWindowInnerWidth() + 60,
    marginLeft: 16,
  },
  logo: {
    animation: 'breath 4s linear infinite',
    marginRight: 4,
  },
  ch: {
    color: '#fff',
    marginLeft: 8,
  },
  body: {
  },
  rDrawerBtnIn: {
    position: 'fixed',
    right: 10,
    bottom: 10,
    marginRight: 24,
    marginBottom: 19,
    // width: 50,
    // height: 50,
    zIndex: 1101,
    opacity: 1,
    animation: '$rDrawerBtnIn 0.3s forwards linear',
  },
  rDrawerBtnOut: {
    position: 'fixed',
    right: 10,
    bottom: 10,
    marginRight: 24,
    marginBottom: 19,
    opacity: 0,
    zIndex: -1,
    animation: '$rDrawerBtnOut 0.3s forwards linear',
  },
  rDrawerBtnRightIn: {
    position: 'fixed',
    right: 10,
    bottom: 10,
    marginRight: 24,
    marginBottom: 19,
    zIndex: 1101,
    opacity: 1,
    transform: `rotate(90deg)`,
    animation: '$rDrawerBtnRightIn 0.3s forwards linear',
  },
  rDrawerBtnRightOut: {
    position: 'fixed',
    right: 10,
    bottom: 10,
    marginRight: 24,
    marginBottom: 19,
    zIndex: 1101,
    opacity: 1,
    transform: `rotate(0deg)`,
    animation: '$rDrawerBtnRightOut 0.3s forwards linear',
  },
  notiBtn: {
  },
  rDrawerMenu: {
    marginTop: 60,
    zIndex: 1000,
    marginLeft: 8,
    marginRight: 8,
  },
  nt: {
    color: '#fff',
    textAlign: 'center',
    marginBottom: 8,
  },
  more: {
    color: theme.design.default.dark,
    textAlign: 'center',
    margin: 8,
  }
}));

export function saveDrawerNextState(nextState) {
  registry.register('_MENU_NEXT_STATE', nextState);
}

export function getDrawerNextState() {
  return registry.get('_MENU_NEXT_STATE', SHUTTER_STATE.INIT);
}

/**
 * @return {null}
 */
function Drawer({
  themeColorDark,
  themeColorLight,
  DrawerButtonIcon,
  renderMenuList,
  pageControl,
  location,
  renderTop = () => null,
}) {
  const classes = useStyles();

  const [shutterState, setShutterState] = useState(getDrawerNextState());
  // const [showNotification] = useState(false);
  const [timeoutIndex, setTimeoutIndex] = useState(null);

  useEffect(
    () => {
      return () => {
        clearTimeout(timeoutIndex);
      }
    },
    [
      timeoutIndex,
    ]
  );

  // Reset the menu next state to init once it's set to open previously.
  useEffect(() => {
    if (shutterState === SHUTTER_STATE.OUT) {
      saveDrawerNextState(SHUTTER_STATE.INIT);
      // console.log('reset', getDrawerNextState());
    }
  }, [shutterState]);

  function onDrawerStateUpdate(currentState, nextState) {
    if (nextState === SHUTTER_STATE.IN) {
      openDrawer();
    }
    if (nextState === SHUTTER_STATE.OUT) {
      closeDrawer();
    }
    if (nextState === SHUTTER_STATE.RIGHT_IN) {
      setShutterState(SHUTTER_STATE.RIGHT_IN);
    }
    if (nextState === SHUTTER_STATE.RIGHT_OUT) {
      setShutterState(SHUTTER_STATE.RIGHT_OUT);
    }
  }

  function openDrawer() {
    saveDrawerNextState(SHUTTER_STATE.OUT);
    // console.log('show', getDrawerNextState());
    setShutterState(SHUTTER_STATE.IN);
  }

  function closeDrawer() {
    saveDrawerNextState(SHUTTER_STATE.INIT);
    // console.log('close', getDrawerNextState());
    setShutterState(SHUTTER_STATE.OUT);
  }

  function onMenuItemClick(uri) {
    return () => {
      closeDrawer();
      setTimeout(() => {
        pageControl.toPage(location, uri);
      }, 200);
    };
  }

  function redirectToPage(uri) {
    closeDrawer();
    setTimeoutIndex(setTimeout(() => {
      pageControl.toPage(location, uri);
    }, 200));
  }

  function getIsMenuVisible() {
    return (shutterState !== SHUTTER_STATE.INIT && shutterState !== SHUTTER_STATE.OUT);
  }

  // function toggleRightDrawerButton() {
  //   if (shutterState === SHUTTER_STATE.IN || shutterState === SHUTTER_STATE.RIGHT_OUT) {
  //     onDrawerStateUpdate(shutterState, SHUTTER_STATE.RIGHT_IN);
  //   } else {
  //     onDrawerStateUpdate(shutterState, SHUTTER_STATE.RIGHT_OUT);
  //   }
  // }

  return (
    <ShutterModal
      renderRightDrawerChildren={(currentState) => {
        // function getRightDrawerChildrenClass() {
        //   if (currentState === SHUTTER_STATE.INIT || currentState === SHUTTER_STATE.OUT) {
        //     return classes.rDrawerBtnOut;
        //   } else {
        //     if (currentState === SHUTTER_STATE.RIGHT_IN) {
        //       return classes.rDrawerBtnRightIn;
        //     } else {
        //       return classes.rDrawerBtnRightOut;
        //     }
        //   }
        // }
        // return (
        //   currentState !== SHUTTER_STATE.INIT && (
        //     <div className={getRightDrawerChildrenClass()} onClick={toggleRightDrawerButton}>
        //       {showNotification ? (<NotificationActiveSVG scale={0.6}/>) : (<NotificationInactiveSVG scale={0.6}/>)}
        //     </div>
        //   )
        // );
      }}
      RightDrawerProps={{
        style: {backgroundImage: `radial-gradient(circle, ${themeColorDark}, ${themeColorLight})`},
      }}
      onStateUpdate={onDrawerStateUpdate}
      currentState={shutterState}
      DrawerButtonIcon={DrawerButtonIcon}
    >
      {
        (shutterState !== SHUTTER_STATE.INIT && shutterState !== SHUTTER_STATE.RIGHT_IN) && (
          <Menu visible={getIsMenuVisible()}>
            <div className={classes.body}>
              {renderTop(redirectToPage)}
              <div className={classes.list}>
                {renderMenuList(onMenuItemClick)}
              </div>
              <div className={classes.b}>
                <img src={getImagePath('/asset/img/logo_nc.svg')} alt='logo' width={32} height={32} className={classes.logo}/>
                <img src={getImagePath('/asset/img/tanggram_text_nc.svg')} alt='tanggram' height={20} />
              </div>
            </div>
          </Menu>
        )
      }
      {/*{*/}
      {/*  shutterState === SHUTTER_STATE.RIGHT_IN &&*/}
      {/*  <RightDrawerMenu closeDrawer={closeDrawer}/>*/}
      {/*}*/}
    </ShutterModal>
  );
}

Drawer.propTypes = {
  DrawerButtonIcon: TObject,
  renderMenuList: TFunction,
  themeColorDark: TString,
  themeColorLight: TString,
  renderTop: TFunction,
};

function mapStateToProps(state) {
  return {
  }
}

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(withPageControl(Drawer));