import {saveInLocal, loadFromLocal, removeFromLocal} from '../local_storage';
import callGetSessionTokenApi from '../api/method/get_session_token';
import {dataStatus, transformApiData} from 'logic/redux/transform';

export function createSessionToken({
  id = null,
}) {
  function getId() {
    return id;
  }

  return {
    getId,
  }
}

export async function getSessionTokenSync() {
  const data = await callGetSessionTokenApi();
  const apiData = transformApiData(data);
  if (apiData.status === dataStatus.SUCCESS && apiData.data && apiData.data.access_session_token_id) {
    return createSessionToken({id: apiData.data.access_session_token_id});
  } else {
    return createSessionToken({});
  }
}

const localKey = 'tanggram-access-token';

export function saveSessionTokenLocally(sessionToken) {
  if (sessionToken.getId()) {
    saveInLocal(localKey, sessionToken.getId());
  }
}

export function clearSessionTokenLocally() {
  removeFromLocal(localKey);
}

export function loadSessionTokenLocally() {
  return createSessionToken({id: loadFromLocal(localKey)})
}
