import React from 'react';
import {makeStyles, useTheme} from '@material-ui/styles';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import useConfig from 'lib/Core/hook/useConfig';
import withPageControl from '../HOC/withPageControl';
import {TNumber} from 'lib/Core/prop_types';
import BottomBar from './BottomBar';
import {URI} from 'page/Layout/uri';
import {bottomNavIcon} from '../Icon';
import WalletIcon from 'lib/Icon/Wallet';
import SettingIcon from 'lib/Icon/Setting';
import HomeIcon from 'lib/Icon/Home';

const useStyles = makeStyles({
  bna: {
    padding: 0,
    minWidth: 40,
  },
});

export const CUSTOMER_BN_ITEM_ID = {
  NONE: 0,
  INVENTORY: 1,
  WORLD_GATE: 2,
  SETTING: 3,
};

function BottomNav({
  children,
  pageControl,
  location,
  activeItem = null
}) {
  const config = useConfig();
  const {getSafeAreaBottomHeight} = config;
  const classes = useStyles();
  const theme = useTheme();

  function handleChange(event, value) {
    pageControl.toPage(location, value);
  }

  return (
    <BottomBar style={{
      paddingBottom: getSafeAreaBottomHeight(),
      backgroundColor: theme.design.background.bottomNav,
      // borderTopStyle: 'solid',
      // borderTopWidth: 1,
      // borderTopColor: theme.design.background.dark,
    }} onChange={handleChange}>
      <BottomNavigationAction
        className={classes.bna}
        key={CUSTOMER_BN_ITEM_ID.WORLD_GATE}
        value={URI.WORLD_GATE}
        style={{paddingTop: 0}}
        icon={activeItem === CUSTOMER_BN_ITEM_ID.WORLD_GATE ? bottomNavIcon(HomeIcon, theme.design.mainYellow) : bottomNavIcon(HomeIcon, theme.design.bottomNav.inactive)}/>)}
      />
      <BottomNavigationAction
        className={classes.bna}
        key={CUSTOMER_BN_ITEM_ID.INVENTORY}
        value={URI.INVENTORY}
        style={{paddingTop: 0}}
        icon={activeItem === CUSTOMER_BN_ITEM_ID.INVENTORY ? bottomNavIcon(WalletIcon, theme.design.mainYellow) : bottomNavIcon(WalletIcon, theme.design.bottomNav.inactive)}
      />
      <BottomNavigationAction
        className={classes.bna}
        key={CUSTOMER_BN_ITEM_ID.SETTING}
        value={URI.SETTING}
        style={{paddingTop: 0}}
        icon={activeItem === CUSTOMER_BN_ITEM_ID.SETTING ? bottomNavIcon(SettingIcon, theme.design.mainYellow) : bottomNavIcon(SettingIcon, theme.design.bottomNav.inactive)}/>)}
      />
    </BottomBar>
  );
}

BottomNav.propTypes = {
  activeItem: TNumber,
};

export default withPageControl(BottomNav);
