import React from 'react';
import {makeStyles} from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import {TNode} from 'lib/Core/prop_types';
import {TBool, TFunction, TNumber, TString} from 'lib/Core/prop_types';
import {useI18nTranslator} from '../I18nLang/hooks';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 24,
    marginBottom: 18,
    justifyContent: 'flex-start',
    width: '100%',
  },
  mt: {
    paddingLeft: 8,
  },
});

function MenuItem({id, label, Icon, highlight = false, onClick = () => {}, highlightColor, color}) {
  const classes = useStyles();

  const [_t] = useI18nTranslator();
  return (
    <div key={id}>
      <IconTextButton className={classes.root} onClick={onClick} Icon={Icon}>
        <Typography
          variant='subtitle1'
          className={classes.mt}
          style={{color: highlight ? highlightColor : color}}
        >
          {_t(label)}
        </Typography>
      </IconTextButton>
    </div>
  );
}

function IconTextButton({
  children,
  Icon,
  onClick = () => {},
  className = null,
  style = {},
}) {
  const classes = useStyles();

  return (
    <ButtonBase className={className} onClick={onClick}　style={style}>
      <div className={classes.icon ? classes.icon : null}>
        {Icon}
      </div>
      <div className={classes.text ? classes.text : null}>
        {children}
      </div>
    </ButtonBase>
  );
}

MenuItem.propTypes = {
  onClick: TFunction,
  highlightColor: TString,
  color: TString,
  highlight: TBool,
  id: TNumber,
  label: TString,
  Icon: TNode,
};

export default MenuItem;
