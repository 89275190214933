import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/styles';
import Dialog from '@material-ui/core/Dialog';
import {TFunction, TBool} from 'lib/Core/prop_types';
import {getWindowInnerWidth} from 'viewport';
import {SlideUp} from '../Animation/Slide';

const _width = getWindowInnerWidth();

const useStyles = makeStyles({
  paper: {
    borderRadius: 10,
    border: 'none',
    alignSelf: 'flex-end',
    marginBottom: 10,
    overflow: 'hidden',
    marginLeft: 8,
    marginRight: 8,
    width: _width - 16,
  },
});

function StandardDialog({open, style, onClose, transaction, children}) {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(
    () => {
      setIsOpen(open);
    },
    [open]
  );

  function closeDialog() {
    if (onClose !== undefined) {
      onClose();
    } else {
      setIsOpen(false);
    }
  }

  function renderDialog() {
    const _transaction = transaction ? transaction : SlideUp;
    const _style = style ? style : {};
    return (
      <Dialog
        // fullScreen
        open={isOpen}
        onClose={closeDialog}
        TransitionComponent={_transaction}
        transitionDuration={{
          enter: 350,
          exit: 350,
        }}
        classes={{paper: classes.paper}}
        disableAutoFocus={true}
        style={_style}
      >
        {children}
      </Dialog>
    );
  }

  return renderDialog();
}

StandardDialog.propTypes = {
  onClose: TFunction,
  onOpen: TFunction,
  transition: TFunction,
  open: TBool,
};

export default StandardDialog;
