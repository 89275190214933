import React from 'react';
import useConfig from 'lib/Core/hook/useConfig';
import {TNode} from 'lib/Core/prop_types';
import {topMenuHeight} from '../TopMenu';
import {bottomNavHeight} from '../BottomNav';
import {Body} from '../NavLayout';

function FullHeightBody(props) {
  // const classes = useStyles();
  const {getSafeAreaTopHeight, getSafeAreaBottomHeight, getWindowInnerHeight} = useConfig();
  // @todo The title needs to be taken into account!!!
  const height = getWindowInnerHeight() - topMenuHeight - bottomNavHeight - getSafeAreaTopHeight() - getSafeAreaBottomHeight();
  const _props = {
    ...props,
    ...{
      style: {
        height,
      },
    },
  };
  return (<Body {..._props}/>);
}

FullHeightBody.propTypes = {
  children: TNode,
};

export default FullHeightBody;