import React from 'react';
import {TString} from 'lib/Core/prop_types';
import Base from './Base';

const Send = (props) => {
  return (
    <Base {...props}>
      <path d="M21.81 2.23a1 1 0 0 0-1-.25L1.36 7.5a1 1 0 0 0-.05 1.91l10 3.38 3.38 10a1 1 0 0 0 .94.68 1 1 0 0 0 .93-.73l5.51-19.53a1 1 0 0 0-.26-.98zM17.68 5l-5.9 5.9L5 8.55zM15.5 19.06l-2.31-6.79 5.91-5.9z"/>
      <path fill="none" d="M0 0h24v24H0z"/>
    </Base>
  );
};

Send.propTypes = {
  color: TString,
};

export default Send;
