import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {TString} from 'lib/Core/prop_types';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
}));

function MessageBar({
  children,
  style,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root} style={style}>
      {children}
    </div>
  );
}

MessageBar.propTypes = {
  className: TString,
};

export default MessageBar;
