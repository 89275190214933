import React from 'react';
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/styles';
import withPageControl from '../HOC/withPageControl';
import {TFunction, TObject} from 'lib/Core/prop_types';
import {TUserSession} from 'logic/redux/type';
import NavLayout from '../NavLayout';

export {default as Body, FullSizeBody} from '../NavLayout/Body';
export {default as FullHeightBody} from './FullHeightBody';

const useStyles = makeStyles(theme => ({
  title: {
    width: '100%',
    // marginBottom: 5,
  },
}));

function TopMenuLayout({
  title,
  TitleStyle = {},
  headerStyle = {},
  ...others
}) {
  const classes = useStyles();

  const _headerStyle = {
    ...{
      background: 'transparent',
      minHeight: 280,
      paddingTop: 71,
    },
    ...headerStyle,
  };

  const _props = {
    ...{
      headerStyle: _headerStyle,
      innerStyle: {
        marginTop: 75,
      },
      Title: (
        <Typography variant='h5' align={'center'} className={classes.title} style={TitleStyle}>{title}</Typography>
      ),
      disableHeader: true,
    },
    ...others,
  };

  return (<NavLayout{..._props}/>);
}

TopMenuLayout.propTypes = {
  userSession: TUserSession.isRequired,
  actionBar: TObject,
  renderTopBanner: TFunction,
  renderHelpCenter: TFunction,
  TopMenu: TObject,
};

function mapStateToProps(state) {
  return {
    userSession: state.userSession,
  }
}

export default connect(mapStateToProps)(withPageControl(TopMenuLayout));
