import React from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import {makeStyles} from '@material-ui/styles';
import {TString, TObject, TFunction, TNumber, declareObject, TBool} from 'lib/Core/prop_types';
import ButtonText from './ButtonText';

const useStyles = makeStyles({
  bb: {
    padding: 0,
    margin: 0,
    // backgroundPosition: 'center',
    // transition: 'opacity 0.8s',
    // '&:active': {
    //   // animation: 'triangleButtonClick 0.5s linear',
    //   // backgroundColor: '#EFEFEF',
    //   // backgroundSize: '100%',
    //   opacity: 0.6,
    //   transition: 'opacity 0s',
    // },
    // background: 'transparent',
    position: 'relative',
  },
  mask: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    zIndex: 1,
  },
});

// The triangle button overall.
const TriangleButton = ({
  style = {},
  triangleStyle = {},
  label,
  GradientDefProps,
  size = 84,
  onClick = () => {},
  disableRipple = false,
  backgroundColor = '#FFFFFF',
}) => {
  const classes = useStyles();

  function clickButton() {
    onClick();
  }

  const buttonBaseStyle = {
    ...{
      width: size,
      height: size,
    },
    ...style,
  };

  const bgStyle = {
    ...{
      width: size,
      height: size,
      background: `linear-gradient(${GradientDefProps.colorTop}, ${GradientDefProps.colorBottom})`,
    },
    ...triangleStyle,
  };

  if (!disableRipple) {
    return (
      <ButtonBase
        onClick={clickButton}
        className={classes.bb}
        style={buttonBaseStyle}
      >
        <div
          className={classes.mask}
          style={{
            borderTopWidth: size,
            borderTopStyle: 'solid',
            borderTopColor: backgroundColor,
            borderRightWidth: size,
            borderRightStyle: 'solid',
            borderRightColor: 'transparent',
          }}
        />
        <div style={bgStyle}>
          <ButtonText label={label} size={size}/>
        </div>
      </ButtonBase>
    )
  } else {
    return (
      <div
        onClick={clickButton}
        className={classes.bb}
        style={buttonBaseStyle}
      >
        <div style={bgStyle}>
          <ButtonText label={label} size={size}/>
        </div>
      </div>
    )
  }

};

const TGradientDefProps = declareObject({
  colorTop: TString.isRequired,
  colorBottom: TString.isRequired,
});

TriangleButton.propTypes = {
  label: TString,
  GradientDefProps: TGradientDefProps.isRequired,
  style: TObject,
  triangleStyle: TObject,
  size: TNumber,
  onClick: TFunction,
  disableRipple: TBool,
  backgroundColor: TString,
};

export default TriangleButton;

export function addColor(colorTop, colorBottom) {
  return function (props) {
    const _props = {
      ...props,
      ...{
        GradientDefProps: {
          colorTop,
          colorBottom,
        }
      },
    };
    return (<TriangleButton {..._props}/>);
  }
}