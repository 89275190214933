import includeConstraints from './include_constraints';
import {isEmail} from 'logic/utils/validation';

export const passwordError = {
  NO_UPPER_CASE: 1,
  NO_LOWER_CASE: 2,
  NO_NUMBER: 3,
  LENGTH_VIOLATION: 4,
 };

const userConstraints = includeConstraints({
  email: (val) => {
    val = val.trim();
    return {
      isPassed: isEmail(val),
      msg: 'Please input a valid email. E.g. tanggram@tanggram.com',
      val,
    };
  },
  password: (val) => {
    val = val.trim();
    let errors = [];
    if (val.length < 8 || val.length > 20) {
      errors.push(passwordError.LENGTH_VIOLATION);
    }
    if (!/^(.*[A-Z])/.test(val)) {
      errors.push(passwordError.NO_UPPER_CASE);
    }
    if (!/^(.*[a-z])/.test(val)) {
      errors.push(passwordError.NO_LOWER_CASE);
    }
    if (!/^(.*[0-9])/.test(val)) {
      errors.push(passwordError.NO_NUMBER);
    }
    return {
      isPassed: errors.length ===0,
      msg: 'A password must contain 8 - 20 characters including at least one uppercase (A-Z), one lowercase letter (a-z) and one number (0-9). E.g. 12345678Aa',
      errors,
      val,
    };
  },
});

export default userConstraints;
