import React from 'react';
import {
  makeStyles,
} from '@material-ui/styles';
import getS3ImagePath from 'logic/utils/getS3ImagePath';
import Image from 'component/Image';
import getEnv from 'tanggram';

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: 8,
    marginRight: 8,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export function toAppStore() {
  window.open(getEnv().ext.appRedirectionUrl);
}

const width = 150;
const aspectRatio = 203/683;

function Google({
  style = {},
}) {
  const classes = useStyles();
  return (
    <div style={style} className={classes.root}>
      <Image path={getS3ImagePath('/google_play_2.png')} width={width} aspectRatio={aspectRatio} onClick={toAppStore}/>
    </div>
  );
}

Google.propTypes = {
};

function Apple({
style = {},
}) {
  const classes = useStyles();
  return (
      <div style={style} className={classes.root}>
        <Image path={getS3ImagePath('/apple_store_2.png')} width={width} aspectRatio={aspectRatio} onClick={toAppStore}/>
      </div>
  );
}

Apple.propTypes = {
};

export {
  Google,
  Apple,
};
