import {put, takeLeading} from 'redux-saga/effects';
import createState from './state';
import loadCoffeeZeroVoucher from 'logic/service/api/method/load_coffee_zero_voucher';
import {dataStatus, transformApiData} from '../transform';
import {
  getApiResponse,
  handleError,
  showLoading,
  hideLoading,
} from '../actions';
import TDataStatus from '../data_status';
import {TArray, declareObject, TObject} from 'lib/Core/prop_types';

export const TCoffeeZeroVoucherLoading = declareObject({
  status: TDataStatus.isRequired,
  voucherTemplate: TObject,
  vouchers: TArray,
  stores: TArray,
  // amount: TNumber,
  // createdAt: TString,
  // creator: TObject,
  // description: TString,
  // extra: TString,
  // id: TNumber,
  // identity: TString,
  // stores: TObject,
  errors: TArray,
});

export const coffeeZeroVoucherLoading = 'coffeeZeroVoucher.1';

export default createState(
  'coffeeZeroVoucher',
  {
    status: dataStatus.INIT,
    voucherTemplate: {},
    vouchers: [],
    stores: [],
    errors: [],
  },
  ({addSaga, reduce, reduceError, reduceReset}) => {
    addSaga(function* () {
      yield  takeLeading('COFFEE_ZERO_VOUCHER_LOAD', function* (action) {
        try {
          yield put(showLoading(coffeeZeroVoucherLoading));
          const apiData = yield loadCoffeeZeroVoucher();
          yield put(getApiResponse('COFFEE_ZERO_VOUCHER_LOAD_R', apiData));
          yield put(hideLoading(coffeeZeroVoucherLoading));
        } catch (e) {
          yield put(handleError('COFFEE_ZERO_VOUCHER_LOAD_F', e));
          yield put(hideLoading(coffeeZeroVoucherLoading));
        }
      });
    });

    reduce('COFFEE_ZERO_VOUCHER_LOAD_R', function (state, action) {
      let data = Object.assign({}, state);
      const apiData = transformApiData(action.data);
      data.status = apiData.status;
      if (data.status === dataStatus.FAILURE) {
        data.errors = apiData.errors;
      } else if (data.status === dataStatus.SUCCESS) {
        data.voucherTemplate = apiData.data.voucherTemplate ? apiData.data.voucherTemplate : {};
        data.vouchers = apiData.data.vouchers ? apiData.data.vouchers : [];
        data.stores = apiData.data.stores ? apiData.data.stores : [];
      }
      return data;
    });
    reduceError('COFFEE_ZERO_VOUCHER_LOAD_F');
    reduceReset('COFFEE_ZERO_VOUCHER_LOAD_RESET');
  }
);

export const actions = {
  load: (postData) => ({type: 'COFFEE_ZERO_VOUCHER_LOAD'}),
  reset: () => ({type: 'COFFEE_ZERO_VOUCHER_LOAD_RESET'}),
};
