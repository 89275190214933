const config = {
  env: 'prod',
  platform: 'web',
  debug: false,
  verbose: false,
  bugsnagApiKey: '10f17b0695c0a850063e3efa9d20e12c',
  appVersion: '1.0.0',
  isSessionPersistenceEnabled: true, // This is the default setting for session persistence.
  localStorage: {
    type: 'cookie',
    config: {
      maxAge: 60 * 60 * 24 * 30,
      path: '/',
    }
  },
  resource: {
    auth: {
      baseUrl: 'https://api.tanggram.com/app-auth',
    },
    reward: {
      baseUrl: 'https://api.tanggram.com/app-reward',
    },
    wealth: {
      baseUrl: 'https://api.tanggram.com/app-wealth',
    },
    center: {
      baseUrl: 'https://api.tanggram.com/app-center',
    },
    search: {
      baseUrl: 'https://api.tanggram.com/app-search',
    },
  },
  assets: {
    image: {
      basePath: 'https://account.tanggram.com',
    },
    s3Image: {
      basePath: 'https://s3-ap-southeast-2.amazonaws.com/tanggram-app-media/app_asset/tanggram',
    },
    s3Pdf: {
      basePath: 'https://s3-ap-southeast-2.amazonaws.com/tanggram-app-media/pdf',
    },
  },
  ext: {
    /*
    appRedirectionUrl: 'https://api.tanggram.com/app-center/app-store',
    googlePlayUri: 'https://play.google.com/store/apps/details?id=com.tanggram.app&hl=en_AU',
    appleStoreUri: 'https://apps.apple.com/au/app/tanggram/id1483540550',
    */
    appRedirectionUrl: 'https://tanggramapp.onelink.me/PtDa/Tanggram',
    googlePlayUri: 'https://tanggramapp.onelink.me/PtDa/Tanggram',
    appleStoreUri: 'https://tanggramapp.onelink.me/PtDa/Tanggram',
  },
};

export default config;