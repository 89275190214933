import React from 'react';
import {
  makeStyles,
} from '@material-ui/styles';
import {isIOS, isAndroid} from 'logic/utils/platform';
import {Google, Apple} from './Icons';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    flexDirection: 'row',
    paddingLeft: 8,
    paddingRight: 8,
  },
}));

// const appIcon = isAndroid() ? '/google_play_2.png' : '/apple_store_2.png';

function GooglePlayStoreApp({
  style = {},
}) {
  const classes = useStyles();
  if (isAndroid()) {
    return (
      <div className={classes.root}>
        <Google/>
      </div>
    );
  } else if (isIOS()) {
    return (
      <div className={classes.root}>
        {/* <Google/> */}
        <Apple/>
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        <Google/>
        <Apple/>
      </div>
    );
  }
}

GooglePlayStoreApp.propTypes = {
};

export default GooglePlayStoreApp;
