import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/styles';
import withPageControl from '../HOC/withPageControl';
import {TString} from 'lib/Core/prop_types';
import ShutterModal from '../ShutterModal';
import registry from 'logic/service/registry';
import {TFunction} from 'lib/Core/prop_types';
import {SHUTTER_STATE} from '../ShutterModal';
import Menu from './Menu';

const useStyles = makeStyles({
  list: {
    marginTop: 90,
  },
});

export function saveDrawerNextState(nextState) {
  registry.register('_MENU_NEXT_STATE', nextState);
}

export function getDrawerNextState() {
  return registry.get('_MENU_NEXT_STATE', SHUTTER_STATE.INIT);
}

/**
 * @return {null}
 */
function BlankDrawer({
  children, 
  themeColor,
  DrawerButtonIcon,
  renderMenuList,
  pageControl,
  location,
}) {
  const classes = useStyles();

  // console.log('current', getDrawerNextState());
  const [shutterState, setShutterState] = useState(getDrawerNextState());
  // const {changeLanguage} = useI18nLang();

  // Reset the menu next state to init once it's set to open previously.
  useEffect(() => {
    if (shutterState === SHUTTER_STATE.OUT) {
      saveDrawerNextState(SHUTTER_STATE.INIT);
      // console.log('reset', getDrawerNextState());
    }
  }, [shutterState]);

  function onDrawerStateUpdate(currentState, nextState) {
    if (nextState === SHUTTER_STATE.IN) {
      openDrawer();
    }
    if (nextState === SHUTTER_STATE.OUT) {
      closeDrawer();
    }
    if (nextState === SHUTTER_STATE.RIGHT_IN) {
      setShutterState(SHUTTER_STATE.RIGHT_IN);
    }
    if (nextState === SHUTTER_STATE.RIGHT_OUT) {
      setShutterState(SHUTTER_STATE.RIGHT_OUT);
    }
  }

  function openDrawer() {
    saveDrawerNextState(SHUTTER_STATE.OUT);
    // console.log('show', getDrawerNextState());
    setShutterState(SHUTTER_STATE.IN);
  }

  function closeDrawer() {
    saveDrawerNextState(SHUTTER_STATE.INIT);
    // console.log('close', getDrawerNextState());
    setShutterState(SHUTTER_STATE.OUT);
  }

  function onMenuItemClick(uri) {
    return () => {
      closeDrawer();
      setTimeout(() => {
        pageControl.toPage(location, uri);
      }, 300);
    };
  }

  function getIsMenuVisible() {
    return (shutterState !== SHUTTER_STATE.INIT && shutterState !== SHUTTER_STATE.OUT);
  }

  return (
    <ShutterModal
      onStateUpdate={onDrawerStateUpdate}
      currentState={shutterState}
      DrawerButtonIcon={DrawerButtonIcon}
      RightDrawerProps={{
        style: {backgroundColor: themeColor},
      }}
    >
      {
        shutterState !== SHUTTER_STATE.INIT && (
          <Menu visible={getIsMenuVisible()}>
            <div className={classes.body}>
              <div className={classes.list}>
                {renderMenuList(onMenuItemClick)}
              </div>
            </div>
          </Menu>
        )
      }
    </ShutterModal>
  );
}

BlankDrawer.propTypes = {
  renderMenuList: TFunction,
  themeColor: TString,
};

function mapStateToProps(state) {
  return {
  }
}

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(withPageControl(BlankDrawer));