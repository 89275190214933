import {put, takeLeading} from 'redux-saga/effects';
import createState from './state';
import callMerchantEnquiry from '../../service/api/method/merchant_enquiry';
import {dataStatus, transformApiData} from '../transform';
import {
  getApiResponse,
  handleError,
  showLoading,
  hideLoading,
} from '../actions';

export const merchantEnquiryLoading = 'merchantEnquiry.1';

export default createState(
  'merchantEnquiry',
  {
    status: dataStatus.INIT,
    errors: [],
  },
  ({addSaga, reduce, reduceError, reduceReset}) => {
    addSaga(function* () {
      yield takeLeading('CREATE_MERCHANT_ENQUIRE', function* (action) {
        try {
          yield put(showLoading(merchantEnquiryLoading));
          const apiData = yield callMerchantEnquiry(action.postData);
          yield put(getApiResponse('CREATE_MERCHANT_ENQUIRE_R', apiData));
          yield put(hideLoading(merchantEnquiryLoading));
        } catch (e) {
          yield put(handleError('CREATE_MERCHANT_ENQUIRE_F', e));
          yield put(hideLoading(merchantEnquiryLoading));
        }
      });
    });

    reduce('CREATE_MERCHANT_ENQUIRE_R', function (state, action) {
      let data = Object.assign({}, state);
      const apiData = transformApiData(action.data);
      data.status = apiData.status;
      if (data.status === dataStatus.FAILURE) {
        data.errors = apiData.errors;
      } else if (data.status === dataStatus.SUCCESS) {
      }
      return data;
    });
    reduceError('CREATE_MERCHANT_ENQUIRE_F');
    reduceReset('CREATE_MERCHANT_ENQUIRE_RESET');
  }
);

export const merchantEnquiry = {
  create: (postData) => ({type: 'CREATE_MERCHANT_ENQUIRE', postData}),
  reset: () => ({type: 'CREATE_MERCHANT_ENQUIRE_RESET'}),
};
