import React from 'react';
import {makeStyles} from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import useConfig from 'lib/Core/hook/useConfig';
import {ChevronRight as ChevronRightIcon} from 'react-feather';
import ContentAlignedBox from '../ContentAlignedBox';
import transitions from 'logic/const/transition';
import {TNumber, declareObject, TBool, TFunction, TObject, TNode, TString} from 'lib/Core/prop_types';
import {loadTransition} from 'logic/service/page_transition';
import {getWindowInnerWidth, getCardMaxWidth} from 'viewport';

const _width = getWindowInnerWidth();
const _cMaxWidth = getCardMaxWidth();

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minWidth: 100,
    maxWidth: _cMaxWidth,
    marginBottom: 15,
    borderRadius: 10,
    boxShadow: ({config}) => (config.card.raised ? theme.design.boxShadow : 'none'),
    marginLeft: 2,
    marginRight: 2,
  },
  cBody: {
    flexGrow: 1,
  },
  body: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  divide: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 16,
  },
  lPCorner: {
    display: 'flex',
    height: 45,
    alignSelf: 'flex-end',
    minWidth: _width / 2 - 16,
    // backgroundColor: 'rgba(204,204,204,0.2)',
  },
  flex: {
    flexGrow: 1,
  },
  loadCont: {
    display: 'flex',
    flexGlow: 1,
    flexWrap: 'wrap',
    padding: 20,
  },
  loadItem: {
    height: 25,
    width: '100%',
    backgroundColor: '#F6F6F6',
    borderRadius: 10,
    marginTop: 10,
    marginBottom: 10,
  },
  overlay: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 9999,
    position: 'fixed',
    opacity: 0.3,
    backgroundColor: '#CECECE',
  },
  pBtn: {
    textTransform: 'none',
    color: '#999999',
    paddingBottom: 0,
  },
  rTBtn: {
    paddingTop: 5,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  rPCorner: {
    marginRight: -5,
    paddingLeft: 0,
    marginTop: 25,
  },
  title: {
    display: 'flex',
    paddingBottom: 0,
    flexGrow: 1,
    overflow: 'none',
    padding: 10,
  },
  tLeft: {
    // marginTop: -3,
    marginLeft: 8,
  },
}));

function BlockCard({
  children,
  rightTopButton,
  rightTopText,
  leftTopText,
  leftButton = null,
  LeftButton,
  rightButton,
  RightTriangleButton,
  placeHolder,
  disabled,
  disabledIcon,
  style,
  onClick,
  transition,
  animation,
  renderLeftBorder = () => null,
}) {
  const config = useConfig();
  const classes = useStyles({config});

  const animationOptions = {
    fade: {
      timeout: {
        enter: 500,
        exit: 500,
      },
    },
    slide: {
      timeout: {
        enter: 300,
        exit: 300,
      },
    },
    zoom: {
      timeout: {
        enter: 500,
        exit: 500,
      },
    },
    grow: {
      timeout: {
        enter: 500,
        exit: 500,
      },
    },
  };

  function renderRightTopButton() {
    if (rightTopButton) {
      const {onClick, icon} = rightTopButton;
      const _icon = icon ? icon : <ChevronRightIcon/>;
      return (
        <div className={classes.rTBtn}>
          <IconButton onClick={onClick}>
            {_icon}
          </IconButton>
        </div>
      );
    }
    return null;
  }

  function renderRightTopText() {
    if (rightTopText) {
      return (
        <Typography color='textSecondary' variant='body1'>{rightTopText}</Typography>
      );
    }
    return null;
  }

  function renderLeftTopText() {
    if (leftTopText) {
      return (
        <div className={classes.tLeft}>
          <Typography variant='h6'>{leftTopText}</Typography>
        </div>
      );
    }
  }

  function renderTitle() {
    if (leftTopText || rightTopText) {
      return (
        <div className={classes.title}>
          {renderLeftTopText()}
          <div className={classes.flex}>&nbsp;</div>
          {renderRightTopText()}
        </div>
      );
    }
    return null;
  }

  function renderLeftButton() {
    if (LeftButton) {
      return (
        <div
          className={classes.lPCorner}
        >
          {LeftButton}
        </div>
      );
    }

    if (leftButton) {
      const {Icon, onClick, text, size = 'small', color, style = {}} = leftButton;
      return (
        <div
          className={classes.lPCorner}
          // style={{backgroundColor: backgroundColor}}
        >
          <Button component='button' size={size} style={style} className={classes.pBtn} onClick={onClick}>
            {Icon}<div>&nbsp;</div><Typography style={{color: color}} variant='body1'>{text}</Typography>
          </Button>
        </div>
      );
    }
    return null;
  }

  function renderRightButton() {
    if (rightButton) {
      const {onClick, text, size = 'medium', style = {}} = rightButton;
      return (
        <div className={classes.rPCorner}>
          <Button component='button' size={size} style={style} className={classes.pBtn} onClick={onClick}>
            {text}
          </Button>
        </div>
      );
    }
    if (RightTriangleButton) {
      return RightTriangleButton;
    }
    return null;
  }

  function renderActionBar() {
    const leftButton = renderLeftButton();
    const rightButton = renderRightButton();
    if (leftButton || rightButton) {
      return (
        <div className={classes.divide}>
          {leftButton}
          <div className={classes.flex}>&nbsp;</div>
          {rightButton}
        </div>
      );
    } else {
      return null;
    }
  }

  function renderPlaceHolderItem(key) {
    return (
      <div key={key} className={classes.loadItem}>&nbsp;</div>
    );
  }

  function renderPlaceHolder() {
    if (placeHolder) {
      const {repeat, renderItem} = placeHolder;
      const _repeat = repeat ? repeat : 10;
      const _renderItem = renderItem ? renderItem : renderPlaceHolderItem;
      let items = [];
      for (let i = 0; i < _repeat; i++){
        items.push(_renderItem(`item_${i}`));
      }
      return (
        <div className={classes.loadCont}>
          {items.map((item) => {
            return item;
          })}
        </div>
      );
    }
  }

  function renderOverlay() {
    if (disabled) {
      const _disabledIcon = disabledIcon ? disabledIcon : (<div>&nbsp;</div>);
      return (
        <div className={classes.overlay}>
          <ContentAlignedBox variant='centered' style={{height: '100%', width: '100%'}}>
            {_disabledIcon}
          </ContentAlignedBox>
        </div>
      );
    } else {
      return null;
    }
  }

  function renderCard() {
    const _style = style ? style : {};
    const _onClick = onClick ? onClick : () => {};
    if (placeHolder) {
      return (
        <Card className={classes.card} style={_style} onClick={_onClick}>
          {renderOverlay()}
          {renderPlaceHolder()}
        </Card>
      );
    } else {
      return (
        <Card className={classes.card} style={_style} onClick={_onClick}>
          {renderOverlay()}
          <div className={classes.body}>
            {renderLeftBorder()}
            {renderCardBody()}
          </div>
        </Card>
      );
    }
  }

  function renderCardBody() {
    return (
      <div className={classes.cBody}>
        {renderRightTopButton()}
        {renderTitle()}
        <div>
          {children}
        </div>
        {renderActionBar()}
      </div>
    );
  }

  function renderSlideToRight() {
    return (
      <Slide direction='right' in={true} timeout={animationOptions.slide.timeout}>
        {renderCard()}
      </Slide>
    );
  }

  function renderSlideToLeft() {
    return (
      <Slide direction='left' in={true} timeout={animationOptions.slide.timeout}>
        {renderCard()}
      </Slide>
    );
  }

  const _pageTransition = transition ? transition : loadTransition();
  const _animation = (animation !== false);
  if (_animation) {
    switch (parseInt(_pageTransition, 10)) {
      case transitions.SLIDE_TO_RIGHT:
        return renderSlideToRight();
      case transitions.SLIDE_TO_LEFT:
        return renderSlideToLeft();
      default:
        return renderSlideToRight();
    }
  }
  else {
    return renderCard();
  }
}

const TPlaceHolderProps = declareObject({
  repeat: TNumber,
  renderItem: TFunction,
});

const TLeftButton = declareObject({
  onClick: TFunction,
  text: TNode,
  size: TString,
  style: TObject,
  icon: TObject,
});

const TRightButton = declareObject({
  onClick: TFunction,
  text: TNode,
  size: TString,
  style: TObject,
});

const TRightTopButton = declareObject({
  icon: TNode,
  onClick: TFunction,
});

BlockCard.propTypes = {
  placeHolder: TPlaceHolderProps,
  disabled: TBool,
  disabledIcon: TObject,
  leftButton: TLeftButton,
  rightButton: TRightButton,
  RightTriangleButton: TObject,
  rightTopButton: TRightTopButton,
  transition: TNumber,
  animation: TBool,
  rightTopText: TString,
  leftTopText: TString,
  children: TNode,
  onClick: TFunction,
  renderLeftBorder: TFunction,
  LeftButton: TObject,
};

export default BlockCard;
