import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {makeStyles} from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import {Body} from 'component/NavLayout';
import TopMenuLayout from 'component/TopMenuLayout';
import CustomerRegisterForm from './CustomerRegisterForm';
import AccountSideMenu, {ACCOUNT_MENU_ITEM_ID} from 'component/SideMenu/AccountSideMenu';
import AppDownloadIcon from 'component/AppDownloadIcon';
// import {DefaultBackButton} from 'component/BackButton';
// import Image from 'component/Image';
// import AppDownloadClickBait from 'component/AppDownloadClickBait';

const useStyles = makeStyles({
  body: {
    marginTop: -30,
    zIndex: 999,
  },
  rt: {
    position: 'fixed',
    bottom: -300,
    left: -35,
    animation: 'spin 90s linear infinite',
    pointerEvents: 'none',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexGrow: 1
  },
});

function CustomerRegisterPage() {
  const classes = useStyles();
  const userSession = useSelector(state => state.userSession);
  // const config = useConfig();
  // const sideLength = config.getWindowInnerWidth() + 70;

  function renderBody() {
    return (
      <div>
        <TopMenuLayout
          // BackButton={(<DefaultBackButton/>)}
          SideMenu={(<AccountSideMenu activeItem={ACCOUNT_MENU_ITEM_ID.REGISTER}/>)}
        >
          <div className={classes.body}>
            <Body>
              <CustomerRegisterForm/>
              {/*<AppDownloadClickBait/>*/}
              <div className={classes.row} style={{marginBottom: 8,}}>
                <Typography variant={'body1'} color={'textPrimary'}>Or download the app to register</Typography>
              </div>
              <AppDownloadIcon/>
            </Body>
          </div>
        </TopMenuLayout>
        {/*<div className={classes.rt}>*/}
        {/*  <Image path={getS3ImagePath('/top_right/coin_earth.svg')} alt={'ball'} width={sideLength}/>*/}
        {/*</div>*/}
      </div>
    );
  }

  return (
    renderBody()
  );
}

CustomerRegisterPage.propTypes = {
};

export default CustomerRegisterPage;
