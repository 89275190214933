import {put, takeLeading} from 'redux-saga/effects';
import createState from './state';
import callLoadCustomerNotification from '../../service/api/method/load_customer_notification';
import {dataStatus, transformApiData} from '../transform';
import {
  getApiResponse,
  handleError,
  showLoading,
  hideLoading,
} from '../actions';
import TDataStatus from '../data_status';
import {TArray, declareObject, TNumber} from 'lib/Core/prop_types';

export const TCustomerNotification = declareObject({
  status: TDataStatus.isRequired,
  notifications: TArray,
  pagination: declareObject({
    page: TNumber,
    limit: TNumber,
    totalCount: TNumber,
    startPosition: TNumber,
    endPosition: TNumber,
    numberOfPages: TNumber,
  }),
  filterTags: TArray,
  errors: TArray,
});

export const customerNotificationLoading = 'customerNotification.1';
export const customerNotificationDrawerLoading = 'customerNotification.Drawer.1';

export default createState(
  'customerNotification',
  {
    status: dataStatus.INIT,
    notifications: [],
    pagination: {
      page: null,
      limit: null,
      totalCount: null,
      startPosition: null,
      endPosition: null,
      numberOfPages: null,
    },
    filterTags: [],
    errors: [],
  },
  ({addSaga, reduce, reduceError, reduceReset}) => {
    addSaga(function* () {
      yield takeLeading('SEARCH_CUSTOMER_NOTIFICATION', function* (action) {
        try {
          yield put(showLoading(customerNotificationLoading));
          const apiData = yield callLoadCustomerNotification(action.query);
          yield put(getApiResponse('SEARCH_CUSTOMER_NOTIFICATION_R', apiData));
          yield put(hideLoading(customerNotificationLoading));
        } catch (e) {
          yield put(handleError('SEARCH_CUSTOMER_NOTIFICATION_F', e));
          yield put(hideLoading(customerNotificationLoading));
        }
      });
    });

    reduce('SEARCH_CUSTOMER_NOTIFICATION_R', function (state, action) {
      let data = Object.assign({}, state);
      const apiData = transformApiData(action.data);
      data.status = apiData.status;
      if (data.status === dataStatus.FAILURE) {
        data.errors = apiData.errors;
      } else if (data.status === dataStatus.SUCCESS) {
        data.notifications = apiData.data.items;
        data.pagination = {
          page: apiData.data.pagination.page,
          limit: apiData.data.pagination.limit,
          totalCount: apiData.data.pagination.totalCount,
          startPosition: apiData.data.pagination.startPosition,
          endPosition: apiData.data.pagination.endPosition,
          numberOfPages: apiData.data.pagination.numberOfPages,
        };
        data.filterTags = apiData.data.filterTags;
      }
      return data;
    });

    reduceError('SEARCH_CUSTOMER_NOTIFICATION_F');
    reduceReset('SEARCH_CUSTOMER_NOTIFICATION_RESET');
  }
);

export const customerNotification = {
  search: (query) => ({type: 'SEARCH_CUSTOMER_NOTIFICATION', query}),
  reset: () => ({type: 'SEARCH_CUSTOMER_NOTIFICATION_RESET'})
};

export const customerNotificationDrawerState = createState(
  'customerNotificationDrawer',
  {
    status: dataStatus.INIT,
    notifications: [],
    pagination: {
      page: null,
      limit: null,
      totalCount: null,
      startPosition: null,
      endPosition: null,
      numberOfPages: null,
    },
    filterTags: [],
    errors: [],
  },
  ({addSaga, reduce, reduceError, reduceReset}) => {
    addSaga(function* () {
      yield takeLeading('SEARCH_CUSTOMER_NOTIFICATION_DRAWER', function* (action) {
        try {
          yield put(showLoading(customerNotificationDrawerLoading));
          const apiData = yield callLoadCustomerNotification(action.query);
          yield put(getApiResponse('SEARCH_CUSTOMER_NOTIFICATION_DRAWER_R', apiData));
          yield put(hideLoading(customerNotificationDrawerLoading));
        } catch (e) {
          yield put(handleError('SEARCH_CUSTOMER_NOTIFICATION_DRAWER_F', e));
          yield put(hideLoading(customerNotificationDrawerLoading));
        }
      });
    });

    reduce('SEARCH_CUSTOMER_NOTIFICATION_DRAWER_R', function (state, action) {
      let data = Object.assign({}, state);
      const apiData = transformApiData(action.data);
      data.status = apiData.status;
      if (data.status === dataStatus.FAILURE) {
        data.errors = apiData.errors;
      } else if (data.status === dataStatus.SUCCESS) {
        data.notifications = apiData.data.items;
        data.pagination = {
          page: apiData.data.pagination.page,
          limit: apiData.data.pagination.limit,
          totalCount: apiData.data.pagination.totalCount,
          startPosition: apiData.data.pagination.startPosition,
          endPosition: apiData.data.pagination.endPosition,
          numberOfPages: apiData.data.pagination.numberOfPages,
        };
        data.filterTags = apiData.data.filterTags;
      }
      return data;
    });

    reduceError('SEARCH_CUSTOMER_NOTIFICATION_DRAWER_F');
    reduceReset('SEARCH_CUSTOMER_NOTIFICATION_DRAWER_RESET');
  }
);

export const customerNotificationDrawer = {
  search: (query) => ({type: 'SEARCH_CUSTOMER_NOTIFICATION_DRAWER', query}),
  reset: () => ({type: 'SEARCH_CUSTOMER_NOTIFICATION_DRAWER_RESET'})
};

