import React from 'react';
import {useTheme} from '@material-ui/styles';
import {TObject, TNumber} from 'lib/Core/prop_types';
import Menu from './Menu';
import MenuItem from './MenuItem';
import {URI} from 'page/Layout/uri';
import {topMenuItemIcon} from '../Icon';
import TurnOverIcon from 'lib/Icon/TurnOver';
import TransactionIcon from 'lib/Icon/Transaction';

export const ITEM_ID = {
  SHOPPING: 1,
  OTHER_TRANSACTIONS: 2,
};

function InventoryRewardMenu({
  style = {},
  activeItemId = null,
}) {
  const theme = useTheme();
  return (
    <Menu style={style}>
      <MenuItem
        colorLight={theme.design.reward.light}
        colorDark={theme.design.reward.dark}
        Icon={
          activeItemId === ITEM_ID.SHOPPING
            ? topMenuItemIcon(TransactionIcon)
            : topMenuItemIcon(TransactionIcon, theme.design.reward.dark)
        }
        toUri={URI.CUSTOMER_REWARDS}
        isActive={activeItemId === ITEM_ID.SHOPPING}
      />
      <MenuItem
        colorLight={theme.design.reward.light}
        colorDark={theme.design.reward.dark}
        Icon={
          activeItemId === ITEM_ID.OTHER_TRANSACTIONS
            ? topMenuItemIcon(TurnOverIcon)
            : topMenuItemIcon(TurnOverIcon, theme.design.reward.dark)
        }
        toUri={URI.CUSTOMER_REWARDS_OTHER}
        isActive={activeItemId === ITEM_ID.OTHER_TRANSACTIONS}
      />
    </Menu>
  )
}

InventoryRewardMenu.propTypes = {
  style: TObject,
  activeItemId: TNumber,
};

export default InventoryRewardMenu;
