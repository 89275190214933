import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
  makeStyles,
} from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import {isAndroid} from 'logic/utils/platform';
import PlainCard from 'component/Card/PlainCard';
import usePageNavigator, {pages} from 'page/Layout/pages';
// import Image from 'component/Image';
// import getS3ImagePath from 'logic/utils/getS3ImagePath';
// import isMobile from 'logic/utils/platform/isMobile';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 20,
    alignItems: 'center',
  },
}));

function Home() {
  const classes = useStyles();
  const userSession = useSelector(state => state.userSession);
  const pageNavigator = usePageNavigator();


  // const theme = useTheme();
  // const [appPlace] = useState(isAndroid() ? ' Google Play ' : ' Apple Store ');
  const [appIcon] = useState(isAndroid() ? '/google_play_2.png' : '/apple_store_2.png');


  return (
    <>
      <PlainCard
        className={classes.root}
      >
        <Typography variant='h6'>Welcome to Tanggram!</Typography>
        {/*<Typography color='textSecondary'>sdfgsdfg</Typography>*/}
      </PlainCard>
    </>
  );
}

Home.propTypes = {
};

export default Home;
