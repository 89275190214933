import React from 'react';
import {useTheme} from '@material-ui/styles';
// import transitions from 'logic/const/transition';
import {TBool, TFunction, TObject, TString, TNumber, TNode} from 'lib/Core/prop_types';
import BasicCard from './BasicCard';

// const useStyles = makeStyles((theme) => ({
//   root: {
//
//   }
// }));

function DefaultBasicCard(props) {
  const theme = useTheme();
  return (
    <BasicCard
      // style={style}
      // TopBar={(TopBar)}
      // TopRightButton={TopRightButton}
      // enableLeftStripe={enableLeftStripe}
      // enableBottomLeftButton={enableBottomLeftButton}
      // bottomLeftButtonLabel={bottomLeftButtonLabel}
      // bottomLeftButtonOnClick={bottomLeftButtonOnClick}
      // enableBottomRightButton={enableBottomRightButton}
      // bottomRightButtonLabel={bottomRightButtonLabel}
      // bottomRightButtonOnClick={bottomRightButtonOnClick}
      // enableTransition={enableTransition}
      // transitionDelay={transitionDelay}
      // transition={transition}
      leftStripeWidth={5}
      leftStripeBackgroundColor={theme.design.default.dark}
      bottomLeftButtonColor={theme.design.default.dark}
      colorDark={theme.design.default.dark}
      colorLight={theme.design.default.light}
      {...props}
    />
  )
}

DefaultBasicCard.propTypes = {
  children: TNode,
  TopBar: TObject,
  TopRightButton: TObject,
  enableLeftStripe: TBool,
  leftStripeWidth: TNumber,
  enableBottomLeftButton: TBool,
  transitionDelay: TNumber,
  bottomLeftButtonLabel: TString,
  bottomLeftButtonOnClick: TFunction,
  enableBottomRightButton: TBool,
  bottomRightButtonLabel: TString,
  bottomRightButtonOnClick: TFunction,
  enableTransition: TBool,
  transition: TNumber,
  bottomLeftButtonColor: TString,
};

export default React.forwardRef((props, ref) => (
  <DefaultBasicCard {...props} ref={ref}/>
));
