import {put, takeLeading} from 'redux-saga/effects';
import createState from './state';
import saveEmailUnsubscribeOptions from 'logic/service/api/method/save_email_unsubscribe_options';
import {dataStatus, transformApiData} from '../transform';
import {
  getApiResponse,
  handleError,
  showLoading,
  hideLoading,
} from '../actions';
import TDataStatus from '../data_status';
import {TArray, declareObject, TObject,} from 'lib/Core/prop_types';

export const TEmailUnsubscribeLoading = declareObject({
  status: TDataStatus.isRequired,
  options: TObject,
  errors: TArray,
});

export const emailUnsubscribeUpdateLoading = 'emailUnsubscribeUpdate.1';

export default createState(
  'emailUnsubscribeUpdate',
  {
    status: dataStatus.INIT,
    options: [],
    errors: [],
  },
  ({addSaga, reduce, reduceError, reduceReset}) => {
    addSaga(function* () {
      yield  takeLeading('EMAIL_UNSUBSCRIBE_UPDATE_SAVE', function* (action) {
        try {
          yield put(showLoading(emailUnsubscribeUpdateLoading));
          const apiData = yield saveEmailUnsubscribeOptions(action.ref, action.options);
          yield put(getApiResponse('EMAIL_UNSUBSCRIBE_UPDATE_SAVE_R', apiData));
          yield put(hideLoading(emailUnsubscribeUpdateLoading));
        } catch (e) {
          yield put(handleError('EMAIL_UNSUBSCRIBE_UPDATE_SAVE_F', e));
          yield put(hideLoading(emailUnsubscribeUpdateLoading));
        }
      });
    });

    reduce('EMAIL_UNSUBSCRIBE_UPDATE_SAVE_R', function (state, action) {
      let data = {...state};
      const apiData = transformApiData(action.data);
      data.status = apiData.status;
      if (data.status === dataStatus.FAILURE) {
        data.errors = apiData.errors;
      } else if (data.status === dataStatus.SUCCESS) {
        data.options = apiData.data;
        data.errors = [];
      }
      return data;
    });
    reduceError('EMAIL_UNSUBSCRIBE_UPDATE_SAVE_F');
    reduceReset('EMAIL_UNSUBSCRIBE_UPDATE_RESET');
  }
);

export const actions = {
  save: (ref, options) => ({type: 'EMAIL_UNSUBSCRIBE_UPDATE_SAVE', ref, options,}),
  reset: () => ({type: 'EMAIL_UNSUBSCRIBE_UPDATE_RESET'}),
};
