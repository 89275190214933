import React from 'react';
import {TObject} from 'lib/Core/prop_types';
import HCenteredBox from './HCenteredBox';
import VCenteredBox from './VCenteredBox';
import CenteredBox from './CenteredBox';
import LeftAlignedBox from './LeftAlignedBox';
import RightAlignedBox from './RightAlignedBox';
import HEndAlignedBox from './HEndAlignedBox';

function ContentAlignedBox(props) {
  const {variant} = props;

  function renderVariant() {
    switch (variant) {
      case 'hCentered': {
        return (<HCenteredBox {...props}/>);
      }
      case 'vCentered': {
        return (<VCenteredBox {...props}/>);
      }
      case 'centered': {
        return (<CenteredBox {...props}/>);
      }
      case 'leftAligned': {
        return (<LeftAlignedBox {...props}/>);
      }
      case 'rightAligned': {
        return (<RightAlignedBox {...props}/>);
      }
      case 'hEndAligned': {
        return (<HEndAlignedBox {...props}/>);
      }
      default: {
        return (<HCenteredBox {...props}/>);
      }
    }
  }

  return renderVariant();
}

ContentAlignedBox.propTypes = {
  leftEnd: TObject,
  rightEnd: TObject,
};

export default ContentAlignedBox;

// export {
//   HCenteredBox,
//   VCenteredBox,
//   CenteredBox,
//   LeftAlignedBox,
//   RightAlignedBox,
//   HEndAlignedBox,
// };