import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {dataStatus} from 'logic/redux';
import {URI} from '../Layout/uri';
import {TObject} from 'lib/Core/prop_types';
import withAcl from 'component/HOC/withAcl';
import {userSession} from 'logic/redux/actions';
import {AccessDeniedCard} from 'component/AccessDenied';
import {LoginInProgressCard} from 'component/LoginInProgress';
import {passwordError as PW_ERROR} from 'logic/service/constraint/user';
import {customerReferViaWeb} from 'logic/redux/actions';
import {makeStyles, useTheme} from '@material-ui/styles';
import AdvancedInputValidationHelperText from 'component/AdvancedInputValidationHelperText';
import userConstraint from 'logic/service/constraint/user';
import {customerActivation, TCustomerActivation} from 'logic/redux/state/customer_activation';
import ErrorMessageBar from 'component/MessageBar/ErrorMessageBar';
import SuccessMessageBar from 'component/MessageBar/SuccessMessageBar';
import isEmail from 'logic/utils/validation/isEmail';
import DefaultBasicCard from 'component/Card/DefaultBasicCard';
import {Typography} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import CustomInputLabel from 'component/CustomInputLabel';
import CustomInput from 'component/CustomInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import VisibleIcon from 'lib/Icon/Visible';
import InvisibleIcon from 'lib/Icon/Invisible';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import {smallIcon} from 'component/Icon';
import AsyncContainer from 'component/AsyncContainer';
import useFormData from 'logic/hook/useFormData';
import SendCodeButton from 'component/SendCodeButton';
import rakutenConversion from 'logic/service/rakuten_conversion';
import {customerSendSmsCode, TCustomerSendSmsCode, customerSendSmsCodeLoading} from 'logic/redux/state/customer_send_sms_code';
import usePageNavigator, {pages} from 'page/Layout/pages';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  fCard: {
    boxShadow: 'none',
    padding: '0px 16px 0 16px',
  },
  title: {
    marginLeft: 16,
    marginRight: 16,
    marginTop: 16,
  },
  form: {
    marginTop: 20,
  },
  err: {
    color: theme.status.danger,
  },
  msgGp: {
    marginTop: 16,
    marginRight: 16,
  },
  msgBar: {
    marginTop: 8,
    marginRight: 16,
  },
  nr: {
    marginTop: 16,
    marginLeft: 16,
    marginRight: 16,
  },
  btn: {
    marginTop: 12,
  },
}));

function CustomerActivation({
  userSession,
  pageControl,
  location,
  customerActivation,
  sync,
  addReferral,
  registerRef,
  activate,
  customerSendSmsCode,
  send,
}) {
  const theme = useTheme();
  const classes = useStyles();
  const pageNavigator = usePageNavigator();

  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [nonceError, setNonceError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [showRules, setShowRules] = useState(false);
  const [violations, setViolations] = useState([]);
  const [formData, formDataOnChange, setFormData] = useFormData({
    nonce: '',
    email: '',
    password: '',
  });

  useEffect(
    () => {
      if (userSession.user.isActive) {
        // pageControl.toPage(location, URI.WORLD_GATE);
        if (customerActivation.user.id) {
          rakutenConversion({userId: customerActivation.user.id});
        }
        pageNavigator.redirectTo(pages.HOME);
        // const register_ref = loadFromLocal('register_ref');
        // if (register_ref) {
        //   addReferral({ref: register_ref});
        //   saveInLocal('register_ref', ''); // clear this
        // }
        if (registerRef.ref) {
          addReferral({ref: registerRef.ref});
        }
      }

      // The activation is done. Re-sync the user.
      if (customerActivation.status === dataStatus.SUCCESS) {
        sync(); // make the user goto home page
      }
    },
    [
      pageControl,
      location,
      userSession.user.isActive,
      customerActivation.status,
      sync,
      addReferral,
      registerRef.ref
    ]
  );

  // Reset the form every time when the component is mounted.
  // This is to solve the problem that sometimes browsers might automatically populate these fields (email for activation code and password for password).
  useEffect(
    () => {
      setFormData({
        nonce: '',
        email: '',
        password: '',
      });
    },
    [
      setFormData,
    ]
  );

  function activateCustomer() {
    const isValid = validateFields();
    if (isValid) {
      activate({
        mobile: userSession.user.mobile,
        email: formData.email.replace(/\s+/g, ''),
        nonce: formData.nonce,
        password: formData.password,
      });
    }
  }

  function renderPasswordRules() {
    if (showRules) {
      return (
        <AdvancedInputValidationHelperText
          constraints={[
            {
              k: PW_ERROR.NO_UPPER_CASE, v: 'At least one upper case letter (A-Z).',
            },
            {
              k: PW_ERROR.NO_LOWER_CASE, v: 'At least one lower case letter (a-z).',
            },
            {
              k: PW_ERROR.NO_NUMBER, v: 'At least one number (0-9).',
            },
            {
              k: PW_ERROR.LENGTH_VIOLATION, v: 'Length between 8 and 20.',
            }
          ]}
          violations={violations}
        />
      );
    } else {
      return null;
    }
  }

  function getActivateAccountMessage() {
    if (customerActivation.status === dataStatus.FAILURE) {
      return (
        customerActivation.errors.map((msg, index) => {
          return (
            <ErrorMessageBar className={classes.msgGp} key={`error_${index}`}>{msg}</ErrorMessageBar>
          );
        })
      );
    } else if (customerActivation.status === dataStatus.SUCCESS) {
      return (
        <SuccessMessageBar className={classes.msgGp}>New account has been successfully activated.</SuccessMessageBar>
      );
    }
    return <div/>;
  }

  function handleKeyPress(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      activateCustomer();
    }
  }

  function handleMouseDownPassword(event) {
    event.preventDefault();
  }

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  function validateFields() {
    let isValid = true;

    //check password
    if (passwordError || formData.password === '') {
      setPasswordError(true);
      isValid = false;
    }

    //check nonce
    if (!formData.nonce) {
      setNonceError(true);
      isValid = false;
    } else {
      setNonceError(false);
    }

    //check email
    let em = formData.email.replace(/\s+/g, '');
    if (!em || !isEmail(em)) {
      setEmailError(true);
      isValid = false;
    } else {
      setEmailError(false);
    }

    return isValid;
  }

  function checkPassword(event) {
    formDataOnChange('password')(event);
    const result = userConstraint('password', event.target.value);
    if (result.isPassed) {
      setPasswordError(false);
    } else {
      setPasswordError(true);
    }
    if (!showRules) {
      setShowRules(true);
    }
    setViolations(result.errors);
  }

  function getResendCodeMessage() {
    if (customerSendSmsCode.status === dataStatus.FAILURE) {
      return(
        customerSendSmsCode.errors.map((msg, index) => {
          return (
            <ErrorMessageBar key={`error_${index}`} className={classes.msgGp}>{msg}</ErrorMessageBar>
          );
        })
      );
    } else if (customerSendSmsCode.status === dataStatus.SUCCESS) {
      return (
        <SuccessMessageBar className={classes.msgGp}>A new code has been successfully sent.</SuccessMessageBar>
      );
    }
    return <div/>;
  }

  function handleSendCode() {
    if (userSession.user.mobile) {
      send({
        mobile: userSession.user.mobile,
      });
    }
  }

  return (
    <DefaultBasicCard
      enableBottomRightButton={true}
      bottomRightButtonLabel={'Next'}
      bottomRightButtonOnClick={activateCustomer}
    >
      <div className={classes.root}>
        <Typography variant={'body1'} className={classes.title}>We have sent the activation code to your mobile.</Typography>
        <div className={classes.fCard}>
          <FormControl error={nonceError} key={'nonceInput'} className={classes.form} fullWidth={true}>
            <CustomInputLabel
              htmlFor='nonce-input'
              shrink={true}
            >
              Mobile Activation Code
            </CustomInputLabel>
            <CustomInput
              id='nonce-input'
              type='text'
              onChange={formDataOnChange('nonce')}
              onKeyPress={handleKeyPress}
              value={formData.nonce}
              autoComplete={'off'}
            />
            <FormHelperText
              id='nonce-hint-text'
              disabled
            >
              Please enter your activation code from the mobile.
            </FormHelperText>
          </FormControl>
          <SendCodeButton send={handleSendCode} title={'Send Code Again'} className={classes.btn} interval={30} freezeImmediately={true}/>
          <FormControl error={emailError} key={'emailInput'} className={classes.form} fullWidth={true}>
            <CustomInputLabel
              htmlFor='email-input'
              shrink={true}
            >
              Email
            </CustomInputLabel>
            <CustomInput
              id='email-input'
              type='text'
              onChange={formDataOnChange('email')}
              onKeyPress={handleKeyPress}
              value={formData.email}
              autoComplete={'off'}
            />
            <FormHelperText
              id='email-hint-text'
              disabled
            >
              Please enter your email.
            </FormHelperText>
          </FormControl>
          <FormControl key={'passwordInput'} error={passwordError} fullWidth={true} className={classes.form}>
            <CustomInputLabel
              htmlFor='password-input'
              shrink={true}
            >
              Password
            </CustomInputLabel>
            <CustomInput
              id='password-input'
              type={showPassword ? 'text' : 'password'}
              onChange={checkPassword}
              value={formData.password}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? (smallIcon(VisibleIcon, theme.palette.text.light)) : (smallIcon(InvisibleIcon, theme.palette.text.light))}
                  </IconButton>
                </InputAdornment>
              }
              onKeyPress={handleKeyPress}
              autoComplete={'new-password'}
            />
            {renderPasswordRules()}
          </FormControl>
        </div>
        <AsyncContainer alias={customerSendSmsCodeLoading}>
          {getResendCodeMessage()}
        </AsyncContainer>
        {getActivateAccountMessage()}
      </div>
    </DefaultBasicCard>
  );
}

CustomerActivation.propTypes = {
  pageControl: TObject.isRequired,
  customerActivation: TCustomerActivation.isRequired,
  customerSendSmsCode: TCustomerSendSmsCode.isRequired,
};

function mapStateToProps(state) {
  return {
    customerActivation: state.customerActivation,
    registerRef: state.registerRef,
    userSession: state.userSession,
    customerSendSmsCode: state.customerSendSmsCode,
  }
}

const mapDispatchToProps = {
  sync: userSession.syncRT,
  addReferral: customerReferViaWeb.addReferral,
  activate: customerActivation.activate,
  send: customerSendSmsCode.send,
};

export default connect(mapStateToProps, mapDispatchToProps)(withAcl(
  CustomerActivation,
  {
    allowInactive: true,
    AccessDenied: (<AccessDeniedCard/>),
    LoginInProgress: (<LoginInProgressCard/>),
  })
);
