import React from 'react';
import {makeStyles, useTheme} from '@material-ui/styles';
import config from 'lib/Core/config';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import {SlideUp, SlideDown} from 'component/Animation/Slide';
import CloseIcon from 'lib/Icon/Close';
import {TBool, TFunction, TObject, TString, TNode, declareValues} from 'lib/Core/prop_types';
import DefaultBasicCard from './DefaultBasicCard';
import {smallIcon} from '../Icon';
import Zoom from '../Animation/Zoom';

const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: 10,
    border: 'none',
    overflow: 'hidden',
    margin: 0,
    width: ({config}) => config.getWindowInnerWidth() - 16,
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
}));

function getTransition(transition) {
  switch (transition) {
    case 'slideUp':
      return SlideUp;
    case 'slideDown' :
      return SlideDown;
    case 'zoom':
      return Zoom;
    default:
      return SlideDown;
  }
}

function DefaultDialogCard({
  fullScreen = false,
  children,
  TopBar = null,
  onClose = () => {},
  isOpen = false,
  enableBottomLeftButton = false,
  bottomLeftButtonLabel = null,
  bottomLeftButtonOnClick = () => {},
  bottomRightButtonLabel = '',
  bottomRightButtonOnClick = () => {},
  transition = 'slideDown',
  enableTopRightButton = true,
  TopRightButton = null,
  enableBottomRightButton = true,
  style,
  cardStyle,
}) {
  const classes = useStyles({config});
  const theme = useTheme();

  const _TopRightButton = (enableTopRightButton ? (TopRightButton ? TopRightButton : (<IconButton style={{position: 'absolute', right: -8, zIndex: 999}} onClick={onClose}>
    {smallIcon(CloseIcon, theme.design.default.dark)}
  </IconButton>)) : null);

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={getTransition(transition)}
      transitionDuration={{
        enter: 200,
        exit: 200,
      }}
      fullScreen={fullScreen}
      onClose={onClose}
      classes={{paper: classes.paper}}
      disableAutoFocus={true}
      style={style}
    >
      <DefaultBasicCard
        style={cardStyle}
        TopBar={TopBar}
        TopRightButton={_TopRightButton}
        enableLeftStripe={false}
        enableBottomLeftButton={enableBottomLeftButton}
        bottomLeftButtonLabel={bottomLeftButtonLabel}
        bottomLeftButtonOnClick={bottomLeftButtonOnClick}
        enableBottomRightButton={enableBottomRightButton}
        bottomRightButtonLabel={bottomRightButtonLabel}
        bottomRightButtonOnClick={bottomRightButtonOnClick}
        enableTransition={false}
      >
        {children}
      </DefaultBasicCard>
    </Dialog>
  );
}

DefaultDialogCard.propTypes = {
  children: TNode,
  isOpen: TBool,
  onClose: TFunction,
  TopBar: TObject,
  enableBottomLeftButton: TBool,
  bottomLeftButtonLabel: TString,
  bottomLeftButtonOnClick: TFunction,
  bottomRightButtonLabel: TString,
  bottomRightButtonOnClick: TFunction,
  enableTransition: TBool,
  transition: declareValues(['slideUp', 'slideDown']),
  enableTopRightButton: TBool,
  enableBottomRightButton: TBool,
  fullScreen: TBool,
  style: TObject,
};

export default DefaultDialogCard;
