export function defaultValueWhen(condition = (value) => {
  return value === undefined || value === null;
}) {
  return function (value, defaultValue = '') {
    if (condition(value)) {
      return defaultValue;
    }
    return value;
  }
}

export default defaultValueWhen();
