import createState from './state';
import {declareObject, TBool} from 'lib/Core/prop_types';

export const TMaintenanceMode = declareObject({
  on: TBool.isRequired,
});

export default createState(
  'maintenanceMode',
  {
    on: false,
    message: null,
    image: null,
  },
  ({reduce}) => {
    reduce('MAINTENANCE_MODE_ON', function (state, action) {
      let data = Object.assign({}, state);
      data.on = true;
      data.message = action.e.data.message;
      data.image = action.e.data.display_image;
      return data;
    });

    reduce('MAINTENANCE_MODE_OFF', function (state, action) {
      let data = Object.assign({}, state);
      data.on = false;
      data.message = null;
      data.image = null;
      return data;
    });
  }
);

export const maintenanceMode = {
  show: e => {
    return ({type: 'MAINTENANCE_MODE_ON', e});
  },
  hide: () => ({type: 'MAINTENANCE_MODE_OFF'}),
};
