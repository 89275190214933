import React from 'react';
import {makeStyles} from '@material-ui/styles';
import SafeArea from 'component/SafeArea';
import Typography from '@material-ui/core/Typography';
import useConfig from 'lib/Core/hook/useConfig';
import Image from 'component/Image';
import getS3ImagePath from 'logic/utils/getS3ImagePath';
import {toAppStore} from 'component/AppDownloadIcon/Icons';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#FED900',
    minHeight: ({getWindowInnerHeight}) => (getWindowInnerHeight()),
    maxWidth: ({getCardMaxWidth}) => getCardMaxWidth(),
    margin: 'auto',
  },
  appIcons: {
    display: 'flex',
    // width: '100%',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginTop: -96,
  },
  cardArea: {
    marginLeft: 8,
    marginRight: 8,
    marginTop: 16,
  },
  descArea: {
    margin: 16,
  },
  bottomAppIcons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    paddingTop: 16,
    paddingBottom: 32,
  },
}));

function CampaignNewYear2021Page() {
  const config = useConfig();
  const {getWindowInnerWidth, getWindowInnerHeight, getCardMaxWidth} = config;
  const classes = useStyles({getWindowInnerHeight, getCardMaxWidth});
  const _imgFullWidth = Math.min(getWindowInnerWidth(), getCardMaxWidth());

  return (
    <SafeArea>
      <div className={classes.root}>
        <Image 
          path={getS3ImagePath('/register/campaign-new-year-2021-1.png')} 
          width={_imgFullWidth} 
          aspectRatio={350/375} 
        />
        <div className={classes.appIcons}>
          <Image 
            path={getS3ImagePath('/register/campaign-new-year-2021-2.png')} 
            width={_imgFullWidth * 0.6} 
            aspectRatio={97/375}
            onClick={toAppStore}
          />
        </div>
        <div className={classes.cardArea}>
          <Image 
            path={getS3ImagePath('/register/campaign-new-year-2021-5.png')} 
            width={_imgFullWidth - 16}
            aspectRatio={802/687} 
          />
        </div>
        <div className={classes.descArea}>
          <Typography variant={'body1'}>活动须知：</Typography>
          <Typography variant={'body1'}>本活动于2021年3月1日截止。</Typography>
          <Typography variant={'body1'}>本活动最终解释权归Tanggram和EASI所有。</Typography>
          <Typography variant={'body1'}>*真实投资收益请以Tanggram funds 为准。</Typography>
        </div>
        <div className={classes.bottomAppIcons}>
          <Image 
            path={getS3ImagePath('/register/campaign-new-year-2021-2.png')} 
            width={_imgFullWidth * 0.6} 
            aspectRatio={97/375}
            onClick={toAppStore}
          />
        </div>
      </div>
    </SafeArea>
  );
}

CampaignNewYear2021Page.propTypes = {
};

export default CampaignNewYear2021Page;
