import request from 'superagent';
import auth from '../auth';
import {toUrl} from './utils';
import getEnv from 'tanggram';

const aclHeader = 'tanggram-access-id';
const appVersionHeader = 'tanggram-app-version';
const appVersion = getEnv().appVersion ? getEnv().appVersion : '_NO_VER';

class Resource {
  constructor(config) {
    this._config = config;
  }

  get config() {
    return this._config;
  }

  async get(uri, queries) {
    const url = toUrl(this.config.baseUrl, uri);
    return await request
      .get(url)
      .set(appVersionHeader, appVersion)
      .withCredentials()
      .query(queries);
  }

  async post(uri, postData) {
    const url = toUrl(this.config.baseUrl, uri);
    return await request
      .post(url)
      .set('content-type', 'application/json')
      .set(appVersionHeader, appVersion)
      .withCredentials()
      .send(JSON.stringify(postData));
  }
}

class AclResource {
  constructor(config) {
    this._config = config;
  }

  get config() {
    return this._config;
  }

  async get(uri, queries) {
    const url = toUrl(this.config.baseUrl, uri);
    // console.log(auth.getSession());
    return await request
      .get(url)
      .withCredentials()
      .set(appVersionHeader, appVersion)
      .set(aclHeader, auth.getSession().getId())
      .query(queries);
  }

  async post(uri, postData) {
    const url = toUrl(this.config.baseUrl, uri);
    return await request
      .post(url)
      .withCredentials()
      .set('content-type', 'application/json')
      .set(appVersionHeader, appVersion)
      .set(aclHeader, auth.getSession().getId())
      .send(JSON.stringify(postData));  
    }
}

export {
  aclHeader,
  Resource,
  AclResource,
};
