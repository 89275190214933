import React from 'react';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles({
  box: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  top: {
    alignSelf: 'flex-start',
    flexGrow: 1,
  },
  center: {
    // justifyContent: 'center',
    // flexGrow: 1,
  },
  bot: {
    flexGrow: 1,
    alignSelf: 'flex-end',
  },
});

function CenteredBox({children, style}) {
  const classes = useStyles();

  let customStyle = null;
  if (style !== undefined) {
    customStyle = style;
  }
  return (
    <div style={customStyle} className={classes.box}>
      <div className={classes.top}/>
      <div className={classes.center}>{children}</div>
      <div className={classes.bot}/>
    </div>
  );
}

CenteredBox.propTypes = {
};

export default CenteredBox;
