import React from 'react';
import classNames from 'classnames';
import {makeStyles} from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import {TString, TObject, TBool} from 'lib/Core/prop_types';
import useConfig from 'lib/Core/hook/useConfig';
import BodyBottomOffset from './BodyBottomOffset'

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: ({safeBottomHeight}) => safeBottomHeight,
    // // marginTop: -105,
    // // marginLeft: theme.spacing(1),
    // // marginRight: theme.spacing(1),
    // zIndex: 1,
    // marginBottom: 50,
    // // paddingTop: 5,
    // borderTopLeftRadius: 10,
    // borderTopRightRadius: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  topM: {
    display: 'block',
    width: '100%',
    height: 5,
  },
  title: {
    color: theme.palette.text.secondary,
    paddingLeft: 2,
    paddingRight: 2,
  },
}));

function Body({
  children,
  className,
  style,
  title,
  enableBottomOffset = false,
  // disableScrolling = false,
}) {
  const {getSafeAreaBottomHeight} = useConfig();
  const safeBottomHeight = getSafeAreaBottomHeight() ? getSafeAreaBottomHeight() : 0;
  // console.log(getSafeAreaBottomHeight);
  const classes = useStyles({safeBottomHeight});

  // const {getWindowSafeInnerHeight} = useConfig();
  // This will break the current scroll position and force the page to move to top.
  // const _style = styles(style, disableScrolling ? {height: getWindowSafeInnerHeight() - 150, overflowY: 'hidden'} : {});
  return (
    <div className={classNames(classes.root, className)} style={style}>
      {title && (<Typography variant='body1' align={'center'} className={classes.title}>{title}</Typography>)}
      <div className={classes.topM}/>
      {children}
      {enableBottomOffset && (<BodyBottomOffset/>)}
    </div>
  );
}

Body.propTypes = {
  className: TString,
  style: TObject,
  // disableScrolling: TBool,
  enableBottomOffset: TBool,
  title: TString,
};

export default Body;

const useFullSizeStyles = makeStyles({
  root: {
    margin: 0,
    zIndex: 1,
    padding: 0,
  },
});

export const FullSizeBody = (props) => {
  const classes = useFullSizeStyles();

  return (
    <Body className={classes.root} {...props}>
      {props.children}
    </Body>
  );
};