export const exceptionKeys = {
  OTHERS: 1,
  MAINTENANCE: 2,
};

export default function createException(key = exceptionKeys.OTHERS, data) {
  let value = 'Uncaught Exception';
  if (key === exceptionKeys.MAINTENANCE) {
    value = 'Maintenance Mode';
  }
  return {key, value, data};
}
