import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles({
  paper: {
    borderRadius: 10,
    padding: 0,
    marginLeft: 8,
    marginRight: 8,
  },
});

const CustomDialog = (props) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{
        paper: classes.paper,
      }}
      {...props}
    />
  );
};

export default CustomDialog;
