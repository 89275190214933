import getInnerHeight from 'ios-inner-height';
import {isIos} from './tanggram';
// import safeAreaInsets from 'safe-area-insets';

function createViewport() {
  let windowInnerWidth = null;
  let windowInnerHeight = null;
  let windowSafeInnerHeight = null;
  let safeAreaTopHeight = null;
  let safeAreaBottomHeight = null;
  const cardMaxWidth = 768;

  // Deal with ios.
  let isIosWithNotch = false;
  // iPhone x, xs, xr, xs max.
  if (isIos() && getWindowInnerHeight() >= 812) {
    isIosWithNotch = true;
  }

  function getWindowInnerWidth() {
    if (windowInnerWidth === null) {
      windowInnerWidth = window.innerWidth;
    }
    return windowInnerWidth;
  }

  function getWindowInnerHeight() {
    if (windowInnerHeight === null) {
      windowInnerHeight = getInnerHeight();
    }
    return windowInnerHeight;
  }

  function getWindowSafeInnerHeight() {
    if (windowSafeInnerHeight === null) {
      windowSafeInnerHeight = getWindowInnerHeight() - getSafeAreaTopHeight() - getSafeAreaBottomHeight();
    }
    return windowSafeInnerHeight;
  }

  function getSafeAreaTopHeight() {
    if (safeAreaTopHeight === null) {
      if (isIos()) {
        if (isIosWithNotch) {
          safeAreaTopHeight = 40;
        } else {
          safeAreaTopHeight = 20;
        }
      } else {
        safeAreaTopHeight = 0;
      }
    }
    return safeAreaTopHeight;
  }

  function getSafeAreaBottomHeight() {
    if (safeAreaBottomHeight === null) {
      if (isIos()) {
        if (isIosWithNotch) { // iPhone x, xs, xr, xs max.
          safeAreaBottomHeight = 20;
        } else {
          safeAreaBottomHeight = 0;
        }
      } else {
        safeAreaBottomHeight = 0;
      }
    }
    return safeAreaBottomHeight;
  }

  function getTopPosition(offset = 0) {
    return offset + getSafeAreaTopHeight();
  }

  function getBottomPosition(offset = 0) {
    return offset + getSafeAreaBottomHeight();
  }

  function getCardMaxWidth() {
    return cardMaxWidth;
  }

  return {
    getWindowInnerWidth,
    getWindowInnerHeight,
    getWindowSafeInnerHeight,
    getSafeAreaTopHeight,
    getSafeAreaBottomHeight,
    getTopPosition,
    getBottomPosition,
    getCardMaxWidth,
  }
}

export const {
  getWindowInnerWidth,
  getWindowInnerHeight,
  getWindowSafeInnerHeight,
  getSafeAreaBottomHeight,
  getSafeAreaTopHeight,
  getTopPosition,
  getBottomPosition,
  getCardMaxWidth,
} = createViewport();
