import {put, takeLeading} from 'redux-saga/effects';
import createState from './state';
import callReferViaWeb from '../../service/api/method/refer_via_web';
import {dataStatus, transformApiData} from '../transform';
import {
  getApiResponse,
  handleError,
  showLoading,
  hideLoading,
} from '../actions';

export default createState(
  'customerReferViaWeb',
  {
    status: dataStatus.INIT,
    errors: [],
  },
  ({addSaga, reduce, reduceError, reduceReset}) => {
    addSaga(function* () {
      yield takeLeading('CUSTOMER_REFER_VIA_WEB', function* (action) {
        try {
          yield put(showLoading());
          const apiData = yield callReferViaWeb(action.postData);
          yield put(getApiResponse('CUSTOMER_REFER_VIA_WEB_R', apiData));
          yield put(hideLoading());
        } catch (e) {
          yield put(handleError('CUSTOMER_REFER_VIA_WEB_F', e));
          yield put(hideLoading());
        }
      });
    });

    reduce('CUSTOMER_REFER_VIA_WEB_R', function (state, action) {
      let data = Object.assign({}, state);
      const apiData = transformApiData(action.data);
      data.status = apiData.status;
      if (data.status === dataStatus.FAILURE) {
        data.errors = apiData.errors;
      } else if (data.status === dataStatus.SUCCESS) {
      }
      return data;
    });
    reduceError('CUSTOMER_REFER_VIA_WEB_F');
    reduceReset('CUSTOMER_REFER_VIA_WEB_RESET');
  }
);

export const customerReferViaWeb = {
  addReferral: postData => ({type: 'CUSTOMER_REFER_VIA_WEB', postData}),
  reset: () => ({type: 'CUSTOMER_REFER_VIA_WEB_RESET'}),
};