import React from 'react';
import {TNumber} from 'lib/Core/prop_types';
import Standard from './Standard';

const AccessDenied = ({scale, minX = 0, minY = 0}) => {
  return (
    <Standard
      scale={scale}
      viewBox={{
        minX,
        minY,
        width: 313,
        height: 260,
      }}
    >
      <defs>
        <linearGradient x1='22.857%' y1='100%' x2='22.857%' y2='0%' id='linearGradient-1'>
          <stop stopColor='#FFE319' offset='0%' />
          <stop stopColor='#FFD028' offset='100%' />
        </linearGradient>
        <linearGradient x1='-11.673%' y1='-49.266%' x2='50%' y2='100%' id='linearGradient-2'>
          <stop stopColor='#F3F3F3' offset='0%' />
          <stop stopColor='#E3E4E4' offset='100%' />
        </linearGradient>
        <linearGradient x1='152.841%' y1='-104.273%' x2='50%' y2='100%' id='linearGradient-3'>
          <stop stopColor='#E8E9EA' offset='0%' />
          <stop stopColor='#CDCFD0' offset='100%' />
        </linearGradient>
        <polygon id='path-4' points='0.383622642 0.382770932 111.652585 0.382770932 111.652585 218.915094 0.383622642 218.915094'
        />
        <linearGradient x1='-24.586%' y1='-12.153%' x2='96.641%' y2='92.423%'
                        id='linearGradient-6'>
          <stop stopColor='#FFF4BB' offset='0%' />
          <stop stopColor='#FFE686' offset='100%' />
        </linearGradient>
        <linearGradient x1='9.881%' y1='-24.375%' x2='50%' y2='100%' id='linearGradient-7'>
          <stop stopColor='#FFE762' offset='0%' />
          <stop stopColor='#FFCA33' offset='100%' />
        </linearGradient>
        <linearGradient x1='-5.159%' y1='-16.043%' x2='97.042%' y2='103.859%'
                        id='linearGradient-8'>
          <stop stopColor='#EDCB51' offset='0%' />
          <stop stopColor='#D79B28' offset='100%' />
        </linearGradient>
        <linearGradient x1='7.217%' y1='0%' x2='95.771%' y2='96.298%' id='linearGradient-9'>
          <stop stopColor='#697B8C' offset='0%' />
          <stop stopColor='#48596A' offset='100%' />
        </linearGradient>
        <linearGradient x1='0%' y1='-24.49%' x2='94.233%' y2='95.992%' id='linearGradient-10'>
          <stop stopColor='#E5E5E5' offset='0%' />
          <stop stopColor='#C7C7C7' offset='100%' />
        </linearGradient>
        <linearGradient x1='58.511%' y1='-125.121%' x2='50%' y2='100%' id='linearGradient-11'>
          <stop stopColor='#FB9CAA' offset='0%' />
          <stop stopColor='#F56371' offset='100%' />
        </linearGradient>
        <linearGradient x1='269.615%' y1='-80.539%' x2='50%' y2='100%' id='linearGradient-12'>
          <stop stopColor='#F77B88' offset='0%' />
          <stop stopColor='#EB4550' offset='100%' />
        </linearGradient>
        <linearGradient x1='65.73%' y1='-39.823%' x2='50%' y2='100%' id='linearGradient-13'>
          <stop stopColor='#F77B88' offset='0%' />
          <stop stopColor='#EB4550' offset='100%' />
        </linearGradient>
        <linearGradient x1='50%' y1='0%' x2='50%' y2='100%' id='linearGradient-14'>
          <stop stopColor='#FB9CAA' offset='0%' />
          <stop stopColor='#F56371' offset='100%' />
        </linearGradient>
      </defs>
      <g id='Home' fill='none' fillRule='evenodd'>
        <g id='Access-Denied' transform='translate(-219 -300)'>
          <g id='Tangney-Denied' transform='translate(195 256)'>
            <rect id='Rectangle' fillRule='nonzero' width='360' height='360' />
            <path d='M335.943044,242.218986 C345.009946,170.821822 288.02304,168.934822 256.384946,130.86409 C216.993644,83.4637716 216.393447,52.4227546 165.621967,58.8377653 C115.004423,65.2333261 123.283903,88.5577323 81.2593936,143.055018 C40.7062029,195.644301 6.34240231,211.031025 33.9686674,268.093986 C52.3053394,305.968986 113.321035,285.589236 180.363032,284.388905 C247.405029,283.188575 326.063903,320.012118 335.943044,242.218986 Z'
                  id='Path-8' fillOpacity='0.1' fill='url(#linearGradient-1)' fillRule='nonzero'
            />
            <path d='M230.069042,249.905896 C229.860552,249.905896 229.653288,249.900991 229.44725,249.893019 C224.208005,249.670425 219.309703,246.786509 215.654986,241.772925 C211.894797,236.613396 209.595882,229.405755 209.007203,220.93 L215.124561,220.505047 C216.214231,236.192736 223.234231,243.491745 229.70725,243.765849 C236.09442,244.058349 243.628288,237.326557 246.051071,221.725943 C247.630693,211.555896 253.082108,205.271132 261.007203,204.483774 C269.192297,203.666981 278.123665,209.187075 280.914986,216.781651 L275.15942,218.897217 C273.335127,213.933915 267.004986,210.052311 261.613665,210.585802 C256.546118,211.089245 253.259939,215.267028 252.110788,222.667217 C248.978524,242.831934 238.427061,249.905896 230.069042,249.905896'
                  id='Fill-1' fill='url(#linearGradient-2)' />
            <path d='M107.865854,216.303472 C107.865854,221.123283 104.303118,225.109132 99.6672689,225.765877 L99.6672689,206.841679 C104.303118,207.498425 107.865854,211.484274 107.865854,216.303472'
                  id='Fill-3' fill='#CDCFD0' />
            <path d='M220.261892,216.304024 C220.261892,221.583741 215.981703,225.864542 210.701986,225.864542 C205.421656,225.864542 201.141467,221.583741 201.141467,216.304024 C201.141467,211.024307 205.421656,206.744118 210.701986,206.744118 C215.981703,206.744118 220.261892,211.024307 220.261892,216.304024'
                  id='Fill-5' fill='url(#linearGradient-3)' />
            <g id='Group-9' transform='translate(99.208 84.866)'>
              <mask id='mask-5' fill='#fff'>
                <use xlinkHref='#path-4' />
              </mask>
              <path d='M110.247113,218.857269 L111.59433,218.857269 L111.629896,3.19340094 C111.629896,0.694580189 108.608623,-0.556976415 106.841972,1.20967453 L0.383622642,107.668637 L1.79338679,107.726278 L0.46395283,107.726278 L0.436971698,208.812929 L111.652585,218.915524 L110.247113,218.857269 Z'
                    id='Fill-7' fill='url(#linearGradient-6)' mask='url(#mask-5)' />
            </g>
            <polygon id='Fill-10' fill='url(#linearGradient-7)' points='99.6674528 303.434623 210.799057 303.434623 210.799057 192.303019 99.6674528 192.303019'
            />
            <path d='M121.690618,217.839434 C116.975052,217.839434 113.138212,221.676274 113.138212,226.39184 L113.138212,269.345802 C113.138212,274.061981 116.975052,277.898208 121.690618,277.898208 L188.782882,277.898208 C193.498448,277.898208 197.334675,274.061981 197.334675,269.345802 L197.334675,226.39184 C197.334675,221.676274 193.498448,217.839434 188.782882,217.839434 L121.690618,217.839434 Z M121.690618,288.366887 C111.202929,288.366887 102.671373,279.834717 102.671373,269.345802 L102.671373,226.39184 C102.671373,215.904764 111.202929,207.372594 121.690618,207.372594 L188.782882,207.372594 C199.270571,207.372594 207.802741,215.904764 207.802741,226.39184 L207.802741,269.345802 C207.802741,279.834717 199.270571,288.366887 188.782882,288.366887 L121.690618,288.366887 Z'
                  id='Fill-12' fill='url(#linearGradient-8)' />
            <path d='M188.142939,281.08008 L122.352514,281.08008 C115.060863,281.08008 109.149542,275.168146 109.149542,267.877108 L109.149542,227.861637 C109.149542,220.569373 115.060863,214.658665 122.352514,214.658665 L188.142939,214.658665 C195.43459,214.658665 201.34591,220.569373 201.34591,227.861637 L201.34591,267.877108 C201.34591,275.168146 195.43459,281.08008 188.142939,281.08008'
                  id='Fill-13' fill='url(#linearGradient-9)' />
            <path d='M135.70934,215.668679 C122.522925,215.668679 111.794245,226.396745 111.794245,239.583774 L111.794245,256.154481 C111.794245,269.341509 122.522925,280.069575 135.70934,280.069575 L174.74184,280.069575 C187.928868,280.069575 198.656934,269.341509 198.656934,256.154481 L198.656934,239.583774 C198.656934,226.396745 187.928868,215.668679 174.74184,215.668679 L135.70934,215.668679 Z M174.74184,286.195519 L135.70934,286.195519 C119.144764,286.195519 105.668915,272.719057 105.668915,256.154481 L105.668915,239.583774 C105.668915,223.019198 119.144764,209.543349 135.70934,209.543349 L174.74184,209.543349 C191.306415,209.543349 204.782264,223.019198 204.782264,239.583774 L204.782264,256.154481 C204.782264,272.719057 191.306415,286.195519 174.74184,286.195519 Z'
                  id='Fill-14' fill='url(#linearGradient-10)' />
            <path d='M121.690495,215.668679 C115.777948,215.668679 110.967335,220.479292 110.967335,226.39184 L110.967335,269.346415 C110.967335,275.258962 115.777948,280.069575 121.690495,280.069575 L188.782759,280.069575 C194.695307,280.069575 199.50592,275.258962 199.50592,269.346415 L199.50592,226.39184 C199.50592,220.479292 194.695307,215.668679 188.782759,215.668679 L121.690495,215.668679 Z M188.782759,286.195519 L121.690495,286.195519 C112.400401,286.195519 104.842005,278.637736 104.842005,269.346415 L104.842005,226.39184 C104.842005,217.101745 112.400401,209.543349 121.690495,209.543349 L188.782759,209.543349 C198.072854,209.543349 205.63125,217.101745 205.63125,226.39184 L205.63125,269.346415 C205.63125,278.637736 198.072854,286.195519 188.782759,286.195519 Z'
                  id='Fill-15' fill='#FFF' />
            <path d='M184.381769,247.86925 C184.381769,255.54109 181.220071,261.760241 177.319458,261.760241 C173.418844,261.760241 177.789788,255.54109 177.789788,247.86925 C177.789788,240.196797 173.418844,233.978259 177.319458,233.978259 C181.220071,233.978259 184.381769,240.196797 184.381769,247.86925'
                  id='Fill-16' fill='#FFF' opacity='0.2' />
            <path d='M278.036896,227.399953 C272.757179,227.399953 268.476991,223.119764 268.476991,217.839434 C268.476991,212.559717 272.757179,208.279528 278.036896,208.279528 C283.317226,208.279528 287.597415,212.559717 287.597415,217.839434 C287.597415,223.119764 283.317226,227.399953 278.036896,227.399953 Z'
                  id='Fill-17' fill='url(#linearGradient-3)' />
            <path d='M144.400698,177.86842 L143.910132,177.86842 C141.972396,177.86842 140.386642,176.282665 140.386642,174.344929 L140.386642,162.966863 C140.386642,161.028514 141.972396,159.443373 143.910132,159.443373 L144.400698,159.443373 C146.338434,159.443373 147.924189,161.028514 147.924189,162.966863 L147.924189,174.344929 C147.924189,176.282665 146.338434,177.86842 144.400698,177.86842'
                  id='Fill-18' fill='#58595B' />
            <path d='M170.713311,177.86842 L170.222745,177.86842 C168.285009,177.86842 166.699255,176.282665 166.699255,174.344929 L166.699255,162.966863 C166.699255,161.028514 168.285009,159.443373 170.222745,159.443373 L170.713311,159.443373 C172.651047,159.443373 174.236802,161.028514 174.236802,162.966863 L174.236802,174.344929 C174.236802,176.282665 172.651047,177.86842 170.713311,177.86842'
                  id='Fill-19' fill='#58595B' />
            <path d='M149.969236,187.017967 C149.477443,187.017967 149.06966,186.62858 149.050651,186.133108 C149.031642,185.625373 149.42716,185.197967 149.934896,185.178958 L164.618764,184.622165 C165.12834,184.591505 165.553292,184.998061 165.572915,185.50641 C165.591925,186.013533 165.196406,186.441552 164.68867,186.460561 L150.004802,187.017354 C149.992538,187.017967 149.980887,187.017967 149.969236,187.017967'
                  id='Fill-20' fill='#58595B' />
            <path d='M90.8632642,221.377396 C84.6005755,221.377396 77.1206698,219.413292 73.2887358,211.175462 C65.2998679,194.000132 79.1295377,158.011594 100.691142,139.86433 L104.639585,144.555981 C85.6031698,160.577868 72.1052453,194.090887 78.8493019,208.589566 C81.4566604,214.194896 87.0993962,216.289613 95.6211415,214.818528 L96.665434,220.860462 C95.0036415,221.148057 93.0039717,221.377396 90.8632642,221.377396'
                  id='Fill-21' fill='url(#linearGradient-2)' />
            <path d='M99.6675142,206.841863 L99.6675142,225.765448 C99.2198726,225.832901 98.7660991,225.864175 98.3061934,225.864175 C93.0264764,225.864175 88.7462877,221.583373 88.7462877,216.303656 C88.7462877,211.023939 93.0264764,206.74375 98.3061934,206.74375 C98.7660991,206.74375 99.2198726,206.77441 99.6675142,206.841863'
                  id='Fill-22' fill='url(#linearGradient-3)' />
            <polygon id='Fill-26' fill='url(#linearGradient-11)' points='168.561311 97 110.350571 99.7745377 110.350571 155.246519'
            />
            <polygon id='Fill-23' fill='url(#linearGradient-12)' points='82.6140283 127.508137 110.350019 99.7721462 110.350019 155.244127'
            />
            <polygon id='Fill-24' fill='url(#linearGradient-11)' points='137.985811 71.8206132 165.721802 99.5566038 165.721802 44.0846226'
            />
            <polygon id='Fill-27' fill='url(#linearGradient-12)' points='193.458344 72.149967 165.722354 44.4139764 165.722354 99.8859575'
            />
            <polygon id='Fill-28' fill='url(#linearGradient-13)' points='82.5132783 71.7367877 110.249269 44.0007972 110.249269 99.4727783'
            />
            <polygon id='Fill-29' fill='url(#linearGradient-14)' points='166.348197 100.998497 110.249821 44.0007972 110.249821 99.4727783'
            />
            <polygon id='Fill-30' fill='url(#linearGradient-11)' points='137.880033 127.402113 165.616024 155.138104 165.616024 99.6661226'
            />
            <polygon id='Fill-31' fill='url(#linearGradient-12)' points='193.352505 127.402113 165.616514 99.6661226 165.616514 155.138104'
            />
            <polygon id='Fill-25' fill='url(#linearGradient-12)' points='110.241972 99.5569104 137.977962 127.292901 165.714566 99.5569104 137.977962 71.8209198'
            />
            <path d='M93.1052736,142.210156 C93.1052736,147.489873 97.3854623,151.770675 102.665792,151.770675 C107.945509,151.770675 112.225698,147.489873 112.225698,142.210156 C112.225698,136.930439 107.945509,132.649637 102.665792,132.649637 C97.3854623,132.649637 93.1052736,136.930439 93.1052736,142.210156'
                  id='Fill-32' fill='url(#linearGradient-3)' />
            <path d='M160.530816,239.168203 L149.946854,239.168203 L149.946854,236.195986 C149.946854,232.936175 152.318127,230.281599 155.238835,230.281599 C158.15525,230.281599 160.530816,232.936175 160.530816,236.195986 L160.530816,239.168203 Z M155.238835,256.250939 C153.688646,256.250939 152.429731,254.996929 152.429731,253.446127 C152.429731,252.338675 153.06992,251.384524 154.001995,250.925231 L154.001995,247.144807 L156.476288,247.144807 L156.476288,250.925231 C157.404071,251.384524 158.043646,252.338675 158.043646,253.446127 C158.043646,254.996929 156.789637,256.250939 155.238835,256.250939 Z M167.48091,239.168203 L163.108127,239.168203 L163.108127,236.195986 C163.108127,231.514146 159.577278,227.704288 155.238835,227.704288 C150.896099,227.704288 147.370156,231.514146 147.370156,236.195986 L147.370156,239.168203 L142.997373,239.168203 C142.185486,239.168203 141.523835,239.825561 141.523835,240.637448 L141.523835,262.23891 C141.523835,263.050797 142.185486,263.708156 142.997373,263.708156 L167.48091,263.708156 C168.292797,263.708156 168.949542,263.050797 168.949542,262.23891 L168.949542,240.637448 C168.949542,239.825561 168.292797,239.168203 167.48091,239.168203 Z'
                  id='Fill-33' fill='#F5F5D2' />
          </g>
        </g>
      </g>
    </Standard>
  );
};

AccessDenied.propTypes = {
  scale: TNumber.isRequired,
};

export default AccessDenied;
