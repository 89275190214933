// These are used to match with the ones from the rest.
export const role = {
  CUSTOMER: 'CUSTOMER',
  MERCHANT: 'MERCHANT',
  ADMIN: 'ADMIN',
};

export function hasRole(user, role) {
  if (user === null) {
    return false;
  }
  const roles = user.roles;
  if (roles) {
    return roles.indexOf(role) !== -1;
  }
  return false;
}

export function isCustomer(user) {
  return hasRole(user, role.CUSTOMER);
}

export function isMerchant(user) {
  return hasRole(user, role.MERCHANT);
}

export function isAdmin(user) {
  return hasRole(user, role.ADMIN);
}