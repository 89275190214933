import React from 'react';
import {makeStyles} from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import BlockCard from '../BlockCard';
import NavLayout, {Body} from '../NavLayout';
import {BLANK_MENU_ITEM_ID, BlankSideMenu} from '../SideMenu';
import transitions from 'logic/const/transition';
import {AccessDeniedSVG} from '../SVG';
import YellowTriangleButton from '../TriangleButton/YellowTriangleButton';
import {URI} from 'page/Layout/uri';
import withPageControl from '../HOC/withPageControl';

const useStyles = makeStyles({
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: 16,
    marginRight: 16,
    marginTop: 36,
  },
  text: {
    marginTop: 20,
    marginBottom: -16,
  }
});

export const AccessDeniedCard = withPageControl(({
  message = 'You have no access to this card.',
  pageControl,
  location
}) => {
  const classes = useStyles();

  return (
    <BlockCard
      transition={transitions.SLIDE_TO_RIGHT}
      RightTriangleButton={(<YellowTriangleButton label={'OK'} onClick={() => pageControl.toPage(location, URI.LOGIN)}/>)}
    >
      <div className={classes.body}>
        <AccessDeniedSVG scale={0.5}/>
        <Typography variant='body1' color='textSecondary'  className={classes.text}>{message}</Typography>
      </div>
    </BlockCard>
  );
});

export function AccessDeniedLayout({
  children,
  title = 'Access Denied',
}) {
  return (
    <NavLayout
      SideMenu={(<BlankSideMenu activeItem={BLANK_MENU_ITEM_ID.LOGIN}/>)}
    >
      <Body title={title}>
        {children}
      </Body>
    </NavLayout>
  );
}

export default function AccessDenied({
  title = 'Access Denied',
  message = 'You have no access to this page. Try login first.'
}) {
  return (
    <AccessDeniedLayout>
      <AccessDeniedCard
        message={message}
      />
    </AccessDeniedLayout>
  );
}