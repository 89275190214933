import React from 'react';
import {useTheme} from '@material-ui/styles';
import BlankDrawer from './BlankDrawer';
import {URI} from 'page/Layout/uri';
import MenuItem from './MenuItem';
import {
  Input as InputIcon,
  HowToReg as HowToRegIcon,
  FindInPage as FindInPageIcon,
  Help as HelpIcon,
} from '@material-ui/icons';
// import SearchIcon from 'lib/Icon/Search'
// import {floatingButtonIcon} from '../Icon';


export const BLANK_MENU_ITEM_ID = {
  LOGIN: 1,
  CUSTOMER_REGISTER: 2,
  CUSTOMER_FORGOT_PASSWORD: 3,
  FAQ: 4,
};

function BlankSideMenu({
  style,
  location,
  onClick,
  activeItem,
}) {
  const theme = useTheme();

  const highlightColor = theme.design.default.dark;
  const defaultColor = '#FFFFFF';

  return (
    <BlankDrawer
      themeColor={highlightColor}
      // renderDrawerButton={(openDrawer) => {
      //   return (
      //     <DrawerButton
      //       onClick={openDrawer}
      //       style={{backgroundImage: theme.design.default.triangle}}
      //     />
      //   );
      // }}
      renderMenuList={(onMenuItemClick) => {
        return (
          <div>
            <MenuItem
              id={BLANK_MENU_ITEM_ID.LOGIN}
              onClick={onMenuItemClick(URI.LOGIN)}
              label={'Login'}
              highlight={(activeItem === BLANK_MENU_ITEM_ID.LOGIN)}
              highlightColor={highlightColor}
              color={defaultColor}
              Icon={(<InputIcon nativeColor={activeItem === BLANK_MENU_ITEM_ID.LOGIN ? highlightColor : defaultColor}/>)}
            />
            <MenuItem
              id={BLANK_MENU_ITEM_ID.CUSTOMER_REGISTER}
              onClick={onMenuItemClick(URI.CUSTOMER_INVITATION_CODE)}
              label={'Register'}
              highlight={(activeItem === BLANK_MENU_ITEM_ID.CUSTOMER_REGISTER)}
              highlightColor={highlightColor}
              color={defaultColor}
              Icon={(<HowToRegIcon nativeColor={activeItem === BLANK_MENU_ITEM_ID.CUSTOMER_REGISTER ? highlightColor : defaultColor}/>)}
            />
            <MenuItem
              id={BLANK_MENU_ITEM_ID.CUSTOMER_FORGOT_PASSWORD}
              onClick={onMenuItemClick(URI.CUSTOMER_FORGOT_PASSWORD)}
              label={'Forgot Password'}
              highlight={(activeItem === BLANK_MENU_ITEM_ID.CUSTOMER_FORGOT_PASSWORD)}
              highlightColor={highlightColor}
              color={defaultColor}
              Icon={(<FindInPageIcon nativeColor={activeItem === BLANK_MENU_ITEM_ID.CUSTOMER_FORGOT_PASSWORD ? highlightColor : defaultColor}/>)}
            />
            <MenuItem
              id={BLANK_MENU_ITEM_ID.FAQ}
              onClick={onMenuItemClick(URI.FAQ)}
              label={'FAQ'}
              highlight={(activeItem === BLANK_MENU_ITEM_ID.FAQ)}
              highlightColor={highlightColor}
              color={defaultColor}
              Icon={(<HelpIcon nativeColor={activeItem === BLANK_MENU_ITEM_ID.FAQ ? highlightColor : defaultColor}/>)}
            />
          </div>
        )
      }}
    />
  );
}

BlankSideMenu.propTypes = {
};

export default BlankSideMenu;
