import {loadFromLocal, removeFromLocal, saveInLocal} from '../local_storage';

export function createSession({
  // id = 'a9977945c6e6d3ba20084c15fd9d5a61',
  id = null,
}) {
  function getId() {
    return id;
  }

  return {
    getId,
  }
}

export async function destroySessionSync(session) {
  /**
   * @todo Destroy the server session.
   */
}

const localKey = 'tanggram-access-session';

export function saveSessionLocally(session) {
  if (session.getId()) {
    saveInLocal(localKey, session.getId());
  }
}

export function clearSessionLocally() {
  removeFromLocal(localKey);
}

export function loadSessionLocally() {
  return createSession({id: loadFromLocal(localKey)})
}
