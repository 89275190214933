import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router';
import {ConnectedRouter} from 'connected-react-router';
import {registerRef} from 'logic/redux/state/register_ref';
import {getPagePath, pages} from 'page/Layout/pages';
import CustomerRegisterPage from 'page/CustomerRegisterPage';
import CustomerActivationPage from 'page/CustomerActivationPage'
import WorldGatePage from 'page/WorldGatePage';
import {TObject} from 'lib/Core/prop_types';
import MerchantEnquiryFormPage from 'page/MerchantEnquiryFormPage';
import HomePage from 'page/HomePage';
import SplashPage from 'page/SplashPage';
import CoffeeZeroVoucherPage from 'page/CoffeeZeroVoucherPage';
import CampaignNewYear2021Page from 'page/CampaignNewYear2021Page';
import EmailUnsubscribePage from 'page/EmailUnsubscribePage';
// import LoginPage from 'page/LoginPage';

function Routes({registerRef, setRef, ...props}) {
  useEffect(
    () => {
      const queryString = props.history.location.search;
      if (queryString === undefined || queryString === '') {
      } else {
        const urlParams = new URLSearchParams(queryString);
        const actualUrl = decodeURIComponent(urlParams.get('ref')); //console.log(decodeURIComponent('QUVJaDNNRE10dUdHeUJ2K09nNTFXMlB3Qy9RZU9tK0MrNnJLVlBSNjdBRT0%3D%3A9eFXPULxrpTBaH%2BIqRMGhA%3D%3D'))
        setRef({ref: actualUrl});
      }
    },
    [
      setRef,
      props.history.location.search,
    ]
  );
// console.log(registerRef);
  return (
    <ConnectedRouter history={props.history}>
      <div>
        {/*<Switch>*/}
          {/*<Route path={URI.SPLASH} exact component={CustomerRegisterPage}/>*/}
          {/*<Route path={URI.HOME} exact component={CustomerRegisterPage}/>*/}
          {/*<Route path={URI.WORLD_GATE} component={WorldGatePage}/>*/}
          {/*<Route path={URI.CUSTOMER_REGISTER} component={CustomerRegisterPage}/>*/}
          {/*<Route path={URI.CUSTOMER_ACTIVATE} component={CustomerActivationPage}/>*/}
          {/*<Route path={URI.MERCHANT_ENQUIRY_FORM} component={MerchantEnquiryFormPage}/>*/}
        {/*</Switch>*/}
        <Switch>
          <Route path={getPagePath(pages.SPLASH)} exact component={CustomerRegisterPage}/>
          <Route path={getPagePath(pages.HOME)} exact component={HomePage}/>
          <Route path={getPagePath(pages.WORLD_GATE)} component={WorldGatePage}/>
          <Route path={getPagePath(pages.CUSTOMER_REGISTER)} component={CustomerRegisterPage}/>
          <Route path={getPagePath(pages.CUSTOMER_ACTIVATE)} component={CustomerActivationPage}/>
          <Route path={getPagePath(pages.MERCHANT_ENQUIRY_FORM)} component={MerchantEnquiryFormPage}/>
          <Route path={getPagePath(pages.COFFEE_ZERO_VOUCHER)} component={CoffeeZeroVoucherPage}/>
          <Route path={getPagePath(pages.CAMPAIGN_NEW_YEAR_2021)} component={CampaignNewYear2021Page}/>
          <Route path={getPagePath(pages.EMAIL_UNSUBSCRIBE)} component={EmailUnsubscribePage}/>
          {/*<Route path={getPagePath(pages.LOGIN)} component={LoginPage}/>*/}
        </Switch>
      </div>
    </ConnectedRouter>
  );
}

Routes.propTypes = {
  history: TObject.isRequired,
};

function mapStateToProps(state) {
  return {
    registerRef: state.registerRef,
  }
}

const mapDispatchToProps = {
  setRef: registerRef.set,
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
