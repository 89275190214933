import {useState} from 'react';

export default function useFormData(initialData = {}) {
  const [data, setData] = useState(initialData);
  function formDataOnChange(field) {
    return function updateField(event) {
      setData({...data, [field]: event.target.value});
    }
  }
  return [data, formDataOnChange, setData];
}