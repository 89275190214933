import React from 'react';
import {useTheme} from '@material-ui/styles';
import {useSelector} from 'react-redux';
import Drawer from './Drawer';
import {URI} from 'page/Layout/uri';
import MenuItem from './MenuItem';
import {
  HowToReg as HowToRegIcon,
  Home as HomeIcon,
  ContactMail as ContactMailIcon,
  AccountCircle as AccountCircleIcon,
  ExitToApp as ExitToAppIcon,
} from '@material-ui/icons';
import Divider from '@material-ui/core/Divider';
import AccountBlock from './AccountBlock';

export const ACCOUNT_MENU_ITEM_ID = {
  REGISTER: 1,
  MERCHANT_ENQUIRY: 2,
  HOME: 3,
  // LOGIN: 4,
};

function AccountSideMenu({
  activeItem,
}) {
  const theme = useTheme();
  // const userSession = useSelector(state => state.userSession);
  const highlightColor = theme.design.default.dark;
  const defaultColor = '#ffffff';

  return (
    <Drawer
      themeColorDark={highlightColor}
      themeColorLight={theme.design.default.light}
      renderTop={(redirectToPage) => {
        return (
          <AccountBlock onClick={redirectToPage}/>
        );
      }}
      renderMenuList={(onMenuItemClick) => {
        return (
          <div>
            <MenuItem
              id={ACCOUNT_MENU_ITEM_ID.HOME}
              onClick={onMenuItemClick(URI.HOME)}
              label={'Home'}
              highlight={(activeItem === ACCOUNT_MENU_ITEM_ID.HOME)}
              highlightColor={highlightColor}
              color={defaultColor}
              Icon={(<HomeIcon style={{color: activeItem === ACCOUNT_MENU_ITEM_ID.HOME ? highlightColor : defaultColor}}/>)}
            />
            <MenuItem
              id={ACCOUNT_MENU_ITEM_ID.REGISTER}
              onClick={onMenuItemClick(URI.CUSTOMER_REGISTER)}
              label={'Register as a customer'}
              highlight={(activeItem === ACCOUNT_MENU_ITEM_ID.REGISTER)}
              highlightColor={highlightColor}
              color={defaultColor}
              Icon={(<HowToRegIcon style={{color: activeItem === ACCOUNT_MENU_ITEM_ID.REGISTER ? highlightColor : defaultColor}}/>)}
            />
            <MenuItem
              id={ACCOUNT_MENU_ITEM_ID.MERCHANT_ENQUIRY}
              onClick={onMenuItemClick(URI.MERCHANT_ENQUIRY_FORM)}
              label={'Join as a merchant'}
              highlight={(activeItem === ACCOUNT_MENU_ITEM_ID.MERCHANT_ENQUIRY)}
              highlightColor={highlightColor}
              color={defaultColor}
              Icon={(<ContactMailIcon style={{color: activeItem === ACCOUNT_MENU_ITEM_ID.MERCHANT_ENQUIRY ? highlightColor : defaultColor}}/>)}
            />
            <Divider style={{marginLeft: 24, marginRight: 24, marginTop: 48, marginBottom: 16, backgroundColor: 'transparent'}}/>
            {/*{userSession.isLoggedIn*/}
              {/*? (*/}
                {/*<MenuItem*/}
                  {/*// id={ACCOUNT_MENU_ITEM_ID.MERCHANT_ENQUIRY}*/}
                  {/*// onClick={onMenuItemClick(URI.MERCHANT_ENQUIRY_FORM)}*/}
                  {/*label={'Sign Out'}*/}
                  {/*// highlight={(activeItem === ACCOUNT_MENU_ITEM_ID.MERCHANT_ENQUIRY)}*/}
                  {/*highlightColor={highlightColor}*/}
                  {/*color={defaultColor}*/}
                  {/*Icon={(<ExitToAppIcon style={{color: activeItem === ACCOUNT_MENU_ITEM_ID.MERCHANT_ENQUIRY ? highlightColor : defaultColor}}/>)}*/}
                {/*/>*/}
              {/*)*/}
              {/*: (*/}
                {/*<MenuItem*/}
                  {/*id={ACCOUNT_MENU_ITEM_ID.LOGIN}*/}
                  {/*onClick={onMenuItemClick(URI.LOGIN)}*/}
                  {/*label={'Sign In'}*/}
                  {/*highlight={(activeItem === ACCOUNT_MENU_ITEM_ID.LOGIN)}*/}
                  {/*highlightColor={highlightColor}*/}
                  {/*color={defaultColor}*/}
                  {/*Icon={(<AccountCircleIcon style={{color: activeItem === ACCOUNT_MENU_ITEM_ID.LOGIN ? highlightColor : defaultColor}}/>)}*/}
                {/*/>*/}
              {/*)*/}
            {/*}*/}
          </div>
        )
      }}
    />
  );
}

AccountSideMenu.propTypes = {
};

export default AccountSideMenu;
