import React from 'react';
import {makeStyles} from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import PlainCard from 'component/Card/PlainCard';
import Image from 'component/Image';

const useStyles = makeStyles({
  title: {
    paddingTop: 10,
    lineHeight: 1.2,
  },
  text: {
    margin: 8,
    textAlign: 'left',
    marginTop: 3,
    marginBottom: 3,
  },
  lr: {
    marginLeft: 8,
    marginRight: 8,
    marginTop: 3,
    marginBottom: 3,
  },
});

function TermsAndConditions({
    enableTransition = true,
}) {
  const classes = useStyles();

  return (
    <PlainCard enableTransition={enableTransition} style={{paddingLeft: 8, paddingRight: 8, paddingTop: 16,paddingBottom: 16, textAlign: 'justify'}}>
      <Typography variant='subtitle1' className={classes.title}>1. Welcome</Typography>
      <Typography variant='body2' className={classes.text}>Welcome to Tanggram Pty Ltd (hereinafter referred to as “Tanggram”, “We” or “Us” or “Our”) (ABN:18 625 016 480). Please take a few minutes to read the following terms and conditions to understand Your legal rights and obligations of use. These terms of Service (“Terms”) constitute a binding agreement between the User ("You", "Your" or the "User") and Tanggram. The Terms govern the use of the Services provided by Us through Our Website (“Website”) or mobile phone App (“App”) (collectively the “Service”). “T-Points” means the cryptographic reward tokens that can be used to redeem “Vouchers” offered by Tanggram.  “Merchant” means a merchant listed on Tanggram that provide “shopping rewards“ to Tanggram registered users.</Typography>
      <Typography variant='body2' className={classes.text}>By browsing, accessing or using the Service, You acknowledge that You have read and understood the Terms, accept these Terms, and agree to be bound by them. If You don’t agree with or cannot comply with the Terms, then please stop using the Tanggram Services.</Typography>
      <Typography variant='body2' className={classes.text}>Tanggram reserves the right to change these Terms from time to time, effective upon the posting of the modified terms, and Tanggram will make every effort to communicate these changes to You via email or notification via Tanggram Website (www.tanggram.com) and Tanggram App. It is likely the Terms of use will change over time. By using the Services, You agree the most current version of The terms (they may change over time).</Typography>
      <Typography variant='subtitle1' className={classes.title}>2. Description of the Service</Typography>
      <Typography variant='body2' className={classes.text}>Tanggram is a Fintech platform that enables Users to get cash rewards (hereinafter referred to as “Shopping Rewards”) from Merchants for shopping through Tanggram App. The accumulated Shopping Rewards can be withdrawn, and/or invested into financial products listed on Tanggram. Tanggram does not take into account Users’ particular financial situation. Only general advice will be provided. Users may earn T-Points for further rewards when they refer to friendS or meet Tanggram’s promotional requirementS.  The T-Cents can then be used to exchange for Merchants Vouchers as offered.</Typography>
      <Typography variant='subtitle1' className={classes.title}>3. General</Typography>
      <Typography variant='body2' className={classes.text}>3.1. Registration</Typography>
      <Typography variant='body2' className={classes.text}>3.1.1. You confirm that You are either more than 18 years of age, or an emancipated minor, or possess legal parental or guardian consent, and are fully able and competent to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in these Terms, and to abide by and comply with these Terms.</Typography>
      <Typography variant='body2' className={classes.text}>3.1.2. Tanggram reserves the right to discontinue or cancel Your registration, at Our sole discretion and without notice, including but not limited to the following reasons: (a) breach of any applicable law or breach of any of the Terms; (b) We deduce that Your conduct damage on Our name or reputation; or(c) We deduce that Your conduct violates Our or another party’s rights.</Typography>
      <Typography variant='body2' className={classes.text}>3.2. Usernames and Passwords</Typography>
      <Typography variant='body2' className={classes.text}>3.2.1. You agree and knowledge that You are responsible to maintain the security and confidentiality of Your password, and Your Login email address, which allows You to access the Service. That Login email address and password, together with any other information You provide at signup quoted as Your “Registration Information”. You must keep Your “Registration Information” secure at all times. Do not:</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; sharing Your “Registration Information” with anyone.</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; using Your previous password or any password that is guessable easily.</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; exposure the device You use to login to the Services in a place that can be easily accessed by others.</Typography>
      <Typography variant='body2' className={classes.text}>3.2.2. We assume all actions and activities from Your side with Your “registration information” was made by You or authorised by You. You are solely responsible for any activity that occurs on Your account. Please notify Tanggram via customer@tanggram.com immediately if You suspect there is any unauthorised use of Your account by any other internet user or any other breach of security.</Typography>
      <Typography variant='body2' className={classes.text}>3.3. Access of the Service</Typography>
      <Typography variant='body2' className={classes.text}>3.3.1. You must access and use Tanggram Website and App for lawful purposes and in accordance with these Terms and Conditions.</Typography>
      <Typography variant='body2' className={classes.text}>3.3.2. Your right to access and use Tanggram is personal to You and is not transferable by You to any other person or entity.</Typography>
      <Typography variant='body2' className={classes.text}>3.3.3. While We endeavour to ensure the Service are available twenty four (24) hours a day, We are not under any obligation to do so, and We will not be liable to You if the Services are unavailable at any time or for any period. We will notify You with the Service suspension period for repairs, maintenance or the introduction of new facilities or services, but Your access to the Services may also be occasionally suspended or restricted at any time without notice to You.</Typography>
      <Typography variant='body2' className={classes.text}>3.4. Information collected</Typography>
      <Typography variant='body2' className={classes.text}>3.4.1. Accurate records enable Tanggram to provide the highest level of Service to You. You must provide true, accurate, current and complete information about Your accounts. In order for the Service to function effectively, You must also keep Your Registration Information up to date and accurate. If You do not do this, the accuracy and effectiveness of the Service to You will be affected.</Typography>
      <Typography variant='body2' className={classes.text}>3.4.2 By providing Us with Your email address, You agree to receive all required notices electronically to that email address. Notices will be provided in HTML (or, if Your system does not support HTML, in plain-text) in the text of the email or in the appropriate page on Our App.</Typography>
      <Typography variant='subtitle1' className={classes.title}>4. Third Party links, offers and activities</Typography>
      <Typography variant='body2' className={classes.text}>4.1. All third party products and/or services including names, services, company names and logos mentioned on the Tanggram are affiliated with their respective owners, which are in no way associated or affiliated with Tanggram. These information, by trade name, trademark, service provider, supplier or otherwise does not constitute or imply endorsement, sponsorship or recommendation thereof by Tanggram.</Typography>
      <Typography variant='subtitle1' className={classes.title}>5. Intellectual Property Right</Typography>
      <Typography variant='body2' className={classes.text}>5.1. All intellectual property in relation to the Website, the App and the content and material on the Website or App (including the software, design, text, data, icons, logos, copyrights, trademarks, concepts sound recordings and graphics comprised in the Website) (“Intellectual Property”) belongs to Tanggram, respective owners, advertisers or affiliates.</Typography>
      <Typography variant='body2' className={classes.text}>5.2. Tanggram retains all right, title, and interest in and to the Website/App and all related content and Intellectual Property, and nothing You do on or in relation to the Website/App, or any of the related content will transfer any rights, in intellectual property or otherwise, to You, or license to You any such rights unless expressly stated otherwise.</Typography>
      <Typography variant='body2' className={classes.text}>5.3. You agree not to do anything that interferes with or breaches the intellectual property rights in the content. You agree not to copy, modify, create a derivative work, reverse engineer, reverse assemble, attempt to discover the source code, sell, assign, sub-license, grant a security interest in or otherwise transfer any content on the Website and App.</Typography>
      <Typography variant='body2' className={classes.text}>5.4. You may download and view content or print a copy of the material on the Website and App for personal, non-commercial Use, provided that You do not modify the content in any way (including any copyright notice).</Typography>
      <Typography variant='body2' className={classes.text}>5.5. The contents of Tanggram belong or are licensed to Evertang or its software or content suppliers. Tanggram grants You the right to view and Use Tanggram subject to these terms. You may download or print a copy of the information provided on Tanggram for Your personal, internal and non-commercial Use only. Any distribution, reprint or electronic reproduction of any content from Tanggram in whole or in part for any other purpose is expressly prohibited without Our prior written consent.</Typography>
      <Typography variant='subtitle1' className={classes.title}>6. Access to the Interface</Typography>
      <Typography variant='body2' className={classes.text}>6.1. You agree that You will not:</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; Use any tools, program, algorithm or methodology to access, acquire, copy or monitor Tanggram or any portion of Tanggram, without Tanggram express written agreed, which may be withheld in Tanggram’s sole discretion;</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; Violate or attempt to violate the computer systems which support the Website or App. You must not hack into the Website, App, Tanggram's computer systems or the computer systems of other Users’ Website or App, which includes but not limits  unauthorised access, malicious damage and/or interference and includes, without limitation, spamming, propagating viruses, worms or other types of malicious programs, deliberate attempts to overload a computer system, broadcast attacks or any other method designed to damage or interfere with the operation of a computer system or Website/App.</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; Collect, store or upload personal information of other individuals at the Tanggram;</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; Impersonate or falsely represent Your association with any individual or entity;</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; Post, communicate or transmit or Use any material of any kind for commercial purposes, or which contains any promotional material or advertising, or any other forms of unsanctioned solicitation, including without limitation junk mail, spam, chain letters, or unsolicited mass distribution of email;</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; Delete, circumvent or alter any author attribution, legal notices, rights management information or technological protection measures;</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; Attempt to decipher, decompile, disassemble, or reverse-engineer any of the software comprising or in any way making up a part of Tanggram or the Service. Furthermore, Tanggram does not warrant that the functions contained in the Website and Service, such as hyperlinks, will be uninterrupted or error free, that defects will be corrected or that Tanggram or Our hosting server, are free of viruses or bugs.</Typography>
      <Typography variant='subtitle1' className={classes.title}>7. Limitation of Liability</Typography>
      <Typography variant='body2' className={classes.text}>7.1. To the fullest extent permitted by law, in no event shall Tanggram, its officers, directors, employees, or agents, be liable to You for any direct, indirect, incidental, special, punitive, losses or expenses or consequential damages whatever resulting from any</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; errors, mistakes, or inaccuracies of content,</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; personal injury or property damage, of any nature whatever, resulting from Your access to and Use of Our services,</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; any unauthorised access to or Use of Our secure servers and/or any and all personal information and/or financial information stored therein,</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; any interruption or cessation of transmission to or from Our services,</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; any bugs, viruses, hack attacks, or the like, which may be transmitted to or through Our services by any third party, and/or</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; any errors or omissions in any content or for any loss or damage of any kind incurred as a result of Your Use of any content posted, emailed, transmitted, or otherwise made available via the services, whether based on warranty, contract, tort, or any other legal theory, and whether or not the company is advised of the possibility of such damages.</Typography>
      <Typography variant='body2' className={classes.text}>7.2.  You specifically acknowledge that Tanggram shall not be liable for the content or the defamatory, offensive, or illegal conduct of any third party and that the risk of harm or damage from the foregoing rests entirely with You.</Typography>
      <Typography variant='subtitle1' className={classes.title}>8. Warranties and Disclaimer</Typography>
      <Typography variant='body2' className={classes.text}>8.1. We understand that, in some jurisdictions, warranties, disclaimers and conditions may apply that cannot be legally excluded. If that is true in Your jurisdiction, then to the extent permitted by law, Tanggram limits its liability for any claims under those warranties or conditions to either supplying You the services again (or the cost of supplying You the services again).</Typography>
      <Typography variant='body2' className={classes.text}>8.2. The Service is controlled and offered by Tanggram from its facilities and makes no representations that the Service is appropriate or available for in any locations. Those who access or use the Service from other jurisdictions do so at their own volition and are responsible for compliance with local law.</Typography>
      <Typography variant='body2' className={classes.text}>8.3. You use the recommendations within Tanggram at Your own risk and should consult Your financial advisor prior to entering into any products recommended. Tanggram does not provide personal financial advice.</Typography>
      <Typography variant='body2' className={classes.text}>8.4. Merchant Promotions and Rewards</Typography>
      <Typography variant='body2' className={classes.text}>8.4.1. Prices are current at the time of display but are subject to change and unless otherwise specified, all prices that appear throughout our Website or the App are in Australian dollars (AUD) and are inclusive of GST where applicable.</Typography>
      <Typography variant='body2' className={classes.text}>8.4.2. The content appearing in the App relating to the Merchant’s products and/or services including photographs, business description, offer details, terms and conditions of the Rewards and contact details are generally made available to us by the Merchants. Whilst we make all reasonable efforts to correct any errors or omissions as soon as practicable after being notified of their existence. We cannot guarantee that the Service will be free of faults and we do not accept liability for any errors or omissions. In the event of an error or fault, you should report it by email to customer@tanggram.com.au where there is inconsistency between the content on the Website and the App, the content on the App always prevails.</Typography>
      <Typography variant='body2' className={classes.text}>8.4.3. The Merchant may specify their own terms and conditions that apply to their Rewards. In addition, we also impose the Standard Tanggram Conditions to all of the promotions available through the Service. You must always check the conditions before deciding to redeem the Rewards. The Merchants have the discretion to refuse to honour the Rewards if you have not complied with the conditions.</Typography>
      <Typography variant='body2' className={classes.text}>8.4.4. When You redeem the Rewards, the goods and services are provided to You directly by the Merchant and not by Us. The Merchants are solely responsible for complying with all applicable laws. To the extent permitted by law (including the Australian Consumer Law), We make no warranty or representation regarding the standard of any goods or services to be supplied by the Merchants and We are not responsible for the actions or inactions of the Merchants. The Service also contains links to external websites (i.e. Merchant’s websites, or their social media pages) which are not under our control. We do not accept any responsibility for the content presented on such websites or any damage that you may sustain as a result of visiting them. By linking to such sites we are in no way suggesting an endorsement of or relationship with that organisation.</Typography>
      <Typography variant='body2' className={classes.text}>8.4.5. Despite Our best endeavours, the details of the Merchants and/or the Rewards may change from time to time and often due to reasons beyond our control (for example, a Merchant has gone out of business or a Promotion is ceased due to the changes in their business operation). And Tanggram is not responsible for these information changes.</Typography>
      <Typography variant='subtitle1' className={classes.title}>9. Termination</Typography>
      <Typography variant='body2' className={classes.text}>9.1. We may terminate Your account in our sole and absolute discretion without notice to You, including if:</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; We suspect you have reached these Terms or any applicable law;</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; We reasonably suspect you have committed fraud;</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; We believe there has been a security breach or unauthorised use of Your account.</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; required by applicable laws (such as anti-money laundering and counter terrorism financing laws, or sanctions laws); or</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; We determine that Your conduct impacts on Our name or reputation or violates Our rights or those of another party;</Typography>
      <Typography variant='body2' className={classes.text}>9.2. Following termination of Your Account, You may forfeit all entitlements and benefits associated with Your Account including any unclaimed Shopping Rewards and T-Points remaining in Your account, We may contact You by email for further investigation, however, We will have no further obligations or liabilities to You. Upon termination, all of Your user content will remain and may be dealt with by Us as We think fit.</Typography>
      <Typography variant='subtitle1' className={classes.title}>10. Purchase of Goods and Services</Typography>
      <Typography variant='body2' className={classes.text}>You purchase the goods and services from the Merchants only, and all the payment is made directly between You and Merchants. If You believe You have been overcharged or charged incorrectly You may review your transaction with the Merchants directly.</Typography>
      <Typography variant='body2' className={classes.text}>We displays Merchants’ information for promoting Merchants’ business, and reallocate  the Shopping Rewards from Merchants to You as long as You collect the Rewards using Tanggram App. You acknowledge that the Merchant is solely responsible for the provision of any goods and services.</Typography>
      <Typography variant='subtitle1' className={classes.title}>11. Shopping Rewards Withdraw</Typography>
      <Typography variant='body2' className={classes.text}>By collecting and accumulating the Shopping Rewards, You can withdraw the Rewards once it reaches $200 as a lump sum, or invest it into Tanggram Fund. To ensure a smooth and accurate withdraw and/or investment, We require You to provide at least one valid Government ID and at least one valid payment method. A once off withdraw fee of $5 applies to the first time withdraw.</Typography>
      <Typography variant='subtitle1' className={classes.title}>12. Using of T-Points</Typography>
      <Typography variant='body2' className={classes.text}>You can earn the T-Points in “Socializing” function and through Tanggram future promotions subject to availability.</Typography>
      <Typography variant='body2' className={classes.text}>The T-Points can be used to exchange for Tanggram Vouchers offered in Tanggram App. The T-Cents could not be redeemed for cash, bartered or sold.</Typography>
      <Typography variant='subtitle1' className={classes.title}>13. Privacy Policy</Typography>
      <Typography variant='body2' className={classes.text}>We undertake the obligation of Tanggram's Privacy Policy</Typography>
      <Typography variant='body2' className={classes.text}>You agree to provide accurate and complete information when You register with Tanggram, and You agree to update such information to keep it accurate and complete. You acknowledge that Tanggram may store, collect and use Your information in accordance with our Privacy Policy.</Typography>
      <Typography variant='subtitle1' className={classes.title}>14. Jurisdiction and Agreement</Typography>
      <Typography variant='body2' className={classes.text}>These Terms are governed by the laws in force in the State of Victoria, Australia and You irrevocably agree to submit to the exclusive jurisdiction of the courts of Victoria, Australia in respect of any dispute arising from these Terms of Service.</Typography>
      <br/>
      <Image path={'/asset/img/splash_logo.png'} width={144} aspectRatio={0.3} style={{alignSelf: 'center', marginBottom: 16}}/>
    </PlainCard>
  );
}

TermsAndConditions.propTypes = {
};

export default TermsAndConditions;
