import React from 'react';
import {declareObject, TNumber} from 'lib/Core/prop_types';

const Standard = ({scale, viewBox, children, forwardRef}) => {
  const _scale = scale ? scale : 1;
  const {minX, minY, width, height} = viewBox;
  const _minX = minX ? minX : 0;
  const _minY = minY ? minY : 0;
  const _width = (width * _scale).toFixed(2);
  const _height = (height * _scale).toFixed(2);
  return (
    <svg ref={forwardRef} height={`${_height}px`} width={`${_width}px`} viewBox={`${_minX} ${_minY} ${width} ${height}`}>
      {children}
    </svg>
  );
};

const TViewBoxProp = declareObject({
  minX: TNumber,
  minY: TNumber,
  width: TNumber.isRequired,
  height: TNumber.isRequired,
});

Standard.propTypes = {
  scale: TNumber.isRequired,
  viewBox: TViewBoxProp.isRequired
};

export default React.forwardRef((props, ref) => (
  <Standard {...props} forwardRef={ref}/>
));
