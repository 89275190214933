import React from 'react';
import Typography from '@material-ui/core/Typography';
import {Body} from 'component/NavLayout';
import NavLayout from 'component/NavLayout';
import MerchantEnquiryForm from './MerchantEnquiryForm';
import {AccountSideMenu, ACCOUNT_MENU_ITEM_ID} from 'component/SideMenu';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: 44,
    marginBottom: 10,
    textAlign: 'center',
    width: '100%',
    color: '#FFF',
  }
}));

function MerchantEnquiryFormPage() {
  const classes = useStyles();

  return (
    <NavLayout
      Title={(<Typography className={classes.title} variant={'h5'}>Merchant Enquiry</Typography>)}
      SideMenu={(<AccountSideMenu activeItem={ACCOUNT_MENU_ITEM_ID.MERCHANT_ENQUIRY}/>)}
    >
      <Body>
        <MerchantEnquiryForm/>
      </Body>
    </NavLayout>
  );
}

MerchantEnquiryFormPage.propTypes = {
};

export default MerchantEnquiryFormPage;
