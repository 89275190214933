import {put, takeLeading} from 'redux-saga/effects';
import createState from './state';
import callRegisterCustomer from '../../service/api/method/register_customer';
import {dataStatus, transformApiData} from '../transform';
import {
  getApiResponse,
  handleError,
  showLoading,
  hideLoading,
} from '../actions';
import TDataStatus from '../data_status';
import {TArray, TString, declareObject} from 'lib/Core/prop_types';
import {TUser} from './user_session';

export const TCustomerRegistration = declareObject({
  status: TDataStatus.isRequired,
  user: TUser,
  randomPassword: TString,
  errors: TArray,
});

export default createState(
  'customerRegistration',
  {
    status: dataStatus.INIT,
    user: null,
    randomPassword: null,
    errors: [],
  },
  ({addSaga, reduce, reduceError, reduceReset}) => {
    addSaga(function* () {
      yield takeLeading('REGISTER_CUSTOMER', function* (action) {
        try {
          yield put(showLoading());
          const apiData = yield callRegisterCustomer(action.postData);
          yield put(getApiResponse('REGISTER_CUSTOMER_R', apiData));
          yield put(hideLoading());
        } catch (e) {
          yield put(handleError('REGISTER_CUSTOMER_F', e));
          yield put(hideLoading());
        }
      });
    });

    reduce('REGISTER_CUSTOMER_R', function (state, action) {
      let data = Object.assign({}, state);
      const apiData = transformApiData(action.data);
      data.status = apiData.status;
      if (data.status === dataStatus.FAILURE) {
        data.errors = apiData.errors;
      } else if (data.status === dataStatus.SUCCESS) {
        data.user = apiData.data.user;
        data.randomPassword = apiData.data.randomPassword;
      }
      return data;
    });
    reduceError('REGISTER_CUSTOMER_F');
    reduceReset('REGISTER_CUSTOMER_RESET');
  }
);

export const customerRegistration = {
  register: (postData) => ({type: 'REGISTER_CUSTOMER', postData}),
  reset: () => ({type: 'REGISTER_CUSTOMER_RESET'}),
};
