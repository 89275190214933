import category from '../const/category';

const categoryValues = [
  category.FOOD,
  category.DRINK,
  category.BEAUTY,
  category.GROCERY,
  category.FASHION,
  category.ENTERTAINMENT,
  category.EDUCATION,
  category.AGENT,
  category.OTHER,
];

// put them in the order as display
const categoryOptions = [
  {
    label: 'Food',
    value: category.FOOD,
  },
  {
    label: 'Drink',
    value: category.DRINK,
  },
  {
    label: 'Beauty',
    value: category.BEAUTY,
  },
  {
    label: 'Grocery',
    value: category.GROCERY,
  },
  {
    label: 'Fashion',
    value: category.FASHION,
  },
  {
    label: 'Entertainment',
    value: category.ENTERTAINMENT,
  },
  {
    label: 'Education',
    value: category.EDUCATION,
  },
  {
    label: 'Agent',
    value: category.AGENT,
  },
  {
    label: 'Others',
    value: category.OTHER,
  },
];

export {
  categoryValues,
  categoryOptions,
}