import React from 'react';
import {makeStyles, useTheme} from '@material-ui/styles';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import {TNumber} from 'lib/Core/prop_types';
import transitions from 'logic/const/transition';
import MessageBar from './index';
import {smallIcon} from '../Icon';
import WarningIcon from 'lib/Icon/Warning';

const useStyles = makeStyles(theme => {
  return {
    text: {
      color: theme.status.danger,
      marginLeft: 12,
    },
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    al: {
      // paddingTop: 4,
      width: 24,
      height: 24,
    }
  };
});

function ErrorMessageBar({
  className = null,
  children,
  style = {},
  textStyle = {},
  direction = transitions.SLIDE_TO_RIGHT,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const _style = Object.assign({}, {backgroundColor: `${theme.status.danger}33`}, style);

  function renderMessage() {
    return (
      <div className={className}>
        <MessageBar style={_style}>
          <div className={classes.root}>
            <div className={classes.al}>
              {smallIcon(WarningIcon, theme.status.danger)}
            </div>
            <Typography variant='body2' className={classes.text} style={textStyle}>{children}</Typography>
          </div>
        </MessageBar>
      </div>
    );
  }

  if (direction === transitions.SLIDE_TO_RIGHT) {
    return (
      <Slide direction='right' in={true} timeout={{enter: 300,exit: 300}}>
        {renderMessage()}
      </Slide>
    );
  } else {
    return (
      <Slide direction='left' in={true} timeout={{enter: 300,exit: 300}}>
        {renderMessage()}
      </Slide>
    );
  }
}

ErrorMessageBar.propTypes = {
  direction: TNumber,
};

export default ErrorMessageBar;
