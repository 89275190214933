import {put, takeLeading} from 'redux-saga/effects';
import createState from './state';
import loadEmailUnsubscribeOptionsByRef from 'logic/service/api/method/load_email_unsubscribe_options_by_ref';
import {dataStatus, transformApiData} from '../transform';
import {
  getApiResponse,
  handleError,
  showLoading,
  hideLoading,
} from '../actions';
import TDataStatus from '../data_status';
import {TArray, declareObject, TObject, TString} from 'lib/Core/prop_types';

export const TEmailUnsubscribeLoading = declareObject({
  status: TDataStatus.isRequired,
  ref: TString,
  options: TObject,
  errors: TArray,
});

export const emailUnsubscribeLoading = 'emailUnsubscribe.1';

export default createState(
  'emailUnsubscribe',
  {
    status: dataStatus.INIT,
    ref: null,
    options: [],
    errors: [],
  },
  ({addSaga, reduce, reduceError, reduceReset}) => {
    addSaga(function* () {
      yield  takeLeading('EMAIL_UNSUBSCRIBE_LOAD_OPTIONS', function* (action) {
        try {
          yield put(showLoading(emailUnsubscribeLoading));
          const apiData = yield loadEmailUnsubscribeOptionsByRef(action.ref);
          yield put({...getApiResponse('EMAIL_UNSUBSCRIBE_LOAD_OPTIONS_R', apiData), ref: action.ref});
          yield put(hideLoading(emailUnsubscribeLoading));
        } catch (e) {
          yield put(handleError('EMAIL_UNSUBSCRIBE_LOAD_OPTIONS_F', e));
          yield put(hideLoading(emailUnsubscribeLoading));
        }
      });
    });

    reduce('EMAIL_UNSUBSCRIBE_LOAD_OPTIONS_R', function (state, action) {
      let data = {...state};
      const apiData = transformApiData(action.data);
      data.status = apiData.status;
      if (data.status === dataStatus.FAILURE) {
        data.errors = apiData.errors;
      } else if (data.status === dataStatus.SUCCESS) {
        data.ref = action.ref;
        data.options = apiData.data;
        data.errors = [];
      }
      return data;
    });
    reduceError('EMAIL_UNSUBSCRIBE_LOAD_OPTIONS_F');
    reduceReset('EMAIL_UNSUBSCRIBE_RESET');
  }
);

export const actions = {
  loadOptions: (ref) => ({type: 'EMAIL_UNSUBSCRIBE_LOAD_OPTIONS', ref,}),
  reset: () => ({type: 'EMAIL_UNSUBSCRIBE_RESET'}),
};
