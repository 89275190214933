import {connectionError} from './state/connection_error';
import {customerActivation} from './state/customer_activation';
import {customerRegistration} from './state/customer_registration';
import {customerResendCode} from './state/customer_resend_code';
import {loadingTransition} from './state/loading_transition';
import {userLogin} from './state/user_login';
import {userSession} from './state/user_session';
import {topDock} from './state/top_dock';
import {maintenanceMode} from './state/maintenance_mode';
import {customerSendSmsCode} from './state/customer_send_sms_code';
import {customerMobileVerification} from './state/customer_mobile_verification';
import {userSessionTokenPublicData} from './state/user_session_token_public_data';
import {userSessionExchangePassword} from './state/user_session_exchange_password';
import {customerReferViaWeb} from './state/customer_register_via_web';
import {customerNotificationDetail} from './state/customer_notification_detail';
import {customerNotification, customerNotificationDrawer} from './state/customer_notification';
import {customerNotificationStatus} from './state/customer_notification_status';
import {merchantEnquiry} from './state/merchant_enquiry';
import dataStatus from './data_status';
import createException, {exceptionKeys} from './exception';
import {registerRef} from './state/register_ref';

const passData = (type, data) => ({...{type}, ...data});
const getApiResponse = (type, data) => {
  if (data.state === dataStatus.MAINTENANCE) {
    throw createException(exceptionKeys.MAINTENANCE, data.data);
  }
  return ({type, data});
};
const handleError = (type, e) => {
  return ({type, e});
};
const showLoading = (componentId) => ({type: 'LOADING_TRANSITION_ON', componentId});
const hideLoading = (componentId) => ({type: 'LOADING_TRANSITION_OFF', componentId});

export {
  passData,
  getApiResponse,
  handleError,
  showLoading,
  hideLoading,
  connectionError,
  customerActivation,
  customerRegistration,
  customerResendCode,
  loadingTransition,
  userLogin,
  userSession,
  topDock,
  maintenanceMode,
  customerSendSmsCode,
  customerMobileVerification,
  userSessionTokenPublicData,
  userSessionExchangePassword,
  customerReferViaWeb,
  customerNotificationDetail,
  customerNotification,
  customerNotificationDrawer,
  customerNotificationStatus,
  merchantEnquiry,
  registerRef,
}
