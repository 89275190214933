import {put} from 'redux-saga/effects';
import createState from './state';
import callLoadCustomerNotificationStatus from '../../service/api/method/load_customer_notification_status';
import {dataStatus, transformApiData} from '../transform';
import {
  getApiResponse,
  handleError,
} from '../actions';
import TDataStatus from '../data_status';
import {TArray, declareObject, TNumber, TString, TObject} from 'lib/Core/prop_types';
import {takeOneExclusive} from '../helper';
import notificationStatusType from '../const/customer_notification_status';

export const TCustomerNotificationStatus = declareObject({
  status: TDataStatus.isRequired,
  notificationStatus: declareObject({
    id: TNumber,
    serviceUser: TObject,
    status: TNumber,
    updatedAt: TString,
  }),
  errors: TArray,
});

export const customerNotificationStatusLoading = 'customerNotificationStatusLoading.1';

export default createState(
  'customerNotificationStatus',
  {
    status: dataStatus.INIT,
    notificationStatus: {
      id: null,
      serviceUser: null,
      status: notificationStatusType.STATUS_READ,
      updatedAt: '',
    },
    errors: [],
  },
  ({addSaga, reduce, reduceError, reduceReset}) => {
    addSaga(function* () {
      yield takeOneExclusive(2000, 'LOAD_CUSTOMER_NOTIFICATION_STATUS', function* (action) {
        try {
          const apiData = yield callLoadCustomerNotificationStatus();
          yield put(getApiResponse('LOAD_CUSTOMER_NOTIFICATION_STATUS_R', apiData));
        } catch (e) {
          yield put(handleError('LOAD_CUSTOMER_NOTIFICATION_STATUS_F', e));
        }
      });
    });

    reduce('LOAD_CUSTOMER_NOTIFICATION_STATUS_R', function (state, action) {
      let data = Object.assign({}, state);
      const apiData = transformApiData(action.data);
      data.status = apiData.status;
      if (data.status === dataStatus.FAILURE) {
        data.errors = apiData.errors;
      } else if (data.status === dataStatus.SUCCESS) {
        data.notificationStatus = apiData.data;
      }
      return data;
    });

    reduceError('LOAD_CUSTOMER_NOTIFICATION_STATUS_F');
    reduceReset('LOAD_CUSTOMER_NOTIFICATION_STATUS_RESET');
  }
);

export const customerNotificationStatus = {
  load: () => ({type: 'LOAD_CUSTOMER_NOTIFICATION_STATUS'}),
  reset: () => ({type: 'LOAD_CUSTOMER_NOTIFICATION_STATUS_RESET'})
};
