import React from 'react';
import FloatingButton from '../FloatingButton';
import {TFunction, TObject} from 'lib/Core/prop_types';

function FloatingButtonDialog({
  children,
  onOpen,
  FloatingButtonProps = {}
}) {
  function openDialog() {
    if (onOpen) {
      onOpen();
    }
  }
  const _FloatingButtonProps = {...{onClick: openDialog}, ...FloatingButtonProps};

  return (
    <div>
      {children}
      <FloatingButton {..._FloatingButtonProps}/>
    </div>
  );
}

FloatingButtonDialog.propTypes = {
  FloatingButtonProps: TObject,
  onOpen: TFunction,
};

export default FloatingButtonDialog;
