import {put, takeLeading} from 'redux-saga/effects';
import createState from './state';
import {dataStatus, transformApiData} from '../transform';
import TDataStatus from '../data_status';
import {
  getApiResponse,
  handleError,
  showLoading,
  hideLoading,
} from '../actions';
import {TArray, declareObject, TObject} from 'lib/Core/prop_types';
import callReadNotification from '../../service/api/method/read_customer_notification';

export const TCustomerNotificationDetail = declareObject({
  status: TDataStatus.isRequired,
  detail: TObject,
  errors: TArray,
});

export default createState(
  'customerNotificationDetail',
  {
    status: dataStatus.INIT,
    detail: {},
    errors: [],
  },
  ({addSaga, reduce, reduceError, reduceReset}) => {
    addSaga(function* () {
      yield takeLeading('LOAD_CUSTOMER_NOTIFICATION_DETAIL', function* (action) {
        try {
          yield put(getApiResponse('LOAD_CUSTOMER_NOTIFICATION_DETAIL_R', action.data));
        } catch (e) {
          yield put(handleError('LOAD_CUSTOMER_NOTIFICATION_DETAIL_F', e));
        }
      });
    });

    addSaga(function* () {
      yield takeLeading('READ_CUSTOMER_NOTIFICATION_DETAIL', function* (action) {
        try {
          yield put(showLoading());
          const apiData = yield callReadNotification(action.postData);
          yield put(getApiResponse('READ_CUSTOMER_NOTIFICATION_DETAIL_R', apiData));
          yield put(hideLoading());
        } catch (e) {
          yield put(handleError('LOAD_CUSTOMER_NOTIFICATION_DETAIL_F', e));
          yield put(hideLoading());
        }
      });
    });

    reduce('LOAD_CUSTOMER_NOTIFICATION_DETAIL_R', function (state, action) {
      let data = Object.assign({}, state);
      data.status = dataStatus.SUCCESS;
      data.detail = action.data;
      return data;
    });

    reduce('READ_CUSTOMER_NOTIFICATION_DETAIL_R', function (state, action) {
      let data = Object.assign({}, state);
      const apiData = transformApiData(action.data);
      data.status = apiData.status;
      if (data.status === dataStatus.FAILURE) {
        data.errors = apiData.errors;
      } else if (data.status === dataStatus.SUCCESS) {
        data.detail = apiData.data;
      }
      return data;
    });

    reduceError('LOAD_CUSTOMER_NOTIFICATION_DETAIL_F');
    reduceReset('LOAD_CUSTOMER_NOTIFICATION_DETAIL_RESET');
  }
);

export const customerNotificationDetail = {
  load: (data) => ({type: 'LOAD_CUSTOMER_NOTIFICATION_DETAIL', data}),
  read: (postData) => ({type: 'READ_CUSTOMER_NOTIFICATION_DETAIL', postData}),
  reset: () => ({type: 'LOAD_CUSTOMER_NOTIFICATION_DETAIL_RESET'})
};
