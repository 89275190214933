import {put, takeLeading} from 'redux-saga/effects';
import createState from './state';
import callSendSmsCodeCustomer from '../../service/api/method/send_sms_code_customer';
import {dataStatus, transformApiData} from '../transform';
import {
  getApiResponse,
  handleError,
  showLoading,
  hideLoading,
} from '../actions';
import TDataStatus from '../data_status';
import {declareObject, TArray} from 'lib/Core/prop_types';

export const TCustomerSendSmsCode = declareObject({
  status: TDataStatus.isRequired,
  errors: TArray,
});

export const customerSendSmsCodeLoading = 'customerSendSmsCode.1';

export default createState(
  'customerSendSmsCode',
  {
    status: dataStatus.INIT,
    errors: [],
  },
  ({addSaga, reduce, reduceError, reduceReset}) => {
    addSaga(function* () {
      yield takeLeading('SEND_ACCOUNT_SMS_ACTIVATION_CODE', function* (action) {
        try {
          yield put(showLoading(customerSendSmsCodeLoading));
          const apiData = yield callSendSmsCodeCustomer(action.postData);
          yield put(getApiResponse('SEND_ACCOUNT_SMS_ACTIVATION_CODE_R', apiData));
          yield put(hideLoading(customerSendSmsCodeLoading));
        } catch (e) {
          yield put(handleError('SEND_ACCOUNT_SMS_ACTIVATION_CODE_F', e));
          yield put(hideLoading(customerSendSmsCodeLoading));
        }
      });
    });

    reduce('SEND_ACCOUNT_SMS_ACTIVATION_CODE_R', function (state, action) {
      let data = Object.assign({}, state);
      const apiData = transformApiData(action.data);
      data.status = apiData.status;
      if (data.status === dataStatus.FAILURE) {
        data.errors = apiData.errors;
      }
      return data;
    });
    reduceError('SEND_ACCOUNT_SMS_ACTIVATION_CODE_F');
    reduceReset('SEND_ACCOUNT_SMS_ACTIVATION_CODE_RESET');
  }
);

export const customerSendSmsCode = {
  send: (postData) => ({type: 'SEND_ACCOUNT_SMS_ACTIVATION_CODE', postData}),
  reset: () => ({type: 'SEND_ACCOUNT_SMS_ACTIVATION_CODE_RESET'}),
};
