import React from 'react';
import {TString, TNumber, TObject} from '../prop_types';
import Typography from '@material-ui/core/Typography';

function ImagePlaceholder({
  width,
  height,
  aspectRatio = 1,
  style = {},
}) {
  const _height = height ? height : Math.round(width * aspectRatio);
  return (
    <div
      style={{...{width, height: _height, display: 'flex', justifyContent: 'center', alignItems: 'center'}, ...style}}
    >
      <Typography>No Image</Typography>
    </div>
  );
}

ImagePlaceholder.propTypes = {
  path: TString,
  style: TObject,
  width: TNumber.isRequired,
  aspectRatio: TNumber,
};

export default ImagePlaceholder;
