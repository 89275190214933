import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/styles';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import withPageControl from 'component/HOC/withPageControl';
import CustomInput from 'component/CustomInput';
import CustomInputLabel from 'component/CustomInputLabel';
import MerchantEnquiryFormConstraints from 'logic/service/constraint/merchantEnquiryForm';
import useFormData from 'logic/hook/useFormData';
import AsyncContainer from 'component/AsyncContainer';
import DefaultBasicCard from 'component/Card/DefaultBasicCard';
import {categoryOptions} from 'logic/dict/category';
import {auStateOptions} from 'logic/dict/au_state';
import CustomSelect from 'component/CustomSelect';
import {merchantEnquiry} from 'logic/redux/state/merchant_enquiry';
import {merchantEnquiryLoading} from 'logic/redux/state/merchant_enquiry';
import ErrorMessageBar from 'component/MessageBar/ErrorMessageBar';
import dataStatus from 'logic/redux/data_status';
import SuccessMessageBar from 'component/MessageBar/SuccessMessageBar';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  card: {
    padding: '18px 16px 10px 16px',
  },
  form: {
    display: 'flex',
    paddingBottom: 15,
  },
  msgBar: {
    marginTop: 8,
    marginRight: 16,
  },
}));

function MerchantEnquiryForm({
  createEnquiry,
  merchantEnquiry,
  resetEnquiry,
}) {
  const classes = useStyles();

  const [formData, setFormData] = useFormData({
    email: '',
    merchantName: '',
    mobile: '',
    storeName: '',
    category: '',
    abn: '',
    address: '',
    suburb: '',
    auState: '',
    postcode: '',
    message: '',
  });

  const [emailError, setEmailError] = useState(false);
  const [merchantNameError, setMerchantNameError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [storeNameError, setStoreNameError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [abnError, setAbnError] = useState(false);

  useEffect(
    () => {
      return () => {
        resetEnquiry();
      }
    },
    [
      resetEnquiry,
    ]
  );

  function handleCheck(inputField) {
    return function (event) {
      setFormData(inputField)(event);
      validateField(inputField, event.target.value);
    }
  }

  function handleSubmit() {
    if (merchantEnquiry.status === dataStatus.SUCCESS) {
      return;
    }

    const isValid = validateFields();
    if (isValid) {
      createEnquiry(formData);
    }
  }

  function validateFields() {
    const data =  {
      email: formData.email,
      merchantName: formData.merchantName,
      mobile: formData.mobile,
      storeName: formData.storeName,
      category: formData.category,
      abn: formData.abn,
    };

    for (const field in data) {
      if (data.hasOwnProperty(field)) {
        if (!validateField(field, data[field])) {
          return false;
        }
      }
    }

    return true;
  }

  function validateField(inputField, value) {
    const result = MerchantEnquiryFormConstraints(inputField, value);
    switch (inputField) {
      case 'email':
        setEmailError(!result.isPassed);
        break;
      case 'merchantName':
        setMerchantNameError(!result.isPassed);
        break;
      case 'mobile':
        setMobileError(!result.isPassed);
        break;
      case 'storeName':
        setStoreNameError(!result.isPassed);
        break;
      case 'abn':
        setAbnError(!result.isPassed);
        break;
      case 'category':
        setCategoryError(!result.isPassed);
        break;
      default:
    }

    return result.isPassed;
  }

  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  }

  return (
    <DefaultBasicCard
      enableBottomRightButton={merchantEnquiry.status !== dataStatus.SUCCESS}
      bottomRightButtonLabel={'Submit'}
      bottomRightButtonOnClick={handleSubmit}
    >
      <div className={classes.root}>
        <div className={classes.card}>
          <form>
            <FormControl error={emailError} fullWidth={true} className={classes.form} disabled={merchantEnquiry.status === dataStatus.SUCCESS}>
              <CustomInputLabel>Your Email</CustomInputLabel>
              <CustomInput
                autoComplete={'email'}
                type='email'
                onChange={handleCheck('email')}
                onKeyPress={handleKeyPress}
              />
              {emailError && (<Typography variant={'caption'} color={'error'}>Please input a valid email. E.g. tanggram@tanggram.com</Typography>)}
            </FormControl>

            <FormControl error={merchantNameError} fullWidth={true} className={classes.form} disabled={merchantEnquiry.status === dataStatus.SUCCESS}>
              <CustomInputLabel>Your Name</CustomInputLabel>
              <CustomInput
                autoComplete={'merchantName'}
                type='text'
                onChange={handleCheck('merchantName')}
                onKeyPress={handleKeyPress}
              />
              {merchantNameError && (<Typography variant={'caption'} color={'error'}>Your name cannot be empty and maximum 30 characters.</Typography>)}
            </FormControl>

            <FormControl error={mobileError} fullWidth={true} className={classes.form} disabled={merchantEnquiry.status === dataStatus.SUCCESS}>
              <CustomInputLabel>Your Mobile</CustomInputLabel>
              <CustomInput
                autoComplete={'mobile'}
                type='text'
                onChange={handleCheck('mobile')}
                onKeyPress={handleKeyPress}
              />
              {mobileError && (<Typography variant={'caption'} color={'error'}>Mobile number cannot be empty.</Typography>)}
            </FormControl>

            <FormControl error={storeNameError} fullWidth={true} className={classes.form} disabled={merchantEnquiry.status === dataStatus.SUCCESS}>
              <CustomInputLabel>Store Name</CustomInputLabel>
              <CustomInput
                autoComplete={'storeName'}
                type='text'
                onChange={handleCheck('storeName')}
                onKeyPress={handleKeyPress}
              />
              {storeNameError && (<Typography variant={'caption'} color={'error'}>Please enter your store name and maximum 30 characters.</Typography>)}
            </FormControl>

            <FormControl error={storeNameError} fullWidth={true} className={classes.form} disabled={merchantEnquiry.status === dataStatus.SUCCESS}>
              <CustomInputLabel>Store Category</CustomInputLabel>
              <CustomSelect
                native
                value={formData.category}
                onFocus={handleCheck('category')}
                onChange={handleCheck('category')}
              >
                <option key={'ple_sec_cat'} value={'0'}>Please Select ...</option>
                {categoryOptions.map((option) => {
                  return (
                    <option key={option.label} value={option.value}>{option.label}</option>
                  )
                })}
              </CustomSelect>
              {categoryError && (<Typography variant={'caption'} color={'error'}>Please select a category.</Typography>)}
            </FormControl>

            <FormControl error={abnError} fullWidth={true} className={classes.form} disabled={merchantEnquiry.status === dataStatus.SUCCESS}>
              <CustomInputLabel>ABN</CustomInputLabel>
              <CustomInput
                autoComplete={'abn'}
                type='number'
                onChange={handleCheck('abn')}
                onKeyPress={handleKeyPress}
              />
              {abnError && (<Typography variant={'caption'} color={'error'}>ABN number cannot be empty.</Typography>)}
            </FormControl>

            <FormControl fullWidth={true} className={classes.form} disabled={merchantEnquiry.status === dataStatus.SUCCESS}>
              <CustomInputLabel>Address - Optional</CustomInputLabel>
              <CustomInput
                autoComplete={'address'}
                type='text'
                onChange={handleCheck('address')}
                onKeyPress={handleKeyPress}
              />
            </FormControl>

            <FormControl fullWidth={true} className={classes.form} disabled={merchantEnquiry.status === dataStatus.SUCCESS}>
              <CustomInputLabel>Suburb - Optional</CustomInputLabel>
              <CustomInput
                autoComplete={'suburb'}
                type='text'
                onChange={handleCheck('suburb')}
                onKeyPress={handleKeyPress}
              />
            </FormControl>

            <FormControl fullWidth={true} className={classes.form} disabled={merchantEnquiry.status === dataStatus.SUCCESS}>
              <CustomInputLabel>State - Optional</CustomInputLabel>
              <CustomSelect
                native
                value={formData.auState}
                onFocus={handleCheck('auState')}
                onChange={handleCheck('auState')}
              >
                <option key={'ple_sec_sta'} value={'0'}>Please Select ...</option>
                {auStateOptions.map((option) => {
                  return (
                    <option key={option.label} value={option.value}>{option.label}</option>
                  )
                })}
              </CustomSelect>
            </FormControl>

            <FormControl fullWidth={true} className={classes.form} disabled={merchantEnquiry.status === dataStatus.SUCCESS}>
              <CustomInputLabel>Postcode - Optional</CustomInputLabel>
              <CustomInput
                autoComplete={'postcode'}
                type='number'
                onChange={handleCheck('postcode')}
                onKeyPress={handleKeyPress}
              />
            </FormControl>

            <FormControl fullWidth={true} className={classes.form} disabled={merchantEnquiry.status === dataStatus.SUCCESS}>
              <CustomInputLabel>Message</CustomInputLabel>
              <CustomInput
                autoComplete={'message'}
                type='text'
                onChange={handleCheck('message')}
                onKeyPress={handleKeyPress}
                multiline
                rowsMax={8}
                rows={4}
              />
            </FormControl>

          </form>
        </div>
        <AsyncContainer alias={merchantEnquiryLoading}>
          {merchantEnquiry.status === dataStatus.FAILURE && (<ErrorMessageBar className={classes.msgBar}>{merchantEnquiry.errors.join(' ')}</ErrorMessageBar>)}
          {merchantEnquiry.status === dataStatus.SUCCESS && (<SuccessMessageBar className={classes.msgBar}>Submitted Successfully!</SuccessMessageBar>)}
        </AsyncContainer>
      </div>
    </DefaultBasicCard>
  );
}

MerchantEnquiryForm.propTypes = {
};

function mapStateToProps(state) {
  return {
    merchantEnquiry: state.merchantEnquiry,
  }
}

const mapDispatchToProps = {
  createEnquiry: merchantEnquiry.create,
  resetEnquiry: merchantEnquiry.reset,
};

export default connect(mapStateToProps, mapDispatchToProps)(withPageControl(MerchantEnquiryForm));
