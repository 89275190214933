import {put, takeLeading} from 'redux-saga/effects';
import createState from './state';
import {
  passData,
} from '../actions';

export default createState(
  'registerRef',
  {
    ref: '',
  },
  ({addSaga, reduce, reduceError, reduceReset}) => {
    addSaga(function* () {
      yield takeLeading('LOAD_REGISTER_REF', function* (action) {
        yield put(passData('LOAD_REGISTER_REF_R', {ref: action.ref}));
      });
    });

    reduce('LOAD_REGISTER_REF_R', function (state, action) {
      return Object.assign({}, action.ref);
    });
    reduceError('LOAD_REGISTER_REF_F');
    reduceReset('LOAD_REGISTER_REF_RESET');
  }
);

export const registerRef = {
  set: (ref) => ({type: 'LOAD_REGISTER_REF', ref}),
  reset: () => ({type: 'LOAD_REGISTER_REF_RESET'}),
};
