import React, {useState, createContext} from 'react';
import {LANGUAGE} from 'i18n/language';
import {saveInLocal, loadFromLocal} from 'logic/service/local_storage';

export const I18nLanguageContext = createContext();

export default function ContextProvider({children}) {
  const cookieKey = 'tanggram_i18n_language';

  const [language, setLanguage] = useState(() => {
    const storedLanguage = loadFromLocal(cookieKey);
    return storedLanguage ? storedLanguage : LANGUAGE.ENGLISH;
  });

  function changeLanguage(lang) {
    saveInLocal(cookieKey, lang, {maxAge: 31536000});
    setLanguage(lang);
  }

  return (
    <I18nLanguageContext.Provider
      value={{
        language,
        changeLanguage,
      }}
    >
      {children}
    </I18nLanguageContext.Provider>
  );
}

