import React from 'react';
import CustomerActivation from './CustomerActivation';
import {Body} from 'component/NavLayout';
import TopMenuLayout from 'component/TopMenuLayout';
import AsyncContainer from 'component/AsyncContainer';
import {customerActivationLoading} from 'logic/redux/state/customer_activation';

function CustomerActivationPage() {
  return (
    <TopMenuLayout
      title={'Activation'}
      TitleStyle={{marginTop: -30}}
    >
      <Body>
        <AsyncContainer alias={customerActivationLoading}>
          <CustomerActivation/>
        </AsyncContainer>
      </Body>
    </TopMenuLayout>
  );
}

CustomerActivationPage.propTypes = {
};

const mapDispatchToProps = {
};

export default CustomerActivationPage;
