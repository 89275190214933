import {put, takeLeading} from 'redux-saga/effects';
import createState from './state';
import callVerifyCustomerMobile from '../../service/api/method/verify_customer_mobile';
import {dataStatus, transformApiData} from '../transform';
import {
  getApiResponse,
  handleError,
  showLoading,
  hideLoading,
} from '../actions';
import {TUser} from './user_session';
import TDataStatus from '../data_status';
import {TArray, declareObject} from 'lib/Core/prop_types';

export const TCustomerMobileVerification = declareObject({
  status: TDataStatus.isRequired,
  user: TUser,
  errors: TArray,
});

export const customerMobileVerificationLoading = 'customerMobileVerification.1';

export default createState(
  'customerMobileVerification',
  {
    status: dataStatus.INIT,
    user: null,
    errors: [],
  },
  ({addSaga, reduce, reduceError, reduceReset}) => {
    addSaga(function* () {
      yield takeLeading('ACTIVATE_CUSTOMER_MOBILE', function* (action) {
        try {
          yield put(showLoading(customerMobileVerificationLoading));
          const apiData = yield callVerifyCustomerMobile(action.postData);
          yield put(getApiResponse('ACTIVATE_CUSTOMER_MOBILE_R', apiData));
          yield put(hideLoading(customerMobileVerificationLoading));
        } catch (e) {
          yield put(handleError('ACTIVATE_CUSTOMER_MOBILE_F', e));
          yield put(hideLoading(customerMobileVerificationLoading));
        }
      });
    });

    reduce('ACTIVATE_CUSTOMER_MOBILE_R', function (state, action) {
      let data = Object.assign({}, state);
      const apiData = transformApiData(action.data);
      data.status = apiData.status;
      if (data.status === dataStatus.FAILURE) {
        data.errors = apiData.errors;
      } else if (data.status === dataStatus.SUCCESS) {
        data.user = apiData.data.user;
      }
      return data;
    });
    reduceError('ACTIVATE_CUSTOMER_MOBILE_F');
    reduceReset('ACTIVATE_CUSTOMER_MOBILE_RESET');
  }
);

export const customerMobileVerification = {
  verify: postData => ({type: 'ACTIVATE_CUSTOMER_MOBILE', postData}),
  reset: () => ({type: 'ACTIVATE_CUSTOMER_MOBILE_RESET'}),
};