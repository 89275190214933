import React from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import {TConnectionError} from 'logic/redux/type';
import {StandardDialog} from '../Dialog';
import {TangneyConnectionErrorSVG} from '../SVG';
import {connectionError} from 'logic/redux/actions';
import {isDebugMode} from 'tanggram';
import ForwardRefContainer from 'lib/Core/ForwardRefContainer';

const useStyles = makeStyles({
  row: {
    display: 'flex',
    justifyContent: 'center',
    padding: 8,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: 20,
  },
});

/**
 * @returns {null}
 */
function ConnectionError({connectionError, hide}) {
  const classes = useStyles();

  function handleCloseDialog() {
    hide();
  }

  if (connectionError.on) {
  // if (true) {
    return (
      <StandardDialog
        open={true}
        onClose={handleCloseDialog}
      >
        <ForwardRefContainer className={classes.row}>
          <div className={classes.column}>
            <Typography variant='h6'>Connection Error</Typography>
            {isDebugMode() ? (<Typography variant='body1'>{connectionError.error ? connectionError.error.message : 'No Error Is Caught...'}</Typography>) : null}
            <Typography variant='body1' color='textSecondary'>Please try again later.</Typography>
          </div>
          <TangneyConnectionErrorSVG scale={0.5}/>
        </ForwardRefContainer>
      </StandardDialog>
    );
  } else {
    return null;
  }
}

ConnectionError.propTypes = {
  connectionError: TConnectionError.isRequired,
};

function mapStateToProps(state) {
  return {
    connectionError: state.connectionError,
  }
}

const mapDispatchToProps = {
  hide: connectionError.hide,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionError);
