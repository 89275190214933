import React, {useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import {TObject, TFunction, TString} from 'lib/Core/prop_types';
import SmallDialog from '../CustomDialog/SmallDialog';
import {getWindowInnerWidth} from 'viewport';

const _width = getWindowInnerWidth();

const useStyles = makeStyles({
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowX: 'hidden',
    width: _width -16,
  },
  rCorner: {
    cursor: 'pointer',
    overflowY: 'hidden',
  },
  ab: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: '100%',
  },
  lbw: {
    minWidth: _width / 2 -16,
  },
  lb: {
    textTransform: 'none',
    paddingLeft: 8,
  },
});

function Transition(props) {
  return <Slide direction='down' {...props}/>;
}

function AlertDialog({
  className,
  children,
  onCancel,
  ConfirmButton,
  leftText = 'Cancel',
  leftTextColor,
  bodyStyle
}) {
  const classes = useStyles();
  const theme = useTheme();
  const _leftTextColor = leftTextColor ? leftTextColor : theme.palette.text.secondary;

  const [isOpen, setIsOpen] = useState(true);

  function handleAction() {
    setIsOpen(false);
  }

  function handleClose() {
    if (onCancel) {
      onCancel();
    }
    setIsOpen(false);
  }

  function renderRightButton() {
    const _buttonProps = Object.assign({}, {size: 78});
    return (
      <div
        className={classes.rCorner}
        style={{height: _buttonProps.size}}
      >
        <div onClick={handleAction}>
          {ConfirmButton}
        </div>
      </div>
    );
  }

  function renderLeftButton() {
    return (
      <div className={classes.lbw}>
        <Button component='button' size='large' className={classes.lb} onClick={handleClose} style={{color: _leftTextColor}}>
          {leftText}
        </Button>
      </div>
    );
  }

  const _className = className ? className : classes.root;
  const _bodyStyle = bodyStyle ? bodyStyle : null;

  return (
    <SmallDialog
      open={isOpen}
      disableBackdropClick={true}
      className={_className}
      TransitionComponent={Transition}
    >
      <div className={classes.body} style={_bodyStyle}>
        {children}
      </div>
      <div className={classes.ab}>
        {renderLeftButton()}
        {renderRightButton()}
      </div>
    </SmallDialog>
  );
}

AlertDialog.propTypes = {
  ConfirmButton: TObject.isRequired,
  onCancel: TFunction,
  leftText: TString,
};

export default AlertDialog;
