import React from 'react';
import {makeStyles} from '@material-ui/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import withPageControl from '../HOC/withPageControl';
import {
  TFunction,
  TNode,
  TNumber,
  TObject,
  TString,
} from 'lib/Core/prop_types';
import FlashIcon from 'lib/Core/Animation/FlashIcon';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: ({size}) => (size),
    height: ({size}) => (size),
    opacity: ({isActive}) => (isActive ? 1 : 1),
    backgroundColor: ({colorDark, isActive}) => (isActive ? colorDark : theme.design.background.light),
    padding: 10,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    // borderStyle: ({isActive}) => (!isActive ? 'solid' : 'none'),
    // borderWidth: ({isActive}) => (!isActive ? 1 : 0),
    // borderColor: ({colorDark, isActive}) => (!isActive ? colorDark : theme.design.background.light),
  },
  bb: {
    borderRadius: 10,
  },
  wrapper: {
    marginRight: 8,
    marginLeft: 8,
  },
}));

function MenuItem({
  pageControl,
  location,
  Icon,
  colorDark,
  colorLight,
  size = 46,
  style = {},
  isActive = false,
  // raised = false,
  toUri = null,
  onClick = () => {},
}) {
  const classes = useStyles({colorDark, colorLight, size, isActive});

  function _onClick() {
    if (toUri) {
      pageControl.toPage(location, toUri);
    } else {
      onClick();
    }
  }

  const _Button = (
    <ButtonBase className={classes.bb}>
      <div
        className={classes.root}
        onClick={_onClick}
        style={style}
      >
        {Icon}
      </div>
    </ButtonBase>
  );

  return (
    <div className={classes.wrapper}>
      {isActive
        ? (
          <FlashIcon delay={0}>
            {_Button}
          </FlashIcon>
        )
        : (_Button)
      }
    </div>

  );
}

MenuItem.propTypes = {
  pageControl: TObject.isRequired,
  location: TObject.isRequired,
  Icon: TNode.isRequired,
  colorDark: TString.isRequired,
  colorLight: TString.isRequired,
  size: TNumber,
  style: TObject,
  // raised: TBool,
  onClick: TFunction,
};

export default withPageControl(MenuItem);
