import {useContext} from 'react';
import {I18nLanguageContext} from './Context';
import {LANGUAGE} from 'i18n/language';
import dictionary from 'i18n/language';

// Language hook.
export function useI18nLang() {
  const {language, changeLanguage} = useContext(I18nLanguageContext);
  return {changeLanguage, language};
}

// Translator hook.
export function useI18nTranslator() {
  const {language} = useContext(I18nLanguageContext);
  function translate(content, options = {}) {
    // Do not translate English.
    if (language === LANGUAGE.ENGLISH) {
      return content;
    }

    // Look into the direction and find the translation. If not found, return the original content.
    if (!dictionary.hasOwnProperty(language)) {
      return content;
    }
    const _content = content.toLowerCase();
    if (!dictionary[language].hasOwnProperty(_content)) {
      return content;
    }
    return dictionary[language][_content];
  }
  return [translate, language];
}