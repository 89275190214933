import React from 'react';

function icon(Icon, style = {}) {
  return (<Icon style={style}/>);
}

export function menuCardIcon(Icon, color = '#FFF') {
  return icon(Icon, {width: 28, height: 28, color,});
}

export function floatingButtonIcon(Icon, color = '#FFF') {
  return icon(Icon, {width: 24, height: 24, color,});
}

export function icon16(Icon, color = '#FFF') {
  return icon(Icon, {width: 16, height: 16, color,});
}

export function icon24(Icon, color = '#FFF') {
  return icon(Icon, {width: 24, height: 24, color,});
}

export function smallIcon(Icon, color = '#FFF') {
  return icon(Icon, {width: 28, height: 28, color,});
}

export function topMenuItemIcon(Icon, color = '#FFF') {
  return icon(Icon, {width: 26, height: 26, color,});
}

export function bottomNavIcon(Icon, color = '#FFF') {
  return icon(Icon, {width: 32, height: 32, color,});
}

export function miniIcon(Icon, color = '#FFF') {
  return icon(Icon, {width: 15, height: 15, color,});
}

export function mediumIcon(Icon, color = '#FFF') {
  return icon(Icon, {width: 30, height: 30, color,});
}

export function subFloatingIcon(Icon, color = '#FFF') {
  return icon(Icon, {width: 20, height: 20, color,});
}
