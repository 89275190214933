import React from 'react';
import {
  makeStyles,
} from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import PlainCard from 'component/Card/PlainCard';
import getS3ImagePath from 'logic/utils/getS3ImagePath';
import Image from 'component/Image';
import AppDownloadIcon from 'component/AppDownloadIcon';
import theme from 'theme';
import config from 'config';

const useStyles = makeStyles(theme => ({
  card: {
    // padding: 16,
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    display: 'flex',
    color: '#FFFFFF',
    flexWrap: 'wrap',
  },
  image: {
    display: 'flex',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexGrow: 1
  },
}));

const bannerWidth = config.getWindowInnerWidth() - 16;

function AppDownloadClickBait() {
  const classes = useStyles();

  return (
    <div>
      {/*<PlainCard*/}
        {/*className={classes.card}*/}
        {/*style={{backgroundColor: '#51246C'}}*/}
      {/*>*/}
        {/*<Typography variant={'subtitle1'} className={classes.text}>Get extra 3 coffees</Typography>*/}
        {/*<Image */}
          {/*path={getS3ImagePath('/event/coffee-zero-app-download-free-coffees.png')} */}
          {/*className={classes.image}*/}
          {/*alt='3 free coffees' */}
          {/*width={80} */}
          {/*aspectRatio={128/180}*/}
        {/*/>*/}
      {/*</PlainCard>*/}
      {/*<PlainCard*/}
        {/*className={classes.card}*/}
        {/*style={{backgroundColor: theme.design.wealth.text}}*/}
      {/*>*/}
        {/*<Typography variant={'subtitle1'} className={classes.text}>Access up to 5.05% reliable funds</Typography>*/}
        {/*<Image */}
          {/*path={getS3ImagePath('/event/coffee-zero-app-download-invest.png')} */}
          {/*className={classes.image}*/}
          {/*alt='3 free coffees' */}
          {/*width={80} */}
          {/*aspectRatio={128/180}*/}
        {/*/>*/}
      {/*</PlainCard>*/}
      {/*<PlainCard*/}
        {/*className={classes.card}*/}
        {/*style={{backgroundColor: theme.design.reward.text}}*/}
      {/*>*/}
        {/*<Typography variant={'subtitle1'} className={classes.text}>Hundreds of hot deals and investment credits</Typography>*/}
        {/*<Image */}
          {/*path={getS3ImagePath('/event/coffee-zero-app-download-rewards.png')} */}
          {/*className={classes.image}*/}
          {/*alt='3 free coffees' */}
          {/*width={80} */}
          {/*aspectRatio={128/180}*/}
        {/*/>*/}
      {/*</PlainCard>*/}
      <PlainCard
        className={classes.card}
      >
        <Image
          path={getS3ImagePath('/event/coffee-zero-app-download-3.png')}
          className={classes.image}
          alt='Unlock 3 Free Coffees'
          width={bannerWidth}
          aspectRatio={800/718}
          style={{borderRadius: 10,}}
        />
      </PlainCard>
      <div className={classes.row} style={{marginBottom: 8,}}>
        <Typography variant={'body1'} color={'textPrimary'}>Download the Tanggram app</Typography>
      </div>
      <AppDownloadIcon/>
    </div>
  );
}

AppDownloadClickBait.propTypes = {
};

export default AppDownloadClickBait;
