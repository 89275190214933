import React from 'react';
import {dataStatus} from 'logic/redux';
import {connect} from 'react-redux';
import {URI} from 'page/Layout/uri';
import withPageControl, {TPageControl} from './withPageControl';
import {TUserSession} from 'logic/redux/type';
import {userLogin, userSession as userSessionAction} from 'logic/redux/actions';
import AccessDenied from '../AccessDenied';
import LoginInProgress from '../LoginInProgress';
import auth from 'logic/service/auth';
import {_logging_} from 'logger';

const defaultOptions = {
  allowInactive: false,
  AccessDenied: (<AccessDenied/>),
  LoginInProgress: (<LoginInProgress/>),
};

function withAcl(Component, options = defaultOptions) {
  // console.log(Component);
  const {_logger_} = _logging_(`withAcl${Component.name}`);

  const _options = {...defaultOptions, ...options};
  let _log_ = _logger_()._log_;
  class WithAcl extends React.Component {
    constructor(props) {
      super(props);
      this.syncUserSession();
    }

    redirectWhenNotLoggedIn() {
      const {userSession, pageControl, location} = this.props;
      if (userSession.status !== dataStatus.INIT
        && !userSession.isLoggedIn) {
        pageControl.toPage(location, URI.SPLASH);
      }
    }

    redirectWhenUserInactive() {
      const {userSession, pageControl, location} = this.props;
      if (!_options.allowInactive &&
        userSession.isLoggedIn
        && !userSession.user.isActive
      ) {
        pageControl.toPage(location, URI.CUSTOMER_ACTIVATE);
      }
    }

    redirectWhenUserIsMerchant() {
      const {userSession, syncRT, resetUserLogin} = this.props;
      if (userSession.status !== dataStatus.INIT
        && userSession.isLoggedIn && userSession.roles.isMerchant) {
        auth.logoutLocally();
        syncRT();
        resetUserLogin();
      }
    }

    componentDidMount() {
      this.redirectWhenNotLoggedIn();
      this.redirectWhenUserInactive();
      this.redirectWhenUserIsMerchant();
    }

    componentDidUpdate() {
      this.redirectWhenNotLoggedIn();
      this.redirectWhenUserInactive();
      this.redirectWhenUserIsMerchant();
    }

    syncUserSession() {
      const {pageControl, location, userSession} = this.props;
      if (!auth.getSession().getId()) {
        _log_()('No session id.', userSession, auth.getSession(), auth.getSessionToken());
        /**
         * Check the session token (should've been loaded from local storage already if there is one).
         */
        if (auth.getSessionToken().getId()) {
          _log_()('Found session token: ', auth.getSessionToken().getId(), userSession,);
          // The session token is found in the memory.
          /**
           * @todo To pin login page.
           */
          pageControl.toPage(location, URI.SPLASH);
        } else {
          _log_()('No session token.');
          /**
           * Has to use username/password to login.
           */
          pageControl.toPage(location, URI.SPLASH);
        }
      } else {
        _log_()('Found session id.');
        if (userSession.status === dataStatus.INIT) {
          _log_()('Init session.');
          // Sync the user session state if it's not done yet.
          this.props.sync();
        }
      }
    }

    render() {
      const {userSession} = this.props;
      if (userSession.isLoggedIn) {
        _log_()('User has been logged in.');
        if (_options.allowInactive || userSession.user.isActive) {
          // Render the target View.
          return (
            <Component {...this.props} />
          );
        } else {
          return _options.AccessDenied;
        }
      } else {
        if (userSession.status === dataStatus.INIT) {
          return _options.LoginInProgress;
        } else {
          return _options.AccessDenied;
        }
      }
    }
  }

  WithAcl.propTypes = {
    userSession: TUserSession.isRequired,
    pageControl: TPageControl.isRequired,
  };

  function mapStateToProps(state) {
    return {
      userSession: state.userSession,
    }
  }

  const mapDispatchToProps = {
    sync: userSessionAction.sync,
    syncRT: userSessionAction.syncRT,
    resetUserLogin: userLogin.reset,
  };

  return connect(mapStateToProps, mapDispatchToProps)(withPageControl(WithAcl));
}

export default withAcl;
