import React from 'react';
import {makeStyles} from '@material-ui/styles';
import PlainCard from 'component/Card/PlainCard';
import Typography from '@material-ui/core/Typography';
import Image from 'component/Image';

const useStyles = makeStyles({
  title: {
    paddingTop: 10,
    lineHeight: 1.2,
  },
  text: {
    margin: 8,
    textAlign: 'left',
    marginTop: 3,
    marginBottom: 3,
  },
  lr: {
    marginLeft: 8,
    marginRight: 8,
    marginTop: 3,
    marginBottom: 3,
  },
});

function PrivacyPolicy() {
  const classes = useStyles();

  return (
    <PlainCard style={{paddingLeft: 8, paddingRight: 8, paddingTop: 16,paddingBottom: 16, textAlign: 'justify'}}>
      <Typography variant='body2' className={classes.text}>The privacy of your personal information is of supreme importance to Tanggram Pty Ltd ACN 18 625 016 480 ("Tanggarm"). This policy explains how we handle personal information and comply with the requirements of applicable privacy laws ("Privacy Laws"). It applies to your personal information when you use our website and our App and interact generally with us.</Typography>
      <Typography variant='body2' className={classes.text}>We respect that you have a right to control how your personal information is collected and used. We value your trust, and aim to help you manage and build wealth over a long period. The protection of your personal information is vital to sustain this relationship.</Typography>
      <Typography variant='body2' className={classes.text}>We reserve the right to update this Privacy Policy at any time and the most up to date version will be published on our Website. If at any point we decide to use personal information in a manner materially different from that stated at the time it was collected, we will notify users by email or via a prominent notice on our website, and where necessary we will seek the prior consent of our users.</Typography>
      <Typography variant='subtitle1' className={classes.title}>1. Why we collect personal information</Typography>
      <Typography variant='body2' className={classes.text}>1.1 When you visit our Website or use our App, we collect personal information so that we can provide you with products and services and improve your experience with us. </Typography>
      <Typography variant='body2' className={classes.text}>1.2 The reasons for which we collect and hold your personal information include:</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; to deliver our products and services to you;</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; to confirm your identity and process your transactions through our App;</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; to facilitate your participation in functions, events or activities conducted by us or our merchants;</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; to manage our relationship with you, evaluate our business performance and build our customer database;</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; to provide you with information about our products, services, functions, events or activities;</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; to enable you to participate in our promotion, competition, survey and/or enable you to subscribe to mailing lists/newsletters and interact or follow our social media pages, including but not limited to, Twitter, Facebook and Instagram, Wechat;</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; to respond to your requests and see your feedback;</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; to conduct research, compare information for accuracy and verification purposes, compile or analyse statistics relevant to the operations of our business;</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; to facilitate our internal business operations, including fulfilment of any legal and regulatory requirements and monitoring, analysing and improving the performance and functionality of our Website and App and investigating breaches of or enforcement of any legal terms applicable to our Website or the App;</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; to manage risk and protect our Website and App from fraud by verifying your identity and helping to detect and prevent fraudulent use of our Website and App;</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; for the direct marketing purposes as set out below; </Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; to help improve the efficiency and your online experience by analysing data collected from visits and activities of users on our Website and App. </Typography>
      <Typography variant='subtitle1' className={classes.title}>2. How we collect personal information</Typography>
      <Typography variant='body2' className={classes.text}>2.1 We collect personal information from existing and potential customers, and use that information for the purposes identified in this privacy policy.</Typography>
      <Typography variant='body2' className={classes.text}>2.2 The types of personal information we will collect from you will depend on the circumstances in which that information is collected. It may include:</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; contact details (eg. your name, address, email, phone number);</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; information required for you to open an account with us or receive services from or do business with us. This information may include bank account details that you link to your account;</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; personal information about you that allows us to tailor the services and product offerings we make available to you;</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; statistical information regarding the use of our Tanggram Website or App, including website users' IP addresses and the dates and times of visits; </Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; transaction details relating to your use of our products, services or rewards; and</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; personal information regarding your friends and contacts if you invite them to join Tanggram.</Typography>
      <Typography variant='body2' className={classes.text}>2.3 We usually collect personal information when you:</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; submit information to us (including via our Website or App), eg. when you register a user account, retrieve log-in information, or request a new password;</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; register for email subscriptions, or publications through our Website or App;</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; request information or support concerning our products and services;</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; receive services from us. This may include us accessing information held by your financial institution or other third parties that we have relationships with;</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; use our Website or App;</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; contact us (through the Website, App, or by email or phone) or request that we contact you;</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; complete any survey or provide feedback to us concerning our products or services; or</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; participate in promotions or competitions run by or with the support of Tanggram.</Typography>
      <Typography variant='body2' className={classes.text}>2.4 You may choose to provide us with access to certain personal information stored by third parties such as social media sites. The information we may receive varies by site and is controlled by that site. By associating an account managed by a third party with your Tanggram account and authorising Tanggram to have access to this information, you agree that Tanggram may collect, store and use this information in accordance with this Privacy Policy.</Typography>
      <Typography variant='body2' className={classes.text}>2.5 If you do not provide us with the information we request, our ability to supply you with products or services, or to otherwise fulfil the purpose for which you have provided your information may be limited. </Typography>
      <Typography variant='body2' className={classes.text}>2.6 We will collect personal information directly from you, but may also collect information about you from third parties. If we receive information about you from someone else, we will take reasonable steps to make you aware of the fact and circumstances of the collection.</Typography>
      <Typography variant='subtitle1' className={classes.title}>3. How we use and disclose personal information</Typography>
      <Typography variant='body2' className={classes.text}>3.1 We will only use and disclose your personal information in accordance with Privacy Laws and this Privacy Policy. We may share your personal information with:</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; our affiliates and related companies, and merchants;</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; the suppliers and service providers who help with our business operations including in relation to fraud prevention, identity verification, marketing, and technology services;</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; financial institutions that we use to provide you with services;</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; law enforcement, government agencies or officials, or other third parties pursuant to a subpoena, court order, or other legal process or requirement applicable to us; when we need to do so to comply with law; or when we believe, in our sole discretion, that the disclosure of personal information is necessary to prevent financial loss, to report suspected illegal activity or to investigate suspected violations of our terms of use;</Typography>
      <Typography variant='body2' className={classes.lr}>&#8226; to any other person or third parties with your consent (express or implied).</Typography>
      <Typography variant='subtitle1' className={classes.title}>4. How we use personal information for direct marketing</Typography>
      <Typography variant='body2' className={classes.text}>4.1 We may send you information about your account and our services or other items or material that you may be interested in. We will only contact you if you have consented to receive such messages.</Typography>
      <Typography variant='body2' className={classes.text}>4.2 If you do not wish to receive promotional information from us any longer, please contact us directly, or use the unsubscribe facilities included in our marketing communications.</Typography>
      <Typography variant='body2' className={classes.text}>4.3 We may provide your personal information to third parties who offer goods or services that you may be interested in. These third parties who may contact you directly in relation to those goods or services.</Typography>
      <Typography variant='subtitle1' className={classes.title}>5. Use of digital technologies</Typography>
      <Typography variant='body2' className={classes.text}>5.1 We may use digital technologies such as cookies, location services, web server logs and web beacons in connection with our Website and App, social media pages and other digital portals to recognise you and customise your online experience. Cookies are small files that store information on your computer, mobile phone or other device.  They enable us to recognise you across different websites, services, devices and/or browsing sessions.  </Typography>
      <Typography variant='body2' className={classes.text}>5.2 We also use cookies to assist us to customise online content and advertising, measure the effectiveness of our promotions, prevent potential fraud, estimate our number of members, determine overall traffic patterns, and analyse users interactions with our Website and App. </Typography>
      <Typography variant='body2' className={classes.text}>5.3 if you do not wish to receive any cookies while you are using our Website or App, then you may set your browser settings to delete, disable or block certain Cookies. Your use of the Website and App may be limited.</Typography>
      <Typography variant='subtitle1' className={classes.title}>6. How to access and correct your personal information</Typography>
      <Typography variant='body2' className={classes.text}>6.1 If at any time you wish to access or correct personal information that we hold about you, please contact us at customer@tanggram.com. If you wish to have your personal information deleted, please let us know in the same manner as referred to above and we will take all reasonable steps to delete it unless we need to keep it for legal reasons.</Typography>
      <Typography variant='subtitle1' className={classes.title}>7. How we store and secure the personal information</Typography>
      <Typography variant='body2' className={classes.text}>7.1 We take all reasonable steps to keep secure any information which we hold about you, and to keep this information accurate, up to date and complete. Your information is stored on secure servers that are protected in controlled facilities. We require our employees and data processors to respect the confidentiality of any personal information held by us.</Typography>
      <Typography variant='body2' className={classes.text}>7.2 If we become aware of unauthorised access to or disclosure of your personal information we will take appropriate steps to rectify the data breach and will notify you in accordance with the requirements of the Privacy Laws.</Typography>
      <Typography variant='body2' className={classes.text}>7.3 When we no longer need to use your information, or are no longer required to retain it by law, regulation or policies, we will take steps to properly de-identify or destroy it.</Typography>
      <Typography variant='subtitle1' className={classes.title}>8. Contacting Us</Typography>
      <Typography variant='body2' className={classes.text}>8.1 If you have any concerns or complaints about how we handle your personal information, or if you have any questions about this policy, please contact us at customer@tanggram.com.</Typography>
      <Typography variant='body2' className={classes.text}>8.2 We aim to acknowledge receipt of all privacy complaints from you within 5 working days and resolve all complaints within 30 working days. Where we cannot resolve a complaint within that period, we will notify you of the reason for the delay as well as advising the time by which we expect to resolve the complaint.</Typography>
      <br/>
      <Image path={'/asset/img/splash_logo.png'} width={144} aspectRatio={0.3} style={{alignSelf: 'center', marginBottom: 16}}/>
    </PlainCard>
  );
}

PrivacyPolicy.propTypes = {
};

export default PrivacyPolicy;
