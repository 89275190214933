import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/styles';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Typography} from '@material-ui/core';
import {useSelector, useDispatch} from 'react-redux';
import Body from 'component/NavLayout/Body';
import TopMenuLayout from 'component/TopMenuLayout';
import DefaultBasicCard from 'component/Card/DefaultBasicCard';
import AsyncContainer from 'component/AsyncContainer';
import {actions as emailUnsubscribeUpdateActions, emailUnsubscribeUpdateLoading} from 'logic/redux/state/email_unsubscribe_update';
import {dataStatus} from 'logic/redux';
import ErrorMessageBar from 'component/MessageBar/ErrorMessageBar';
import SuccessMessageBar from 'component/MessageBar/SuccessMessageBar';

const useStyles = makeStyles(theme => ({
  body: {
    paddingBottom: 60,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexGrow: 1,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  label: {
    padding: theme.spacing(1),
  },
  form: {
    padding: theme.spacing(1.5),
  },
  formItem: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  messageBar: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}));

function SetOptions({jwtToken, preferences}) {
  const classes = useStyles();
  const [options, setOptions] = useState(preferences);
  const emailUnsubscribeUpdate = useSelector(state => state.emailUnsubscribeUpdate);
  const dispatch = useDispatch();

  const changeOption = (event) => {
    setOptions({ ...options, [event.target.name]: event.target.checked });
  };

  const saveOptions = () => {
    dispatch(emailUnsubscribeUpdateActions.reset());
    dispatch(emailUnsubscribeUpdateActions.save(jwtToken, options));
  };

  return (
    <TopMenuLayout
      title={'Email Preferences'}
      // BackButton={(<DefaultBackButton/>)}
      // SideMenu={(<AccountSideMenu activeItem={ACCOUNT_MENU_ITEM_ID.HOME}/>)}
    >
      <div className={classes.body}>
        <Body>
          <AsyncContainer alias={[emailUnsubscribeUpdateLoading]}>
            <DefaultBasicCard
              enableBottomRightButton={true}
              bottomRightButtonLabel={'Save'}
              bottomRightButtonOnClick={saveOptions}
            >
              <div style={{flexDirection: 'column'}}>
                <FormGroup className={classes.form}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={options.news ? true : false}
                        onChange={changeOption}
                        name="news"
                        color="primary"
                      />
                    }
                    label="Tanggram news, market updates."
                    className={classes.formItem}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={options.rewards ? true : false}
                        onChange={changeOption}
                        name="rewards"
                        color="primary"
                      />
                    }
                    label="Campaigns, big rewards, special deals."
                    className={classes.formItem}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={options.blogs ? true : false}
                        onChange={changeOption}
                        name="blogs"
                        color="primary"
                      />
                    }
                    label="Tanggram high quality articles."
                    className={classes.formItem}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={options.features ? true : false}
                        onChange={changeOption}
                        name="features"
                        color="primary"
                      />
                    }
                    label="Tanggram major releases, major new features."
                    className={classes.formItem}
                  />
                </FormGroup>
                {emailUnsubscribeUpdate.status === dataStatus.SUCCESS && (
                  <div>
                    <SuccessMessageBar className={classes.messageBar}>
                      Your email preferences have been updated.
                    </SuccessMessageBar>
                  </div>
                )}
                {emailUnsubscribeUpdate.status === dataStatus.FAILURE && (
                  <div>
                    <ErrorMessageBar className={classes.messageBar}>
                      An error occurred while updating your email preferences. Please try again later.
                    </ErrorMessageBar>
                  </div>
                )}
              </div>
            </DefaultBasicCard>
          </AsyncContainer>
        </Body>
      </div>
    </TopMenuLayout>
  );
}

export default SetOptions;
