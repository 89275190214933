import React from 'react';
import Input from '@material-ui/core/Input';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    background: theme.design.background.light,
    borderRadius: 10,
    border: 'none',
    minHeight: 48,
    fontSize: 18,
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    color: theme.palette.text.secondary,
    paddingLeft: 18,
    paddingRight: 10,
  },
}));

const CustomInput = (props) => {
  const classes = useStyles();

  return (
    <Input
      classes={{
        formControl: classes.formControl,
        input: classes.input,
      }}
      disableUnderline={true}
      {...props}
    />
  );
};

export default CustomInput;
