import React from 'react';
import {makeStyles} from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Body from 'component/NavLayout/Body';
import TopMenuLayout from 'component/TopMenuLayout';
import PlainCard from 'component/Card/PlainCard';

const useStyles = makeStyles(theme => ({
  body: {
    paddingBottom: 60,  
  },
  invalid: {
    color: theme.status.warning,
  },
  invalidDiv: {
    padding: theme.spacing(1),
  },
}));

function InvalidRef() {
	const classes = useStyles();

	return (
		<TopMenuLayout
			title={'Email Preferences'}
			// BackButton={(<DefaultBackButton/>)}
			// SideMenu={(<AccountSideMenu activeItem={ACCOUNT_MENU_ITEM_ID.HOME}/>)}
		>
			<div className={classes.body}>
				<Body>
					<PlainCard>
						<div className={classes.invalidDiv}>
							<Typography className={classes.invalid} variant={'h6'}>
								Request invalid.
							</Typography>
						</div>
					</PlainCard>
				</Body>
			</div>
		</TopMenuLayout>
	)
}

export default InvalidRef;
