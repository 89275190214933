import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import InputAdornment from '@material-ui/core/InputAdornment';
import {useSelector, useDispatch} from 'react-redux';
import useConfig from 'lib/Core/hook/useConfig';
import {dataStatus} from 'logic/redux';
// import {TObject} from 'lib/Core/prop_types';
// import {
//   TCustomerRegistration,
//   TUserLogin,
// } from 'logic/redux/type';
import CustomInput from 'component/CustomInput';
import CustomInputLabel from 'component/CustomInputLabel';
import auth from 'logic/service/auth';
import userConstraint from 'logic/service/constraint/user';
import {
  customerRegistration as customerRegistrationActions,
  userLogin as userLoginActions,
  userSession as userSessionActions,
  customerActivation as customerActivationActions,
  customerResendCode as customerResendCodeActions,
} from 'logic/redux/actions';
import useFormData from 'logic/hook/useFormData';
import AsyncContainer from 'component/AsyncContainer';
import ErrorMessageBar from 'component/MessageBar/ErrorMessageBar';
import DefaultBasicCard from 'component/Card/DefaultBasicCard';
import DefaultDialogCard from 'component/Card/DefaultDialogCard';
import TermsAndConditions from '../TermsAndConditionsPage/TermsAndConditions';
import PrivacyPolicy from '../PrivacyPolicyPage/PrivacyPolicy';
import Image from 'component/Image';
import usePageNavigator, {pages} from 'page/Layout/pages';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  card: {
    padding: '10px 16px 0 16px',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 8,
    marginBottom: 10,
  },
  err: {
    fontSize: 12,
  },
  form: {
    display: 'flex',
    paddingBottom: 15,
  },
  msgBar: {
    marginTop: 8,
    marginRight: 16,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    paddingLeft: 0,
  },
  rowT: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 0,
  },
  text: {
    color: theme.design.textLink,
  },
  ck: {
    marginLeft: -12,
  },
  doc: {
    height: ({config}) => config.getWindowInnerHeight() - 210,
    overflowY: 'auto',
    marginTop: 20,
  },
  dt: {
    color: ({showErrorHint}) => showErrorHint ? theme.status.danger : theme.palette.text.secondary,
    cursor: 'pointer',
  },
  title: {
    textAlign: 'center',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: 15,
    paddingRight: 15,
  },
  media: {
    marginTop: 32,
    marginBottom: 8,
  },
  pre: {
    marginLeft: 12,
    marginRight: -10,
    marginTop: -2,
    color: theme.palette.text.light,
  },
}));

function CustomerRegisterForm({
  action,
}) {
  const config = useConfig();
  const [showErrorHint, setShowErrorHint] = useState(false);
  const classes = useStyles({config, showErrorHint});
  const pageNavigator = usePageNavigator();
  const userSession = useSelector(state => state.userSession);
  const customerRegistration = useSelector(state => state.customerRegistration);
  const userLogin = useSelector(state => state.userLogin);
  const dispatch = useDispatch();

  const [mobileError, setMobileError] = useState([false, null]);
  const [isAccept, setIsAccept] = useState(false);
  const [formData, setFormData] = useFormData({
    mobile: '',
  });
  const [openTCDialog, setOpenTCDialog] = useState(false);
  const [openPPDialog, setOpenPPDialog] = useState(false);

  const [loadingText, setLoadingText] = useState('');
  const [timeoutIndex, setTimeoutIndex] = useState(null);

  useEffect(
    () => {
      return () => {
        dispatch(customerRegistrationActions.reset());
        clearTimeout(timeoutIndex);
      }
    },
    [
      timeoutIndex,
      customerRegistrationActions.reset,
      dispatch,
    ]
  );

  useEffect(
    () => {
      // Redirect to activate page if the registration is successful.
      if (customerRegistration.status === dataStatus.SUCCESS) {
        // If the registered user is logged in. Redirect to home page.
        // We need to check the mobile here to make sure the logged-in user is the one just registered.
        if (userSession.isLoggedIn && userSession.user.mobile.substr(3) === formData.mobile) {
          pageNavigator.redirectTo(pages.CUSTOMER_ACTIVATE);
        } else {
          // Automatically login the user.
          dispatch(userLoginActions.login({
            username: formData.mobile,
            password: customerRegistration.randomPassword,
          }));
        }
        if (userLogin.status === dataStatus.SUCCESS) {
          auth.loginLocally({sessionId: userLogin.data.access_session_id});
          dispatch(userSessionActions.sync());
          pageNavigator.redirectTo(pages.CUSTOMER_ACTIVATE);
        }
      }
    },
    [
      formData.mobile,
      pageNavigator,
      userLoginActions.login,
      customerRegistration,
      userLogin,
      userSession,
      userSessionActions.sync,
      dispatch,
    ]
  );

  function handleCheck(inputField) {
    return function (event) {
      setFormData(inputField)(event);
      validateField(inputField, event.target.value);
    }
  }

  function handleAcceptTerms() {
    setShowErrorHint(false);
    setIsAccept(!isAccept);
  }

  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  }

  function handleSubmit() {
    const isValid = validateFields();
    if (isValid && isAccept) {
      dispatch(customerRegistrationActions.register({
        mobile: formData.mobile,
      }));
      setLoadingText('Creating the account ...');
      setTimeoutIndex(setTimeout(() => {
        setLoadingText('Sending the activation code to your mobile...')
      }, 800));
      dispatch(customerActivationActions.reset());
      dispatch(customerResendCodeActions.reset());
    }
    if (isValid && !isAccept){
      setShowErrorHint(true);
    }
  }

  function validateFields() {
    const data =  {
      mobile: formData.mobile,
    };
    for (const field in data) {
      if (data.hasOwnProperty(field)) {
        if (!validateField(field, data[field])) {
          return false;
        }
      }
    }
    return true;
  }

  function agree() {
    setOpenTCDialog(false);
    setOpenPPDialog(false);
    setIsAccept(true);
  }

  function renderTCDialog() {
    return (
      <DefaultDialogCard
        bottomRightButtonLabel={'Agree'}
        bottomRightButtonOnClick={agree}
        enableBottomRightButton={true}
        isOpen={openTCDialog}
        onClose={() => setOpenTCDialog(false)}
        enableTopRightButton={false}
      >
        <div className={classes.doc}>
          <Typography variant='h6' className={classes.title}>Terms & Conditions</Typography>
          <TermsAndConditions enableTransition={false}/>
        </div>
      </DefaultDialogCard>
    );
  }

  function renderPPDialog() {
    return (
      <DefaultDialogCard
        bottomRightButtonLabel={'Agree'}
        bottomRightButtonOnClick={agree}
        enableBottomRightButton={true}
        isOpen={openPPDialog}
        onClose={() => setOpenPPDialog(false)}
        enableTopRightButton={false}
      >
        <div className={classes.doc}>
          <Typography variant='h6' className={classes.title}>Privacy Policy</Typography>
          <PrivacyPolicy enableTransition={false}/>
        </div>
      </DefaultDialogCard>
    );
  }

  function validateField(inputField, value) {
    const result = userConstraint(inputField, value);
    if (result.isPassed) {
      setMobileError([false, null]);
    } else {
      setMobileError([true, (<FormHelperText className={classes.err}>{result.msg}</FormHelperText>)]);
    }
    return result.isPassed;
  }

  function toCmsPage() {

  }

  return (
    <DefaultBasicCard
      enableBottomRightButton={true}
      bottomRightButtonLabel={'Next'}
      bottomRightButtonOnClick={handleSubmit}
      style={{marginTop: 40}}
    >
      {renderTCDialog()}
      {renderPPDialog()}
      <div className={classes.root}>
        <div className={classes.body}>
          <div className={classes.media} onClick={() => toCmsPage}>
            {/*<a target='_blank' href='https://www.tanggram.com'>*/}
              <Image path={'/asset/img/tanggram-logo-with-caption.png'} width={240} aspectRatio={812/2160} alt={'Tanggram-logo'}/>
            {/*</a>*/}
          </div>
        </div>
        <div className={classes.card}>
          <form action={action} className={classes.col}>
            <FormControl error={mobileError[0]} fullWidth={true} className={classes.form}>
              <CustomInputLabel
                htmlFor='mobile-input'
              >
                Mobile
              </CustomInputLabel>
              <CustomInput
                id='mobile-input'
                name={'mobile'}
                autoComplete={'mobile'}
                type='number'
                onChange={handleCheck('mobile')}
                onKeyPress={handleKeyPress}
                startAdornment={(<InputAdornment position="start" className={classes.pre} disableTypography={true}>+61</InputAdornment>)}
              />
              {mobileError[1]}
            </FormControl>
          </form>
          {/*<div className={classes.row} onKeyPress={handleKeyPress}>*/}
          {/*  <Typography variant='body2' color='textSecondary'>Please input a frequently used mobile as it can not be changed once registered.</Typography>*/}
          {/*</div>*/}
          <div className={classes.rowT} onKeyPress={handleKeyPress}>
            <Checkbox className={classes.ck} checked={isAccept} color='primary' onClick={handleAcceptTerms}/>
            <Typography variant='body2' className={classes.dt}>I agree to accept the &nbsp;
              <span className={classes.text} onClick={() => {setOpenTCDialog(true)}}>Terms & Conditions</span> and <span className={classes.text} onClick={() => {setOpenPPDialog(true)}}>Privacy Policy</span>.</Typography>
          </div>
        </div>
        <AsyncContainer>
          {customerRegistration.status === dataStatus.FAILURE && (<ErrorMessageBar className={classes.msgBar}>{customerRegistration.errors.join(' ')}</ErrorMessageBar>)}
          <div/>
        </AsyncContainer>
        {customerRegistration.status !== dataStatus.FAILURE && (<Typography variant={'caption'} color={'primary'} style={{textAlign: 'center'}}>{loadingText}</Typography>)}
      </div>
    </DefaultBasicCard>
  );
}

CustomerRegisterForm.propTypes = {
};

export default CustomerRegisterForm;
