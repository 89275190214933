import React from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import HCenteredBox from '../ContentAlignedBox/HCenteredBox';

const useStyles = makeStyles({
  b: {
    padding: 10,
  },
});

function CircularBar({style = {}}) {
  const classes = useStyles();

  return (
    <HCenteredBox style={style}>
      <div className={classes.b}>
        <CircularProgress className={classes.bar} />
      </div>
    </HCenteredBox>
  );
}

CircularBar.propTypes = {
};

function mapStateToProps(state) {
  return {
  }
}

export default connect(mapStateToProps)(CircularBar);
