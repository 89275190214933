import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
  makeStyles,
} from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import {isAndroid} from 'logic/utils/platform';
import getEnv from 'tanggram';
import PlainCard from 'component/Card/PlainCard';
import registerSuccess from "./registerSuccess.json";
import {Lottie} from '@crello/react-lottie';
import Image from 'component/Image';
import getS3ImagePath from 'logic/utils/getS3ImagePath';
import isMobile from 'logic/utils/platform/isMobile';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 20,
    alignItems: 'center',
  },
  dt: {
    marginLeft: 8,
  },
  img: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  }
}));

function PortalList() {
  const classes = useStyles();
  const userSession = useSelector(state => state.userSession);
  // console.log(userSession);
  // const theme = useTheme();
  // const [appPlace] = useState(isAndroid() ? ' Google Play ' : ' Apple Store ');
  const [appIcon] = useState(isAndroid() ? '/google_play_2.png' : '/apple_store_2.png');

  function redirectToStoreMobile() {
    window.location.replace(getEnv().ext.appRedirectionUrl);
  }

  function redirectToStoreWeb(url) {
    window.open(url);
  }

  return (
    <>
      <PlainCard
        className={classes.root}
      >
        <Lottie
          config={{
            loop: false,
            autoplay: true,
            animationData: registerSuccess,
          }}
          width={160}
        />
        <Typography variant='h6'>Congratulations</Typography>
        <Typography color='textSecondary'>Now you can proceed to download the Tanggram app by clicking the button below.</Typography>
      </PlainCard>

      {/*<DefaultBasicCard*/}
      {/*  enableBottomRightButton={true}*/}
      {/*  bottomRightButtonLabel={'Go'}*/}
      {/*  bottomRightButtonOnClick={redirectToStoreMobile}*/}
      {/*>*/}
      {/*  <div className={classes.row}>*/}
      {/*    {smallIcon(isAndroid() ? DownloadGooglePlayIcon : DownloadAppleStoreIcon, theme.palette.text.light)}*/}
      {/*    <Typography className={classes.dt}>Download Tanggram App via {appPlace}.</Typography>*/}
      {/*  </div>*/}
      {/*</DefaultBasicCard>*/}

      {
        isMobile() ?
          (
            <div className={classes.img}>
              <Image path={getS3ImagePath(appIcon)} width={200} aspectRatio={203/683} onClick={redirectToStoreMobile}/>
            </div>
          ) :
          (
            <div className={classes.img}>
              <Image path={getS3ImagePath('/google_play_2.png')} width={200} aspectRatio={203/683} onClick={() => redirectToStoreWeb(getEnv().ext.googlePlayUri)}/>
              <div style={{width: 100}}/>
              <Image path={getS3ImagePath('/apple_store_2.png')} width={200} aspectRatio={203/683} onClick={() => redirectToStoreWeb(getEnv().ext.appleStoreUri)}/>
            </div>
          )
      }
    </>
  );
}

PortalList.propTypes = {
};

export default PortalList;
