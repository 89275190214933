import React from 'react';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles({
  box: {
    display: 'flex',
    flexGrow: 1,
  },
  flex: {
    flexGrow: 1,
    padding: 0,
    margin: 0,
  },
});

function HCenteredBox({children, style}) {
  const classes = useStyles();

  let customStyle = null;
  if (style !== undefined) {
    customStyle = style;
  }
  return (
    <div style={customStyle} className={classes.box}>
      <div className={classes.flex}/>
      {children}
      <div className={classes.flex}/>
    </div>
  );
}

HCenteredBox.propTypes = {
};

export default HCenteredBox;
