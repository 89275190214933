import includeConstraints from './include_constraints';
import {isEmail} from 'logic/utils/validation';

const MerchantEnquiryFormConstraints = includeConstraints({
  email: (val) => {
    val = val.trim();
    return {
      isPassed: isEmail(val),
      val,
    };
  },
  merchantName: (val) => {
    val = val.trim();
    return {
      isPassed: /^[0-9a-zA-z.'\s]{1,30}$/.test(val),
      val,
    };
  },
  mobile: (val) => {
    val = val.trim();
    return {
      isPassed: /^[0-9()/+.'\s]{9,15}$/.test(val),
      val,
    };
  },
  storeName: (val) => {
    val = val.trim();
    return {
      isPassed: /^[0-9a-zA-z.'\s]{1,30}$/.test(val),
      val,
    };
  },
  category: (val) => {
    val = val.trim();
    return {
      isPassed: /^((?!0).)*$/.test(val),
      val,
    };
  },
  abn: (val) => {
    val = val.trim();
    return {
      isPassed: /^[0-9.'\s]{11}$/.test(val),
      val,
    };
  },
});

export default MerchantEnquiryFormConstraints;
