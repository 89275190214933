import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import useConfig from 'lib/Core/hook/useConfig';
import {TFunction, TNumber, TObject} from 'lib/Core/prop_types';
import {
  getWindowInnerHeight,
  getWindowInnerWidth,
  getTopPosition,
} from 'viewport';

const windowInnerHeight = getWindowInnerHeight();
const buttonSize = 65;
const buttonSizeDouble = getWindowInnerWidth() <= 500 ? buttonSize * 2 : buttonSize * 8;
const scaleRatio = Number((Math.sqrt(Math.pow(windowInnerHeight - 165, 2) * 2) / buttonSize).toFixed(2));
const rightScaleRatio = Number((Math.sqrt(Math.pow(windowInnerHeight - 150, 2) * 2) / 110).toFixed(2));

const useStyles = makeStyles(theme => ({
  '@keyframes btnIn': {
    '0%': {
      transform: `rotate(0deg)`,
    },
  },
  '@keyframes btnOut': {
    '0%': {
      transform: `rotate(90deg)`,
    },
  },
  '@keyframes drawerIn': {
    '0%': {
      transform: 'rotate(45deg) scale(1)',
      backgroundColor: 'rgba(234, 235, 237, 0.4)',
      opacity: 1,
    },
  },
  '@keyframes drawerOut': {
    '0%': {
      top: -buttonSize,
      left: -buttonSize,
      height: buttonSizeDouble,
      width: buttonSizeDouble,
      transform: `rotate(45deg) scale(${scaleRatio})`,
      backgroundColor: '#555',
      opacity: 0.95,
    },
  },
  '@keyframes drawerRightIn': {
    '0%': {
      top: getTopPosition(-buttonSize),
      left: -buttonSize,
      height: buttonSizeDouble,
      width: buttonSizeDouble,
      transform: `rotate(45deg) scale(${scaleRatio})`,
      backgroundColor: '#555',
      opacity: 0.95,
    },
  },
  '@keyframes drawerRightOut': {
    '0%': {
      transform: 'rotate(45deg) scale(1)',
      backgroundColor: 'rgba(234, 235, 237, 0.4)',
      opacity: 1,
    },
  },
  '@keyframes rDrawerIn': {
    '0%': {
      transform: 'rotate(45deg) scale(0)',
      opacity: 1,
    },
  },
  '@keyframes rDrawerOut': {
    '0%': {
      bottom: -110,
      right: -110,
      height: 220,
      width: 220,
      transform: `rotate(45deg) scale(1)`,
      opacity: 0.95,
    },
  },
  '@keyframes rDrawerRightIn': {
    '0%': {
      bottom: -110,
      right: -110,
      height: 220,
      width: 220,
      transform: `rotate(45deg) scale(1)`,
      opacity: 0.95,
    },
  },
  '@keyframes rDrawerRightOut': {
    '0%': {
      bottom: -110,
      right: -110,
      height: 220,
      width: 220,
      transform: `rotate(45deg) scale(${rightScaleRatio})`,
      opacity: 1,
      zIndex: 999,
    },
  },
  btnInit: {
    fontSize: 30,
    top: 11,
    left: 13,
    padding: 0,
    position: 'absolute',
    zIndex: 1002,
    borderRadius: 0,
  },
  btnOut: {
    fontSize: 30,
    top: 11,
    left: 13,
    padding: 0,
    position: 'absolute',
    zIndex: 1002,
    animation: '$btnOut 0.1s linear',
    borderRadius: 0,
  },
  btnIn: {
    fontSize: 30,
    top: ({getTopPosition}) => (getTopPosition(11)),
    left: 13,
    padding: 0,
    position: 'fixed',
    zIndex: 1002,
    transform: 'rotate(90deg)',
    animation: '$btnIn 0.1s linear',
    borderRadius: 0,
  },
  btnRightIn: {
    fontSize: 30,
    top: ({getTopPosition}) => (getTopPosition(11)),
    left: 13,
    padding: 0,
    position: 'fixed',
    zIndex: 1002,
    animation: '$btnOut 0.1s linear',
    borderRadius: 0,
  },
  btnRightOut: {
    fontSize: 30,
    top: ({getTopPosition}) => (getTopPosition(11)),
    left: 13,
    padding: 0,
    position: 'fixed',
    zIndex: 1002,
    transform: 'rotate(90deg)',
    animation: '$btnIn 0.1s linear',
    borderRadius: 0,
  },
  drawerInit: {
    top: ({buttonSize}) => (-buttonSize),
    left: ({buttonSize}) => (-buttonSize),
    height: ({buttonSizeDouble, config}) => (config.getWindowInnerWidth() > 500 ? buttonSizeDouble / 4 : buttonSizeDouble),
    width: ({buttonSizeDouble, config}) => (config.getWindowInnerWidth() > 500 ? buttonSizeDouble / 4 : buttonSizeDouble),
    // backgroundColor: 'rgba(234, 235, 237, 0.4)',
    backgroundColor: theme.palette.primary[500],
    position: 'absolute',
    zIndex: 1000,
    transform: 'rotate(45deg) scale(1)',
  },
  drawerIn: {
    top: ({buttonSize, getTopPosition}) => (getTopPosition(-buttonSize)),
    left: ({buttonSize}) => (-buttonSize),
    height: ({buttonSizeDouble}) => (buttonSizeDouble),
    width: ({buttonSizeDouble}) => (buttonSizeDouble),
    backgroundColor: '#555',
    position: 'fixed',
    zIndex: 1000,
    transform: ({scaleRatio}) => (`rotate(45deg) scale(${scaleRatio})`),
    animation: '$drawerIn 0.1s linear',
    opacity: 0.95,
  },
  drawerOut: {
    // background: 'linear-gradient(135deg, rgba(234, 235, 237, 0.4) 59px, transparent 0)',
    top: ({buttonSize}) => (-buttonSize),
    left: ({buttonSize}) => (-buttonSize),
    height: ({buttonSizeDouble, config}) => (config.getWindowInnerWidth() > 500 ? buttonSizeDouble / 4 : buttonSizeDouble),
    width: ({buttonSizeDouble, config}) => (config.getWindowInnerWidth() > 500 ? buttonSizeDouble / 4 : buttonSizeDouble),
    // backgroundColor: 'rgba(234, 235, 237, 0.4)',
    backgroundColor: theme.palette.primary[500],
    position: 'absolute',
    zIndex: 1000,
    transform: 'rotate(45deg) scale(1)',
    animation: '$drawerOut 0.1s linear',
    opacity: 1,
  },
  drawerRightIn: {
    // background: 'linear-gradient(135deg, rgba(234, 235, 237, 0.4) 59px, transparent 0)',
    top: ({buttonSize, getTopPosition}) => (getTopPosition(-buttonSize)),
    left: ({buttonSize}) => (-buttonSize),
    height: ({buttonSizeDouble}) => (buttonSizeDouble),
    width: ({buttonSizeDouble}) => (buttonSizeDouble),
    backgroundColor: '#555',
    position: 'fixed',
    zIndex: 1000,
    transform: 'rotate(45deg) scale(1)',
    animation: '$drawerRightIn 0.15s linear',
    opacity: 1,
  },
  drawerRightOut: {
    top: ({buttonSize, getTopPosition}) => (getTopPosition(-buttonSize)),
    left: ({buttonSize}) => (-buttonSize),
    height: ({buttonSizeDouble}) => (buttonSizeDouble),
    width: ({buttonSizeDouble}) => (buttonSizeDouble),
    backgroundColor: '#555',
    position: 'fixed',
    zIndex: 1000,
    transform: ({scaleRatio}) => (`rotate(45deg) scale(${scaleRatio})`),
    animation: '$drawerIn 0.1s linear',
    opacity: 0.95,
  },
  rDrawerInit: {
    bottom: -110,
    right: -110,
    height: 220,
    width: 220,
    position: 'fixed',
    zIndex: 1101, // Menu has zIndex as 1100, so the right drawer should be higher than that.
    transform: 'rotate(45deg) scale(0)',
  },
  rDrawerOut: {
    bottom: -110,
    right: -110,
    height: 220,
    width: 220,
    position: 'fixed',
    zIndex: 1101,
    transform: 'rotate(45deg) scale(0)',
    animation: '$rDrawerOut 0.1s linear',
    opacity: 1,
  },
  rDrawerIn: {
    bottom: -110,
    right: -110,
    height: 220,
    width: 220,
    position: 'fixed',
    zIndex: 1101,
    transform: `rotate(45deg) scale(1)`,
    animation: '$rDrawerIn 0.1s linear',
    opacity: 1,
  },
  rDrawerRightIn: {
    bottom: -110,
    right: -110,
    height: 220,
    width: 220,
    position: 'fixed',
    zIndex: 999,
    transform: ({rightScaleRatio}) => (`rotate(45deg) scale(${rightScaleRatio})`),
    animation: '$rDrawerRightIn 0.1s linear',
    opacity: 1,
  },
  rDrawerRightOut: {
    bottom: -110,
    right: -110,
    height: 220,
    width: 220,
    position: 'fixed',
    zIndex: 1101,
    transform: `rotate(45deg) scale(1)`,
    animation: '$rDrawerRightOut 0.15s linear',
    opacity: 1,
  },
  // [theme.breakpoints.up(767)]: {
  //   inRB: {
  //     height: _len,
  //     width: _len,
  //     opacity: 1,
  //     position: 'fixed',
  //     zIndex: 103,
  //     left: 100,
  //     top: _height - 560 + 0.5 * _width,
  //     transform: 'rotate(-45deg)',
  //     animation: 'slashDownPad 0.1s linear',
  //   },
}));

export const SHUTTER_STATE = {
  INIT: 0,
  IN: 1,
  OUT: 2,
  RIGHT_IN: 4,
  RIGHT_OUT: 5,
};

function ShutterModal({
  children,
  DrawerButtonIcon = (
    <MenuIcon
      style={{
        fontSize: 30,
        color: '#ffffff',
      }}
    />
  ),
  onStateUpdate = () => {},
  RightDrawerProps = {},
  currentState = SHUTTER_STATE.INIT,
  renderRightDrawerChildren = (shutterState) => {},
}) {

  const config = useConfig();
  const windowInnerHeight = config.getWindowInnerHeight();
  const buttonSize = 65;
  const buttonSizeDouble = getWindowInnerWidth() <= 500 ? buttonSize * 2 : buttonSize * 8;
  const scaleRatio = Number((Math.sqrt(Math.pow(windowInnerHeight - 165, 2) * 2) / buttonSize).toFixed(2));
  const rightScaleRatio = Number((Math.sqrt(Math.pow(windowInnerHeight - 150, 2) * 2) / 110).toFixed(2));
  const getTopPosition = config.getTopPosition;
  // const theme = useTheme();

  const classes = useStyles({
    windowInnerHeight,
    buttonSize,
    buttonSizeDouble,
    scaleRatio,
    rightScaleRatio,
    getTopPosition,
    config,
  });

  function toggle() {
    if (currentState === SHUTTER_STATE.INIT || currentState === SHUTTER_STATE.OUT) {
      onStateUpdate(currentState, SHUTTER_STATE.IN);
    } else {
      if (currentState === SHUTTER_STATE.RIGHT_IN) {
        onStateUpdate(currentState, SHUTTER_STATE.RIGHT_OUT);
      } else {
        onStateUpdate(currentState, SHUTTER_STATE.OUT);
      }
    }
  }

  function getButtonClass() {
    switch (currentState) {
      case SHUTTER_STATE.INIT:
        return classes.btnInit;
      case SHUTTER_STATE.IN:
        return classes.btnIn;
      case SHUTTER_STATE.OUT:
        return classes.btnOut;
      case SHUTTER_STATE.RIGHT_IN:
        return classes.btnRightIn;
      case SHUTTER_STATE.RIGHT_OUT:
        return classes.btnRightOut;
      default:
        return classes.btnInit;
    }
  }

  function getDrawerClass() {
    switch (currentState) {
      case SHUTTER_STATE.INIT:
        return classes.drawerInit;
      case SHUTTER_STATE.IN:
        return classes.drawerIn;
      case SHUTTER_STATE.OUT:
        return classes.drawerOut;
      case SHUTTER_STATE.RIGHT_IN:
        return classes.drawerRightIn;
      case SHUTTER_STATE.RIGHT_OUT:
        return classes.drawerRightOut;
      default:
        return classes.drawerInit;
    }
  }

  function getRightDrawerClass() {
    switch (currentState) {
      case SHUTTER_STATE.INIT:
        return classes.rDrawerInit;
      case SHUTTER_STATE.IN:
        return classes.rDrawerIn;
      case SHUTTER_STATE.OUT:
        return classes.rDrawerOut;
      case SHUTTER_STATE.RIGHT_IN:
        return classes.rDrawerRightIn;
      case SHUTTER_STATE.RIGHT_OUT:
        return classes.rDrawerRightOut;
      default:
        return classes.rDrawerInit;
    }
  }
  
  return (
    <Fragment>
      <div className={getDrawerClass()}/>
      <IconButton
        aria-label="Side Menu"
        className={getButtonClass()}
        onClick={toggle}
      >
        {DrawerButtonIcon}
      </IconButton>
      {children}
      <div className={getRightDrawerClass()} {...RightDrawerProps}/>
      {
        renderRightDrawerChildren(currentState)
      }
    </Fragment>
  );
}

ShutterModal.propTypes = {
  DrawerButtonIcon: TObject,
  // onStateUpdate: TFunction.isRequired,
  onStateUpdate: TFunction,
  currentState: TNumber,
  RightDrawerProps: TObject,
  renderRightDrawerChildren: TFunction,
};

function mapStateToProps(state) {
  return {
  }
}

export default connect(mapStateToProps)(ShutterModal);
