import React, {useCallback, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import FullWidthImage from 'lib/Core/FullWidthImage';
import Typography from '@material-ui/core/Typography';
import ContentAlignedBox from 'component/ContentAlignedBox';
import truncateString from 'logic/utils/truncateString';
import {icon16} from 'component/Icon';
import LocationIcon from 'lib/Icon/Location';
// import CallIcon from 'lib/Icon/Call';
import RewardBasicCard from 'component/Card/RewardBasicCard';
import config from 'config';
import theme from 'theme';
import {isIOS, isAndroid} from 'logic/utils/platform';
import makePhoneCall from 'lib/Core/utils/makePhoneCall';

const useStyles = makeStyles(theme => ({
  card: {
    // flex: 1,
    // flexDirection: 'column',
    marginBottom: 5,
    width: config.getWindowInnerWidth() - 16,
  },
  des: {
    paddingTop: 8,
    marginLeft: 8,
    marginRight: 8,
  },
  flex: {
    flexDirection: 'row',
  },
  rt: {
    color: '#E57373',
    marginRight: 8,
  },
  content: {
    flex: 1,
    flexDirection: 'row',
    alignSelf: 'flex-start',
    marginTop: 8,
    // alignItems: 'flex-start',
  },
  icon: {
    marginRight: 4,
    height: 20,
    paddingTop: 2,
  },
}));

function StoreInfo({
  data,
}) {
  const classes = useStyles();
  
  let cover = data.logo;
  if (data.media.length > 0) {
    cover = data.media[0].media;
  }

  const [hasGeo] = useState(() => {
    const {latitude, longitude, address} = data;
    return !!(latitude && longitude && address);
  });

  function openMap() {
    const {latitude, longitude, name} = data;
    let url = null;
    if (isIOS()) {
      url = `http://maps.apple.com/?q=${name}&ll=${latitude}>,${longitude}`;
    } else if (isAndroid()) {
      url = `geo:0,0?q=${latitude},${longitude}(${name})`;
    }
    if (url !== null) {
      window.open(url);  
    }
  }

  const renderTags = useCallback(
    () => {
      return (
        <div className={classes.flex}>
          {data.tags.map((tag, index) => {
            return (<Typography key={`${index}.${tag.label}` } variant='body2' color='textSecondary'>{tag.label} </Typography>);
          })}
        </div>
      );
    },
    []
  );

  const renderContent = useCallback(
    (icon, content) => {
      return (
        <div className={classes.content}>
          <Typography variant='body2' color='textSecondary'>{content}</Typography>
        </div>
      );
    },
    []
  );

  return (
    <RewardBasicCard
      enableBottomLeftButton={!!data.contactNumber}
      bottomLeftButtonLabel={`Call ${data.contactNumber}`}
      bottomLeftButtonOnClick={() => {makePhoneCall(data.contactNumber)}}
      enableBottomRightButton={hasGeo}
      bottomRightButtonOnClick={openMap}
      bottomRightButtonLabel={'Map'}
    >
      <div className={classes.card} style={{marginBottom: hasGeo ? 0 : 16}}>
        <FullWidthImage path={cover} margin={20} aspectRatio={9/16} style={{borderTopRightRadius: 10, borderTopLeftRadius: 10}}/>
        <div className={classes.des}>
          <ContentAlignedBox
            // style={{}}
            variant={'hEndAligned'}
            leftEnd={
              <Typography variant='body2'>{truncateString(data.name, 25)}</Typography>
            }
            rightEnd={
              renderTags()
            }
          />
          {!!data.secondaryName && (<Typography variant='body2' color='textSecondary'>{data.secondaryName}</Typography>)}
          {!!data.address && renderContent(icon16(LocationIcon, theme.design.reward.dark), data.address)}
          {/*{!!data.contactNumber && renderContent(icon16(CallIcon, {color: theme.design.reward.dark, width: 16, height: 16}), data.contactNumber)}*/}
        </div>
      </div>
    </RewardBasicCard>
  );
}

StoreInfo.propTypes = {
};

export default StoreInfo;
