import React from 'react';
import {makeStyles} from '@material-ui/styles';
import withPageControl, {TPageControl} from '../HOC/withPageControl';
import {getTopPosition, getWindowSafeInnerHeight} from 'viewport';

const useStyles = makeStyles({
  '@keyframes menuIn': {
    '0%': {
      opacity: 0,
    },
    '30%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  '@keyframes menuOut': {
    '0%': {
      zIndex: 1100,
      opacity: 1,
    },
    '50%': {
      zIndex: -1,
      opacity: 0,
    },
    '100%': {
      zIndex: -1,
      opacity: 0,
    },
  },
  rootIn: {
    zIndex: 1100,
    position: 'fixed',
    top: getTopPosition(60),
    height: getWindowSafeInnerHeight(),
    width: '100%',
    opacity: 1,
    animation: '$menuIn 0.3s linear',
  },
  rootOut: {
    position: 'fixed',
    top: getTopPosition(60),
    height: getWindowSafeInnerHeight(),
    width: '100%',
    opacity: 0,
    animation: '$menuOut 0.2s forwards linear',
  },
});

function Menu({children, pageControl, location, visible}) {
  const classes = useStyles();

  return (
    <div className={visible ? classes.rootIn : classes.rootOut}>
      {children}
    </div>
  );
}

Menu.propTypes = {
  pageControl: TPageControl.isRequired,
};

export default withPageControl(Menu);
