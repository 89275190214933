import React from 'react';
import {connect} from 'react-redux';
import DeferredLoadingContainer from '../DeferredLoadingContainer';
import {TLoadingTransition, globalFlag} from 'logic/redux/state/loading_transition';
import {TNode, TBool, TObject, TString, TArray, declareTypes} from 'lib/Core/prop_types';
import isIntersected from 'lib/Core/utils/isIntersected';

function AsyncContainer({
  children,
  loadingTransition,
  disableChildrenRemoval = false,
  Placeholder = <DeferredLoadingContainer/>,
  alias = globalFlag,
}) {
  if (isIntersected(alias, loadingTransition.on)) {
    if (disableChildrenRemoval) {
      return (
        <>
          {children}
          {Placeholder}
        </>
      );
    } else {
      return Placeholder;
    }
  } else {
    return children;
  }
}

AsyncContainer.propTypes = {
  disableChildrenRemoval: TBool,
  loadingTransition: TLoadingTransition.isRequired,
  Placeholder: TObject,
  children: TNode.isRequired,
  alias: declareTypes([TString, TArray]),
};

function mapStateToProps(state) {
  return {
    loadingTransition: state.loadingTransition,
  }
}

export default connect(mapStateToProps)(AsyncContainer);