import React from 'react';
import {TNumber} from 'lib/Core/prop_types';
import Standard from './Standard';
import generateSvgDefId from 'logic/utils/generateSvgDefId';

const defId = generateSvgDefId();

const LoadingHead = ({ scale, minX = 0, minY = 0 }) => {
  return (
    <Standard
      scale={scale}
      viewBox={{
        minX,
        minY,
        width: 230.1,
        height: 100.49,
      }}
    >
      <defs>
        <linearGradient id={defId} x1='-281.04' y1='277.98' x2='-280.32'
                        y2='277.44' gradientTransform='matrix(-115.91 10.14 -10.64 -121.62 -29478.97 36685.04)'
                        gradientUnits='userSpaceOnUse'>
          <stop offset='0' stopColor='#fff4bb' />
          <stop offset='1' stopColor='#ffe686' />
        </linearGradient>
      </defs>
      <path id='Fill-1' d='M61,2.88a2.34,2.34,0,0,1,.8-1.8,2.66,2.66,0,0,1,3.7,0l99.5,99.4-103.9.1Z'
            fill={`url(#${defId})`} />
      <path id='Fill-35' d='M122.8,86.78a3.33,3.33,0,0,1-3.3-3.3V72.88h0a3.33,3.33,0,0,1,3.3-3.3h.5a3.33,3.33,0,0,1,3.3,3.3v10.6a3.33,3.33,0,0,1-3.3,3.3h-.5'
            fill='#58595b' />
      <path id='Fill-37' d='M98.2,86.78a3.33,3.33,0,0,1-3.3-3.3V72.88a3.33,3.33,0,0,1,3.3-3.3h.5a3.33,3.33,0,0,1,3.3,3.3v10.6a3.33,3.33,0,0,1-3.3,3.3h-.5'
            fill='#58595b' />
      <path id='Fill-48' d='M111.4,96.08h-1.1c-5.8,0-7.2-3.6-7.3-3.7a1,1,0,0,1,.5-1.1.81.81,0,0,1,1.1.5c0,.1,1.2,2.6,5.7,2.6s5.7-2.5,5.7-2.6a1,1,0,0,1,1.1-.5.89.89,0,0,1,.5,1.1c-.1.1-1.4,3.2-6.2,3.7'
            fill='#58595b' />
    </Standard>
  );
};

LoadingHead.propTypes = {
  scale: TNumber.isRequired,
};

export default LoadingHead;
