import React from 'react';
import useConfig from '../hook/useConfig';
import Image from '../Image';
import {TFunction, TNumber, TObject, TString} from '../prop_types';

function FullWidthImage({margin = 0, ...others}) {
  const {getWindowInnerWidth} = useConfig();
  const width = getWindowInnerWidth() - margin;
  return (<Image {...others} width={width}/>);
}

FullWidthImage.propTypes = {
  margin: TNumber,
  path: TString,
  style: TObject,
  variant: TNumber,
  aspectRatio: TNumber,
  rotate: TNumber,
  alt: TString,
  onLoad: TFunction,
  onError: TFunction,
  onClick: TFunction,
  renderErrorPlaceholder: TFunction,
};

export default FullWidthImage;
