import React from 'react';
import {connect} from 'react-redux';
import {useTheme} from '@material-ui/styles';
import Drawer from './Drawer';
import {URI} from 'page/Layout/uri';
import MenuItem from './MenuItem';
import {
  Person as PersonIcon,
  Group as GroupIcon,
  CreditCard as CreditCardIcon,
  // Help as HelpIcon,
} from '@material-ui/icons';
import AccountBlock from './AccountBlock';
// import IdCheckBlock from './IdCheckBlock';
// import {idCheckState} from 'logic/redux/const';
// import {MenuWealthSVG} from '../SVG';

export const WEALTH_MENU_ITEM_ID = {
  PROFILE: 1,
  FRIEND: 2,
  WALLET: 3,
  // HELP: 4,
};

function WealthSideMenu({
  activeItem,
}) {
  const theme = useTheme();

  const highlightColor = theme.design.wealth.dark;
  const defaultColor = '#FFFFFF';

  return (
    <Drawer
      themeColorDark={highlightColor}
      themeColorLight={theme.design.wealth.light}
      // DrawerButtonIcon={(<MenuWealthSVG scale={0.45} />)}
      // renderDrawerButton={(openDrawer) => {
      //   return (
      //     <DrawerButton
      //       onClick={openDrawer}
      //       Icon={(<MenuWealthSVG scale={0.45} />)}
      //       // style={{backgroundImage: theme.design.wealth.triangle}}
      //     />
      //   );
      // }}
      renderTop={(redirectToPage) => {
        return (
          <React.Fragment>
            <AccountBlock onClick={redirectToPage}/>
            {/*{customerIDCheck.state !== idCheckState.STATE_READY_TO_VERIFY && (<IdCheckBlock onClick={redirectToPage}/>)}*/}
          </React.Fragment>
        );
      }}
      renderMenuList={(onMenuItemClick) => {
        return (
          <div>
            <MenuItem
              id={WEALTH_MENU_ITEM_ID.PROFILE}
              onClick={onMenuItemClick(URI.ACCOUNT)}
              label={'Profile'}
              highlight={(activeItem === WEALTH_MENU_ITEM_ID.PROFILE)}
              highlightColor={highlightColor}
              color={defaultColor}
              Icon={(<PersonIcon nativeColor={activeItem === WEALTH_MENU_ITEM_ID.PROFILE ? highlightColor : defaultColor}/>)}
            />
            <MenuItem
              id={WEALTH_MENU_ITEM_ID.FRIEND}
              onClick={onMenuItemClick(URI.FRIEND_LIST)}
              label={'Friends'}
              highlight={(activeItem === WEALTH_MENU_ITEM_ID.FRIEND)}
              highlightColor={highlightColor}
              color={defaultColor}
              Icon={(<GroupIcon nativeColor={activeItem === WEALTH_MENU_ITEM_ID.FRIEND ? highlightColor : defaultColor}/>)}
            />
            <MenuItem
              id={WEALTH_MENU_ITEM_ID.WALLET}
              onClick={onMenuItemClick(URI.CUSTOMER_WALLET_HOME)}
              label={'Wallet'}
              highlight={(activeItem === WEALTH_MENU_ITEM_ID.WALLET)}
              highlightColor={highlightColor}
              color={defaultColor}
              Icon={(<CreditCardIcon nativeColor={activeItem === WEALTH_MENU_ITEM_ID.WALLET ? highlightColor : defaultColor}/>)}
            />
            {/*<MenuItem*/}
              {/*id={WEALTH_MENU_ITEM_ID.HELP}*/}
              {/*onClick={onMenuItemClick(URI.CUSTOMER_TUTORIAL_WEALTH)}*/}
              {/*label={'Help'}*/}
              {/*highlight={(activeItem === WEALTH_MENU_ITEM_ID.HELP)}*/}
              {/*highlightColor={highlightColor}*/}
              {/*color={defaultColor}*/}
              {/*Icon={(<HelpIcon nativeColor={activeItem === WEALTH_MENU_ITEM_ID.HELP ? highlightColor : defaultColor}/>)}*/}
            {/*/>*/}
          </div>
        )
      }}
    />
  );
}

WealthSideMenu.propTypes = {
};

function mapStateToProps(state) {
  return {
  }
}

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(WealthSideMenu);
