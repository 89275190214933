import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
  makeStyles,
} from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import {isAndroid} from 'logic/utils/platform';
import PlainCard from 'component/Card/PlainCard';
import usePageNavigator, {pages} from 'page/Layout/pages';
import getS3ImagePath from 'logic/utils/getS3ImagePath';
import Image from 'component/Image';
import {getWindowInnerWidth, getCardMaxWidth} from 'viewport';
import AppDownloadIcon from 'component/AppDownloadIcon';
import theme from 'theme';

const useStyles = makeStyles(theme => ({
  cardTop: {
    padding: 20,
    alignItems: 'center',
    marginBottom: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  card: {
    padding: 20,
    alignItems: 'center',
    // marginTop: 8,
  },
  actionBarRow: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginBottom: 15,
  },
  actionBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexGrow: 1,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    paddingTop: 16,
    paddingBottom: 16,
    maxWidth: getCardMaxWidth(),
    // marginLeft: 'auto',
    // marginRight: 'auto',
  },
}));

const imageWith = (Math.min(getWindowInnerWidth(), 380) - 60) * 0.8;
const imageWithSmall = (Math.min(getWindowInnerWidth(), 380) - 60) * 0.7;

function Welcome() {
  const classes = useStyles();

  return (
    <>
      <PlainCard
        className={classes.cardTop}
      >
        <Image path={getS3ImagePath('/register/reg_success.svg')} width={imageWith} aspectRatio={238/310} alt={'Tanggram-logo'}/>
        <Typography variant='h6' style={{marginTop: 32,}}>Check your rewards!</Typography>
        <Typography color='textSecondary' style={{textAlign: 'left'}}>Simply download the app and check the rewards we've sent to you!</Typography>
      </PlainCard>
      <div className={classes.actionBarRow}>
        <div className={classes.actionBar} style={{backgroundColor: theme.design.mainYellow}}>
          <AppDownloadIcon/>
        </div>
      </div>
      <PlainCard
        className={classes.card}
        style={{backgroundColor: theme.design.wealth.fab}}
      >
        <Image path={getS3ImagePath('/register/invest.png')} width={imageWithSmall} aspectRatio={752/809} alt={'Tanggram-logo'}/>
        <Typography variant='h6' style={{marginTop: 32, color: '#FFFFFF'}}></Typography>
        <Typography color='textSecondary' style={{textAlign: 'left', color: '#FFFFFF'}}></Typography>
      </PlainCard>
      <PlainCard
        className={classes.card}
        style={{backgroundColor: theme.design.reward.text}}
      >
        <Image path={getS3ImagePath('/register/rewards.png')} width={imageWithSmall} aspectRatio={752/809} alt={'Tanggram-logo'}/>
        <Typography variant='h6' style={{marginTop: 32, color: '#FFFFFF'}}>Boost your investments</Typography>
        <Typography style={{textAlign: 'left', color: '#FFFFFF'}}>Use your shopping rewards to accelerate your wealth.</Typography>
      </PlainCard>
      <PlainCard
        className={classes.card}
        style={{backgroundColor: theme.design.center.text}}
      >
        <Image path={getS3ImagePath('/register/refer.png')} width={imageWithSmall} aspectRatio={752/809} alt={'Tanggram-logo'}/>
        <Typography variant='h6' style={{marginTop: 32, color: '#FFFFFF'}}>Refer friends to earn more</Typography>
        <Typography color='textSecondary' style={{textAlign: 'left', color: '#FFFFFF'}}>Tell your mate to join and enjoy more rewards.</Typography>
      </PlainCard>
    </>
  );
}

Welcome.propTypes = {
};

export default Welcome;
