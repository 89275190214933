import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
  makeStyles,
} from '@material-ui/styles';
import QRCode from 'qrcode.react';
import Typography from '@material-ui/core/Typography';
import {isAndroid} from 'logic/utils/platform';
import AsyncContainer from 'component/AsyncContainer';
import {actions as coffeeZeroVoucherActions, coffeeZeroVoucherLoading} from 'logic/redux/state/coffee_zero_voucher';
import {encodeVoucherQRCode} from 'logic/service/qr_code';
import PlainCard from 'component/Card/PlainCard';
import {isIOS} from 'logic/utils/platform';
import StoreInfo from './StoreInfo';
import AppDownloadClickBait from 'component/AppDownloadClickBait';
// import usePageNavigator, {pages} from 'page/Layout/pages';
// import useConfig from 'lib/Core/hook/useConfig';
// import theme from 'theme';
// import PlainCard from 'component/Card/PlainCard';
// import isMobile from 'logic/utils/platform/isMobile';

const useStyles = makeStyles(theme => ({
  dia: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 16,
    paddingBottom: 16,
    alignItems: 'center',
  },
  title: {
    marginLeft: 8,
    marginRight: 8,
    marginBottom: 12,
  },
  text: {
    marginLeft: 8,
    marginRight: 8,
    // marginTop: 4,
    textAlign: 'center',
    // marginTop: 12,
    // marginBottom: 0,
  },
  t: {
    marginLeft: 8,
    marginRight: 8,
    marginTop: 4,
    // textAlign: 'center',
  },
  inner: {
    flex: 1,
    flexDirection: 'column',
  },
}));

function CoffeeZero() {
  const classes = useStyles();
  const userSession = useSelector(state => state.userSession);
  // const pageNavigator = usePageNavigator();
  // const config = useConfig();
  const coffeeZeroVoucher = useSelector(state => state.coffeeZeroVoucher);
  const dispatch = useDispatch();

  useEffect(
    () => {
      dispatch(coffeeZeroVoucherActions.load());
    },
    [
      coffeeZeroVoucherActions.load,
    ]
  );

  // console.log(coffeeZeroVoucher);
  // const theme = useTheme();
  // const [appPlace] = useState(isAndroid() ? ' Google Play ' : ' Apple Store ');
  const [appIcon] = useState(isAndroid() ? '/google_play_2.png' : '/apple_store_2.png');

  const {voucherTemplate = {}, vouchers = [], stores = []} = coffeeZeroVoucher;
  const numOfVouchers = vouchers.length;

  if (numOfVouchers > 0 
    && stores.length > 0 
    && voucherTemplate.id
  ) {
    // There must be at least one valid vouchers and one store that the vouchers can be used at.
    const firstVoucher = vouchers[0];
    return (
      <AsyncContainer alias={[coffeeZeroVoucherLoading]}>
        <div className={classes.inner}>
          <PlainCard className={classes.dia}>
            {/*<Typography color='textPrimary' className={classes.title} variant={'h6'}>{voucherTemplate.title}</Typography>*/}
            <div style={{paddingLeft: 8, paddingRight: 8, height: 176, width: 176, backgroundColor: '#fff'}}>
              <QRCode value={encodeVoucherQRCode(userSession.user.identity, firstVoucher.id)} color={'#000000'} size={160}/>
            </div>
            <Typography color='textSecondary' className={classes.text} variant={'body2'}>Show the QR Code in Store to redeem your voucher.</Typography>
            <Typography color='textSecondary' className={classes.t} variant={'body2'}>{voucherTemplate.description}</Typography>
            {/*<Typography color='textSecondary' className={classes.t}>{voucherTemplate.extra}</Typography>*/}
            <Typography color='textSecondary' className={classes.t} variant={'body2'}>Expire Date: {voucherTemplate.validToDate}</Typography>
          </PlainCard>
        </div>
        <div>
          {stores.map((store, index) => {
            return (
              <StoreInfo key={`${store.id}`} data={store}/>
            );
          })}
        </div>
        <div style={{height: isIOS() ? 75 : 60}}/>
      </AsyncContainer>
    );
  } else {
    return (
      <AsyncContainer alias={[coffeeZeroVoucherLoading]}>
        <div style={{paddingBottom: 60,}}>
          <AppDownloadClickBait/>
        </div>
      </AsyncContainer>
    );
  }
}

CoffeeZero.propTypes = {
};

export default CoffeeZero;
