// This will replace uri in the future.
import {useHistory, useLocation} from 'react-router';

export const pages = {
  SPLASH: 'splash',
  HOME: 'home',
  CUSTOMER_ACTIVATE: 'customer_activate',
  CUSTOMER_REGISTER: 'customer_register',
  WORLD_GATE: 'world_gate',
  MERCHANT_ENQUIRY_FORM: 'merchant_enquiry_form',
  COFFEE_ZERO_VOUCHER: 'coffee_zero_voucher',
  LOGIN: 'login',
  CAMPAIGN_NEW_YEAR_2021: 'campaign_new_year_2021',
  EMAIL_UNSUBSCRIBE: 'email_unsubscribe',
};

const pageInfo = {
  [pages.SPLASH]: {
    path: '/',
  },
  [pages.HOME]: {
    path: '/home',
  },
  [pages.CUSTOMER_ACTIVATE]: {
    path: '/customer/activate',
  },
  [pages.CUSTOMER_REGISTER]: {
    path: '/register',
  },
  [pages.WORLD_GATE]: {
    path: '/world-gate',
  },
  [pages.MERCHANT_ENQUIRY_FORM]: {
    path: '/merchant/enquiry',
  },
  [pages.COFFEE_ZERO_VOUCHER]: {
    path: '/coffee-zero/voucher',
  },
  [pages.LOGIN]: {
    path: '/login',
  },
  [pages.CAMPAIGN_NEW_YEAR_2021]: {
    path: '/campaign-new-year-2021',
  },
  [pages.EMAIL_UNSUBSCRIBE]: {
    path: '/email/unsubscribe',
  },
};

export function getPagePath(page) {
  if (pageInfo.hasOwnProperty(page)) {
    return pageInfo[page].path;
  }
  return null;
}

export default function usePageNavigator() {
  const history = useHistory();
  const location = useLocation();
  // Redirect to the uri.
  function redirectTo(page, options = {
    fromPage: null,
  }) {
    if (page) {
      // // Add to Browsing History
      // addToHistory(uri);

      // Save the referrer url if any.
      // if (options.fromUri) {
      //   saveFromPage(options.fromUri, page.path);
      // }
      const pagePath = getPagePath(page);
      if (pagePath) {
        if (pagePath === location.pathname) {
          history.replace(pagePath);
        } else {
          history.push(pagePath);
        }
      }
    }
  }

  function redirectToPrevious(options = {}) {
    history.goBack();
  }

  return {
    redirectTo,
    redirectToPrevious,
  }
}