import React from 'react';
import {makeStyles} from '@material-ui/styles';
import useConfig from 'lib/Core/hook/useConfig';
import withPageControl from '../HOC/withPageControl';
import {TNode, TObject} from 'lib/Core/prop_types';
import useTopBarVisibility from 'logic/hook/useTopBarVisibility';
import getImagePath from 'logic/utils/getImagePath';

export const height = 72;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    position: 'fixed',
    top: ({getTopPosition}) => (getTopPosition(0)),
    left: 0,
    height,
    backgroundColor: theme.design.background.light,
    paddingTop: 12,
    paddingBottom: 12,
    zIndex: 999,
  },
  rootInv: {
    width: '100%',
    position: 'fixed',
    top: ({getTopPosition}) => (getTopPosition(0)),
    left: 0,
    height: 56,
    backgroundColor: theme.design.background.topMenuItem,
    paddingTop: 12,
    paddingBottom: 12,
    zIndex: 999,
    display: 'none',
  },
  menu: {
    display: 'flex',
    width: '100%',
    paddingLeft: 2,
    justifyContent: 'flex-start',
  },
}));

/**
 * @returns {*}
 */
function Menu({
  children,
  style = null,
}) {
  const config = useConfig();
  const {getTopPosition} = config;
  const classes = useStyles({getTopPosition});

  const isVisibleFromScroll = useTopBarVisibility(true, 38);
  const rootClassName = isVisibleFromScroll ? classes.root : classes.rootInv;
  return (
    <div
      className={rootClassName}
      style={style}
    >
      <div className={classes.menu}>
        {children}
      </div>
    </div>
  );
}

Menu.propTypes = {
  children: TNode,
  style: TObject,
};

export default withPageControl(Menu);

const useLogoMenuStyles = makeStyles({
  topB: {
    position: 'absolute',
    top: ({getTopPosition}) => (getTopPosition(0)),
    left: 0,
    width: '100%',
  },
  topBInner: {
    width: '100%',
    height: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  topImg: {
    height: 36,
  },
});

export function LogoMenu() {
  const config = useConfig();
  const {getTopPosition} = config;
  const classes = useLogoMenuStyles({getTopPosition});
  return (
    <div className={classes.topB}>
      <div className={classes.topBInner}>
        <img className={classes.topImg} src={getImagePath('/asset/img/tanggram_logo_whole.png')} alt={'Tanggram Logo'}/>
      </div>
    </div>
  );
}
