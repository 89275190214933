import React, {useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import {
  AccountCircle as AccountCircleIcon,
  Storefront as StorefrontIcon,
} from '@material-ui/icons';
import TopMenuLayout, {Body} from 'component/TopMenuLayout';
import {useDimension} from './hooks';
import useConfig from 'lib/Core/hook/useConfig';
import {AccountSideMenu, ACCOUNT_MENU_ITEM_ID} from 'component/SideMenu';
import usePageNavigator, {pages} from 'page/Layout/pages';
import theme from 'theme';
import IconTitleMenuCard from 'component/Card/IconTitleMenuCard';
import {menuCardIcon} from 'component/Icon';
// import ColorCard from 'component/Card/ColorCard';

const useStyles = makeStyles(theme => ({
  pager: {
    // position: 'absolute',
    top: ({viewport}) => viewport.windowWidth * 0.7 + 130,
    width: ({viewport}) => viewport.windowWidth - 30,
    zIndex: 99,
  },
  slide: {
    backgroundColor: '#FFFFFF',
    minHeight: ({viewport}) => viewport.windowHeight - 105,
    borderRadius: 10,
    boxShadow: ({config}) => (config.card.raised ? theme.design.boxShadow : 'none'),
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
    justifyContent: 'space-between',
  },
  register: {

  },
  registerTitle: {
    marginTop: 16,
    marginLeft: 16,
  },
}));

function SplashPage() {
  const viewport = useDimension();
  const config = useConfig();
  const classes = useStyles({viewport, config});
  const pageNavigator = usePageNavigator();

  return (
    <TopMenuLayout
      title={'Welcome to Tanggram'}
      SideMenu={(<AccountSideMenu activeItem={ACCOUNT_MENU_ITEM_ID.HOME}/>)}
    >
      <Body>
        <IconTitleMenuCard
          transitionDelay={0}
          Icon={menuCardIcon(AccountCircleIcon, theme.design.center.dark)}
          title={'Register as a customer'}
          className={classes.body}
          colorDark={theme.design.default.dark}
          colorLight={theme.design.default.dark}
          bodyOffset={0}
          enableBottomRightButton={true}
          bottomRightButtonLabel={'Go'}
          bottomRightButtonOnClick={() => {pageNavigator.redirectTo(pages.CUSTOMER_REGISTER);}}
        >
          <Typography variant='body1'>Register an account here and download the Tanggram app to enjoy the full user experience.</Typography>
        </IconTitleMenuCard>
        <IconTitleMenuCard
          transitionDelay={100}
          Icon={menuCardIcon(StorefrontIcon, theme.design.reward.text)}
          title={'Join as a merchant'}
          className={classes.body}
          colorDark={theme.design.reward.text}
          colorLight={theme.design.reward.text}
          bodyOffset={0}
          enableBottomRightButton={true}
          bottomRightButtonLabel={'Go'}
          bottomRightButtonOnClick={() => {pageNavigator.redirectTo(pages.MERCHANT_ENQUIRY_FORM);}}
        >
          <Typography variant='body1'>Want to join the Tanggram family? Drop us a form to start.</Typography>
        </IconTitleMenuCard>
        {/*<ColorCard*/}
          {/*primaryColour={theme.design.reward.bg}*/}
          {/*primaryLabel={'Buy'}*/}
          {/*onPrimaryClick={() => {pageNavigator.redirectTo(pages.CUSTOMER_REGISTER);}}*/}
        {/*>*/}
          {/*<Typography variant={'subtitle1'} color={'#ffffff'}>Email To Verify</Typography>*/}
          {/*<Typography variant={'body1'} color={'#ffffff'} style={{marginTop: 12,}}>It is required to verify your email before you start to invest.</Typography>*/}
          {/*<Typography variant={'body1'} style={{marginTop: 8,}} color={'#ffffff'}>Failed to verify your email? Please tap 'Contact' to choose a way to contact us.</Typography>*/}
        {/*</ColorCard>*/}
      </Body>
    </TopMenuLayout>
  );
}

export default SplashPage;
