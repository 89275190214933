import React from 'react';
import {useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import {AccountBox as AccountBoxIcon} from '@material-ui/icons';
import {TFunction} from 'lib/Core/prop_types';
import getS3ImagePath from 'logic/utils/getS3ImagePath';
import truncateString from 'logic/utils/truncateString';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 24,
    marginBottom: 24,
  },
  t: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 16,
  },
  tc: {
    color: 'white',
  },
  accIcon: {
    // marginLeft: 5,
    padding: 0,
    color: '#ffffff',
    marginLeft: -3,
    marginTop: 5,
    marginRight: 5,
  },
  er: {
    display: 'flex',
    paddingRight: 16,
  },
});

function AccountBlock({
  onClick,
  style
}) {
  const classes = useStyles();
  const userSession = useSelector(state => state.userSession);

  const {user} = userSession;
  
  function gotoAccount() {
    // onClick(URI.ACCOUNT);
  }

  const _style = style ? style : null;

  if (userSession.isLoggedIn) {
    return (
      <div className={classes.root} style={_style}>
        <Avatar src={user.avatar ? user.avatar : getS3ImagePath('/default_avatar.png')} style={{height: 72, width: 72}}/>
        <div className={classes.t} onClick={gotoAccount}>
          {/*<Typography variant='h5' className={classes.tc}>{`${user.firstName} ${user.middleName} ${user.lastName}`}</Typography>*/}
          <div className={classes.er}>
            <AccountBoxIcon className={classes.accIcon} onClick={gotoAccount}/>
            <Typography variant='h6' className={classes.tc}>{user.nickname}</Typography>
          </div>
          <Typography variant='body1' className={classes.tc}>{`ID: ${user.identity}`}</Typography>
          <Typography variant='body1' className={classes.tc}>{truncateString(user.email, 28)}</Typography>
        </div>
      </div>
    );
  } else {
    return (<div/>);
  }
}

AccountBlock.propTypes = {
  onClick: TFunction.isRequired,
};

export default AccountBlock;
