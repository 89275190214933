import React from 'react';
import {makeStyles} from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import withAcl from 'component/HOC/withAcl';
import Body from 'component/NavLayout/Body';
import TopMenuLayout from 'component/TopMenuLayout';
import Home from './Home';
// import CoffeeZero from './CoffeeZero';
import Welcome from './Welcome';
import AccountSideMenu, {ACCOUNT_MENU_ITEM_ID} from 'component/SideMenu/AccountSideMenu';
// import AppDownloadClickBait from 'component/AppDownloadClickBait';
// import AppDownloadIcon from 'component/AppDownloadIcon';
// import Image from 'component/Image';
// import getS3ImagePath from 'logic/utils/getS3ImagePath';

const useStyles = makeStyles(theme => ({
  body: {
    paddingBottom: 60,  
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexGrow: 1
  },
}));

/**
 * @returns {null}
 */
function HomePage() {
  const classes = useStyles();

  return (
    <TopMenuLayout
      title={'Home'}
      // BackButton={(<DefaultBackButton/>)}
      SideMenu={(<AccountSideMenu activeItem={ACCOUNT_MENU_ITEM_ID.HOME}/>)}
    >
      <div className={classes.body}>
        <Body>
          {/* <CoffeeZero/> */}
          <Welcome/>
          {/* <AppDownloadClickBait/> */}
        </Body>
      </div>
    </TopMenuLayout>
  );
}

export default withAcl(HomePage);
