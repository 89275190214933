export default function includeConstraints(constraints) {
  return function (name, val) {
    if (constraints.hasOwnProperty(name)) {
      return constraints[name](val);
    }
    return {
      isPassed: true,
      val,
    }
  };
}
