import React, {useState} from 'react';
import useConfig from 'lib/Core/hook/useConfig';
import {TString, TNumber, TObject, TFunction} from 'lib/Core/prop_types';
import getImagePath from 'logic/utils/getImagePath';
import ImagePlaceholder from './ImagePlaceholder';

const TYPE = {
  PRE_OPACITY: 1,
  PRE_BLUR: 2,
  STATIC: 9,
};

function getPreLoadStyle(variant) {
  switch (variant) {
    case TYPE.PRE_BLUR:
      return {
        filter: 'blur(4px)',
      };
    case TYPE.PRE_OPACITY:
      return {
        opacity: 0.3,
        // backgroundColor: '#CFCFCF',
      };
    case TYPE.STATIC:
    default:
      return {};
  }
}

function Image({
  path,
  width,
  style = {},
  variant = TYPE.PRE_OPACITY,
  aspectRatio = 1,
  rotate = 0,
  alt = 'icon',
  onLoad = () => {},
  onError = () => {},
  onClick = () => {},
  renderErrorPlaceholder = (width, height) => {
    return (<ImagePlaceholder width={width} height={height}/>);
  }
  // defaultImagePath = null,
}) {
  const height = Math.round(width * aspectRatio);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isBroken, setIsBroken] = useState(false);

  function _onLoad() {
    setIsLoaded(true);
    onLoad();
  }

  function _onError() {
    setIsBroken(true);
    onError();
  }

  const _defaultStyle = {
    transform: `rotate(${rotate}deg)`,
  };
  const _style = isLoaded ? {..._defaultStyle, ...style} : {..._defaultStyle, ...getPreLoadStyle(variant)};
  const src = getImagePath(path);
  if (path && !isBroken) {
    return (
      <img src={src} width={width} height={height} alt={alt} style={_style} onClick={onClick} onLoad={_onLoad} onError={_onError}/>
    );
  } else {
    return renderErrorPlaceholder(width, height);
  }
}

Image.propTypes = {
  path: TString,
  style: TObject,
  variant: TNumber,
  width: TNumber.isRequired,
  aspectRatio: TNumber,
  alt: TString,
  onClick: TFunction,
};

export default Image;

export function FullWidthImage({margin = 0, ...others}) {
  const {getWindowInnerWidth} = useConfig();
  const width = getWindowInnerWidth() - margin;
  return (<Image {...others} width={width}/>);
}
