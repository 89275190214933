import React from 'react';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles({
  box: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row-reverse',
  },
  flex: {
    flexGrow: 1,
  },
});

function RightAlignedBox({children, style}) {
  const classes = useStyles();

  let customStyle = null;
  if (style !== undefined) {
    customStyle = style;
  }
  return (
    <div style={customStyle} className={classes.box}>
      {children}
      <div className={classes.flex}/>
    </div>
  );
}

RightAlignedBox.propTypes = {
};

export default RightAlignedBox;
