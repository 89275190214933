import React from 'react';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles({
  box: {
    display: 'flex',
    flexGrow: 1,
  },
  flex: {
    flexGrow: 1,
  },
});

function HEndAlignedBox({leftEnd, rightEnd, style}) {
  const classes = useStyles();

  let customStyle = null;
  if (style !== undefined) {
    customStyle = style;
  }
  return (
    <div style={customStyle} className={classes.box}>
      {leftEnd}
      <div className={classes.flex}/>
      {rightEnd}
    </div>
  );
}

HEndAlignedBox.propTypes = {
};

export default HEndAlignedBox;
