import React from 'react';
import {makeStyles} from '@material-ui/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import {TString, TNumber, TNode, TBool} from 'lib/Core/prop_types';
import withPageControl from '../HOC/withPageControl';
import BasicCard from './BasicCard';

const useStyles = makeStyles((theme) => ({
  body: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    margin: 16,
  },
  summary: {
    display: 'flex',
    alignItems: 'center',
  },
  t: {
    marginLeft: 16,
  },
  d: {
    width: '100%',
    height: 8,
    display: 'flex',
  },
  div: {
    marginTop: 10,
    marginBottom: 10,
  },
  indentBody: {
    // marginRight: 80,
    marginBottom: ({bodyOffset}) => (bodyOffset),
  },
  fullBody: {
    width: '100%',
  },
}));

function IconTitleMenuCard({
  pageControl,
  location,
  children,
  Icon,
  toUri,
  forwardRef,
  title = null,
  className = null,
  bodyOffset = -35,
  disableDivider = false,
  ...others
}) {
  const classes = useStyles({bodyOffset});
  let _body = className ? className : classes.body;
  const {enableBottomRightButton} = others;

  function toPage() {
    pageControl.toPage(location, toUri);
  }

  let _others = others;
  if (toUri) {
    _others = {
      ..._others,
      ...{
        bottomRightButtonOnClick: toPage,
      },
    };
  }

  return (
    <BasicCard
      {..._others}
      ref={forwardRef}
    >
      <div className={_body}>
        <div className={classes.summary}>
          {Icon}
          <Typography variant='subtitle1' className={classes.t}>{title}</Typography>
        </div>
        {disableDivider ? (<div className={classes.d}/>) : (<Divider className={classes.div}/>)}
        <div className={enableBottomRightButton ? classes.indentBody : classes.fullBody}>
          {children}
        </div>
      </div>
    </BasicCard>
  );
}

IconTitleMenuCard.propTypes = {
  Icon: TNode,
  toUri: TString,
  title: TString,
  className: TString,
  bodyOffset: TNumber,
  disableDivider: TBool,
};

const NoRefIconTitleMenuCard = withPageControl(IconTitleMenuCard);

export default React.forwardRef((props, ref) => (
  <NoRefIconTitleMenuCard {...props} forwardRef={ref}/>
));
