import React from 'react';
import Button from '@material-ui/core/Button';
import Card from 'lib/Core/Card';
import TriangleButton from 'lib/Core/TriangleButton';
import {makeStyles} from "@material-ui/styles";
import useConfig from 'lib/Core/hook/useConfig';
import transitions from 'logic/const/transition';
import {TBool, TFunction, TObject, TString, TNumber, TNode} from 'lib/Core/prop_types';
import ShowCard from 'lib/Core/Animation/ShowCard';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minWidth: 100,
    maxWidth: ({config}) => config.cardMaxWidth,
    marginBottom: 15,
    borderRadius: 10,
    boxShadow: ({config}) => (config.card.raised ? theme.design.boxShadow : 'none'),
    // marginLeft: 2,
    // marginRight: 2,
    // backgroundColor: theme.design.background.bottomNav,
  }
}));

function BasicCard({
  children,
  style = null,
  TopBar = null,
  TopRightButton = null, 
  enableLeftStripe = false, 
  leftStripeBackgroundColor = null, 
  leftStripeBackgroundImage = null, 
  leftStripeWidth = 5,
  enableBottomLeftButton = false,
  bottomLeftButtonLabel = null,
  bottomLeftButtonColor = '#FFFFFF',
  bottomLeftButtonOnClick = () => {},
  enableBottomRightButton = false,
  bottomRightButtonLabel = null,
  bottomRightButtonColorDark = null,
  bottomRightButtonColorLight = null,
  colorDark = '#FFFFFF',
  colorLight = '#FFFFFF',
  bottomRightButtonOnClick = () => {},
  enableTransition = true,
  transitionDelay = 0,
  transition = transitions.SLIDE_TO_RIGHT,
  forwardRef,
}) {
  const config = useConfig();
  const classes = useStyles({config});
  const leftStripeProps = {
    style: {
      width: leftStripeWidth,
      backgroundColor: leftStripeBackgroundColor,
      backgroundImage: leftStripeBackgroundImage,
    }
  };

  let _BottomRightButton = null;
  if (enableBottomRightButton) {
    _BottomRightButton = (
      <TriangleButton
        colorTop={bottomRightButtonColorLight ? bottomRightButtonColorLight : colorLight}
        colorBottom={bottomRightButtonColorDark ? bottomRightButtonColorDark : colorDark}
        label={bottomRightButtonLabel}
        onClick={bottomRightButtonOnClick}
      />
    );
  }

  let _BottomLeftButton = null;
  if (enableBottomLeftButton) {
    _BottomLeftButton = (
      <Button
        style={{
          color: bottomLeftButtonColor,
          textTransform: 'none',
        }}
        onClick={bottomLeftButtonOnClick}
      >
        {bottomLeftButtonLabel}
      </Button>
    );
  }
  
  const _Card = (
    <Card
      style={style}
      className={classes.root}
      TopBar={(TopBar)}
      BottomRightButton={_BottomRightButton}
      BottomLeftButton={_BottomLeftButton}
      TopRightButton={TopRightButton}
      enableLeftStripe={enableLeftStripe}
      LeftStripeProps={leftStripeProps}
      ref={forwardRef}
    >
      {children}
    </Card>
  );

  if (enableTransition) {
    if (transition === transitions.SLIDE_TO_LEFT) {
      return (
        <ShowCard direction='left' delay={transitionDelay}>
          {_Card}
        </ShowCard>
      )
    } else {
      return (
        <ShowCard direction='right' delay={transitionDelay}>
          {_Card}
        </ShowCard>
      )
    }
  }
  return _Card;
}

BasicCard.propTypes = {
  children: TNode,
  TopBar: TObject,
  TopRightButton: TObject,
  enableLeftStripe: TBool,
  leftStripeBackgroundColor: TString,
  leftStripeBackgroundImage: TString,
  leftStripeWidth: TNumber,
  enableBottomLeftButton: TBool,
  bottomLeftButtonLabel: TString,
  bottomLeftButtonColor: TString,
  bottomLeftButtonOnClick: TFunction,
  enableBottomRightButton: TBool,
  bottomRightButtonLabel: TString,
  colorDark: TString,
  colorLight: TString,
  bottomRightButtonOnClick: TFunction,
  bottomRightButtonColorDark: TString,
  bottomRightButtonColorLight: TString,
  enableTransition: TBool,
  transitionDelay: TNumber,
  transition: TNumber,
  // transitionOptions: TObject,
};

export default React.forwardRef((props, ref) => (
  <BasicCard {...props} forwardRef={ref}/>
));
