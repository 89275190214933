import React from 'react';
import Select from '@material-ui/core/Select';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  select: {
    paddingLeft: 18,
    background: theme.design.background.light,
    borderRadius: 3,
    fontSize: 18,
    paddingRight: 14,
    minHeight: 32,
    whiteSpace: 'normal',
    height: 'auto',
  },
  root: {
    color: theme.palette.text.secondary,
  },
}));

const CustomSelect = (props) => {
  const classes = useStyles();

  return (
    <Select
      classes={{
        select: classes.select,
        root: classes.root,
      }}
      {...props}
      disableUnderline={true} // This is a prop for MuiInput. No idea why it's not mentioned in the MuiSelect element. It works anyway.
    />
  );
};

export default CustomSelect;
