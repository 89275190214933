import React from 'react';
import {makeStyles} from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
  },
});

function ButtonText({style={}, className = null, label = '', size = 84}) {
  const classes = useStyles();

  const _style = {
    ...{
      float: 'right',
      marginRight: Math.max(25 - label.length * 3, 3),
      marginTop: size - 31,
      color: '#FFFFFF',
    },
    style,
  };

  return (
    <Typography
      variant={'subtitle1'}
      className={className ? className : classes.root}
      style={_style}
    >
      {label}
    </Typography>
  );
}

export default ButtonText;