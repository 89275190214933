import React from 'react';
import { TNumber } from 'lib/Core/prop_types';
import Standard from './Standard';

const TangneyConnectionError = ({ scale, minX = 0, minY = 0 }) => {
  return (
    <Standard
      scale={scale}
      viewBox={{
        minX,
        minY,
        width: 260,
        height: 212,
      }}
    >
      <defs>
        <mask id='a' x='0' y='0.41' width='259.77' height='211.43' maskUnits='userSpaceOnUse'>
          <polygon points='0 211.84 259.77 211.84 259.77 0.41 0 0.41 0 211.84' fill='#fff'
          />
        </mask>
      </defs>
      <polygon points='102.4 203.14 104.96 210.95 148.73 196.72 259.77 160.62 54.26 55.95 102.4 203.14'
        fill='#ff5622' />
      <path d='M100.21,124c-5.57-.78-8.19-5.84-10.71-10.74-4-7.76-7.79-15.09-22.25-11.5l-1-4c17.62-4.38,23,6,26.9,13.61,2.23,4.33,4.16,8.07,7.62,8.55Z'
        fill='#e3e4e4' />
      <path d='M190.25,148.39h-.4c-5.57-.34-10.31-7.91-14.93-23.81l4-1.15c5.39,18.52,9.65,20.75,11.23,20.85,2.89.19,6-5.59,8.07-10.45,3.4-7.91,7.09-12,11.29-12.64,2.88-.41,5.67.92,8.08,3.86l-3.19,2.61c-1-1.23-2.57-2.64-4.32-2.39-1.47.21-4.47,1.81-8.07,10.18-2.6,6.06-6.27,12.95-11.71,12.95'
        fill='#e3e4e4' />
      <path d='M94,121a6.43,6.43,0,1,0,6.42-6.42A6.42,6.42,0,0,0,94,121' fill='#cdcfd0'
      />
      <path d='M182.4,121A6.43,6.43,0,1,1,176,114.6,6.42,6.42,0,0,1,182.4,121'
        fill='#cdcfd0' />
      <path d='M175.14,179.78h.91l0-144.95a1.88,1.88,0,0,0-3.22-1.33L101.3,105.05l1,0h-.89v74.49l74.74.24Z'
        fill='#ffe686' />
      <g mask='url(#a)'>
        <polygon points='101.35 179.58 176.04 179.58 176.04 104.89 101.35 104.89 101.35 179.58'
          fill='#ffca33' />
      </g>
      <g mask='url(#a)'>
        <path d='M116.15,122.06a5.76,5.76,0,0,0-5.75,5.74v28.87a5.76,5.76,0,0,0,5.75,5.75h45.09a5.76,5.76,0,0,0,5.75-5.75V127.8a5.76,5.76,0,0,0-5.75-5.74Zm0,47.4a12.8,12.8,0,0,1-12.78-12.79V127.8A12.8,12.8,0,0,1,116.15,115h45.09A12.81,12.81,0,0,1,174,127.8v28.87a12.81,12.81,0,0,1-12.79,12.79Z'
          fill='#d79b28' />
      </g>
      <g mask='url(#a)'>
        <path d='M160.81,164.56H116.6a8.88,8.88,0,0,1-8.88-8.87v-26.9a8.88,8.88,0,0,1,8.88-8.87h44.21a8.87,8.87,0,0,1,8.88,8.87v26.9a8.87,8.87,0,0,1-8.88,8.87'
          fill='#475969' />
      </g>
      <g mask='url(#a)'>
        <path d='M125.57,120.6a16.09,16.09,0,0,0-16.07,16.07v11.14a16.09,16.09,0,0,0,16.07,16.07h26.24a16.09,16.09,0,0,0,16.07-16.07V136.67a16.09,16.09,0,0,0-16.07-16.07ZM151.81,168H125.57a20.22,20.22,0,0,1-20.19-20.19V136.67a20.22,20.22,0,0,1,20.19-20.19h26.24A20.22,20.22,0,0,1,172,136.67v11.14A20.22,20.22,0,0,1,151.81,168Z'
          fill='#c7c7c7' />
      </g>
      <g mask='url(#a)'>
        <path d='M116.15,120.6a7.21,7.21,0,0,0-7.2,7.2v28.87a7.21,7.21,0,0,0,7.2,7.21h45.09a7.21,7.21,0,0,0,7.21-7.21V127.8a7.21,7.21,0,0,0-7.21-7.2ZM161.24,168H116.15a11.34,11.34,0,0,1-11.32-11.33V127.8a11.34,11.34,0,0,1,11.32-11.32h45.09a11.34,11.34,0,0,1,11.33,11.32v28.87A11.34,11.34,0,0,1,161.24,168Z'
          fill='#fff' />
      </g>
      <g mask='url(#a)'>
        <path d='M153.54,151.58c-2.62,0,.32-4.18.32-9.34s-2.94-9.34-.32-9.34,4.75,4.18,4.75,9.34S156.16,151.58,153.54,151.58Z'
          fill='#fff' fillOpacity='0.2' />
      </g>
      <g mask='url(#a)'>
        <path d='M60.33,99.74a6.43,6.43,0,1,0,6.42-6.43,6.42,6.42,0,0,0-6.42,6.43'
          fill='#cdcfd0' />
      </g>
      <g mask='url(#a)'>
        <path d='M222.37,126.34a6.43,6.43,0,1,1-6.42-6.42,6.42,6.42,0,0,1,6.42,6.42'
          fill='#cdcfd0' />
      </g>
      <g mask='url(#a)'>
        <path d='M137.78,101.32a5.84,5.84,0,0,1-3.56-1.46.62.62,0,1,1,.82-.93c.11.1,2.67,2.27,4.42.38,1.92-2.06,4.8-.9,6,0a.62.62,0,0,1-.72,1c-.11-.08-2.77-1.94-4.42-.15a3.43,3.43,0,0,1-2.59,1.16'
          fill='#58595b' />
      </g>
      <g mask='url(#a)'>
        <path d='M131.42,95.19h-.33a2.38,2.38,0,0,1-2.37-2.37V85.18a2.38,2.38,0,0,1,2.37-2.37h.33a2.38,2.38,0,0,1,2.37,2.37v7.64a2.38,2.38,0,0,1-2.37,2.37'
          fill='#58595b' />
      </g>
      <g mask='url(#a)'>
        <path d='M149.1,95.19h-.33a2.37,2.37,0,0,1-2.36-2.37V85.18a2.37,2.37,0,0,1,2.36-2.37h.33a2.38,2.38,0,0,1,2.37,2.37v7.64a2.37,2.37,0,0,1-2.37,2.37'
          fill='#58595b' />
      </g>
      <g mask='url(#a)'>
        <path d='M130.61,142.62V140a10.65,10.65,0,0,1,10.65,10.65h-2.67a8,8,0,0,0-8-8m0-5.33v-2.66a16,16,0,0,1,16,16h-2.66a13.31,13.31,0,0,0-13.31-13.31m0-5.32v-2.66h.13a21.13,21.13,0,0,1,21.16,21.16v.13h-2.66v-.13A18.49,18.49,0,0,0,130.61,132'
          fill='#f5f5d2' />
      </g>
      <g mask='url(#a)'>
        <polygon points='133.82 154.25 126.95 147.38 128.12 146.21 134.99 153.08 133.82 154.25'
          fill='#f5f5d2' />
      </g>
      <g mask='url(#a)'>
        <polygon points='128.12 154.25 126.95 153.08 133.82 146.21 134.99 147.38 128.12 154.25'
          fill='#f5f5d2' />
      </g>
      <g mask='url(#a)'>
        <path d='M40.77,107.78a1.25,1.25,0,0,1-1.13-.72L22.35,69.55a1.24,1.24,0,1,1,2.25-1L41.89,106a1.25,1.25,0,0,1-.61,1.65,1.34,1.34,0,0,1-.51.11'
          fill='#ff5622' />
      </g>
      <g mask='url(#a)'>
        <path d='M38.06,116.39a1.25,1.25,0,0,1-1.13-.72L19.64,78.16a1.24,1.24,0,1,1,2.25-1l17.29,37.51a1.24,1.24,0,0,1-.61,1.64,1.3,1.3,0,0,1-.51.11'
          fill='#ff5622' />
      </g>
      <g mask='url(#a)'>
        <path d='M35.89,124.83a1.25,1.25,0,0,1-1.13-.72L17.47,86.6a1.24,1.24,0,0,1,2.25-1L37,123.07a1.23,1.23,0,0,1-.61,1.64,1.16,1.16,0,0,1-.51.12'
          fill='#ff5622' />
      </g>
      <g mask='url(#a)'>
        <path d='M56.19,40.39a1.24,1.24,0,0,1-1.13-.71L37.77,2.17a1.24,1.24,0,0,1,2.25-1L57.31,38.64a1.23,1.23,0,0,1-.6,1.64,1.22,1.22,0,0,1-.52.11'
          fill='#ff5622' />
      </g>
      <g mask='url(#a)'>
        <path d='M53.48,49a1.22,1.22,0,0,1-1.12-.72L35.06,10.78a1.24,1.24,0,0,1,2.25-1L54.6,47.25A1.24,1.24,0,0,1,53.48,49'
          fill='#ff5622' />
      </g>
      <g mask='url(#a)'>
        <path d='M51.31,57.44a1.22,1.22,0,0,1-1.12-.72L32.89,19.21a1.24,1.24,0,0,1,2.25-1L52.43,55.69a1.23,1.23,0,0,1-.6,1.64,1.39,1.39,0,0,1-.52.11'
          fill='#ff5622' />
      </g>
      <g mask='url(#a)'>
        <path d='M24.52,114.48a1.67,1.67,0,0,1-.44-.06,1.85,1.85,0,0,1-1.37-2.23l23-96.32a1.86,1.86,0,0,1,3.61.86l-23,96.32a1.87,1.87,0,0,1-1.8,1.43'
          fill='#d63f21' />
      </g>
      <g mask='url(#a)'>
        <path d='M66.63,101h-.06a2.48,2.48,0,0,1-2.42-2.52c.21-10,.38-37.14-1.38-43-.49-1.61-1-2.16-1.21-2.23-1.08-.33-4.64,2.46-6.76,4.14-.94.74-1.91,1.51-2.91,2.25-9.23,6.85-28.24,6.4-29,6.38A2.47,2.47,0,1,1,23,61c.18,0,18.11.42,26-5.41,1-.7,1.89-1.44,2.8-2.16,4.14-3.28,7.72-6.1,11.31-5,2.08.65,3.53,2.46,4.45,5.52,2.31,7.67,1.68,40.77,1.6,44.52A2.47,2.47,0,0,1,66.63,101'
          fill='#b5311f' />
      </g>
      <g mask='url(#a)'>
        <polygon points='0 98.12 8.09 100.79 8.42 96.73 18.78 102.42 10.26 100.45 9.92 104.66 0 98.12'
          fill='#ffca33' />
      </g>
      <g mask='url(#a)'>
        <polygon points='55.88 24.84 61.45 18.39 63.82 21.71 69.79 11.51 63.49 17.57 61.03 14.14 55.88 24.84'
          fill='#ffca33' />
      </g>
      <g mask='url(#a)'>
        <polygon points='176.09 179.58 163 192.68 104.73 211.62 101.32 201.21 101.32 179.58 176.09 179.58'
          fill='#ffe686' />
      </g>
    </Standard>
  );
};

TangneyConnectionError.propTypes = {
  scale: TNumber.isRequired,
};

export default TangneyConnectionError;
