import React from 'react';
import Slide from '@material-ui/core/Slide';

export default Slide;

export const SlideDown = React.forwardRef((props, ref) => (
  <Slide direction={'down'} {...props} ref={ref}/>
));

export const SlideUp = React.forwardRef((props, ref) => (
  <Slide direction={'up'} {...props} ref={ref}/>
));

export const SlideRight = React.forwardRef((props, ref) => (
  <Slide direction={'right'} {...props} ref={ref}/>
));

export const SlideLeft = React.forwardRef((props, ref) => (
  <Slide direction={'left'} {...props} ref={ref}/>
));
