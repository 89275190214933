import {put, takeLeading} from 'redux-saga/effects';
import createState from './state';
import {
  passData,
} from '../actions';
import {TBool, declareObject} from 'lib/Core/prop_types';

export const TDayNightMode = declareObject({
  isEnabled: TBool.isRequired,
  isDay: TBool.isRequired,
});

export default createState(
  'dayNightMode',
  {
    isEnabled: false,
    isDay: true,
  },
  ({addSaga, reduce, reduceError, reduceReset}) => {
    addSaga(function* () {
      yield takeLeading('DAY_NIGHT_MODE', function* (action) {
        yield put(passData('DAY_NIGHT_MODE_D', {dayNightMode: action.dayNightMode}));
      });
    });

    reduce('DAY_NIGHT_MODE_D', function (state, action) {
      return Object.assign({}, action.dayNightMode);
    });
    reduceReset('DAY_NIGHT_MODE_RESET');
  }
);

export const dayNightMode = {
  update: (dayNightMode) => ({type: 'DAY_NIGHT_MODE', dayNightMode}),
  reset: () => ({type: 'DAY_NIGHT_MODE_RESET'}),
};
