import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    zIndex: 1,
    color: theme.design.background.dark,
    paddingTop: 10,
    paddingLeft: 20,
    pointerEvents: 'none',
  },
  shrink: {
    color: theme.palette.primary[500],
    paddingLeft: 0,
    fontSize: 18,
    paddingTop: 0,
    marginTop: -5,
  },
  // focused: {
  //   color: `${theme.palette.primary[500]} !important`,
  // }
}));

const CustomInputLabel = (props) => {
  const classes = useStyles();

  return (
    <InputLabel
      classes={{
        formControl: classes.formControl,
        shrink: classes.shrink,
      }}
      // FormLabelClasses={{
      //   focused: classes.focused,
      // }}
      {...props}
    />
  );
};

export default CustomInputLabel;
