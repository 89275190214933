import React from 'react';
import {useTheme} from '@material-ui/styles';
// import transitions from 'logic/const/transition';
import {TBool, TFunction, TObject, TString, TNumber, TNode} from 'lib/Core/prop_types';
import BasicCard from './BasicCard';

// const useStyles = makeStyles((theme) => ({
//   root: {
//
//   }
// }));

function RewardBasicCard({forwardRef, ...others}) {
  const theme = useTheme();
  return (
    <BasicCard
      // style={style}
      // TopBar={(TopBar)}
      // TopRightButton={TopRightButton}
      // enableLeftStripe={enableLeftStripe}
      // enableBottomLeftButton={enableBottomLeftButton}
      // bottomLeftButtonLabel={bottomLeftButtonLabel}
      // bottomLeftButtonOnClick={bottomLeftButtonOnClick}
      // enableBottomRightButton={enableBottomRightButton}
      // bottomRightButtonLabel={bottomRightButtonLabel}
      // bottomRightButtonOnClick={bottomRightButtonOnClick}
      // enableTransition={enableTransition}
      // transition={transition}
      leftStripeBackgroundColor={theme.design.reward.dark}
      leftStripeWidth={5}
      bottomLeftButtonColor={theme.design.reward.dark}
      colorDark={theme.design.reward.dark}
      colorLight={theme.design.reward.light}
      ref={forwardRef}
      {...others}
    />
  )
}

RewardBasicCard.propTypes = {
  children: TNode,
  TopBar: TObject,
  TopRightButton: TObject,
  enableLeftStripe: TBool,
  leftStripeWidth: TNumber,
  enableBottomLeftButton: TBool,
  bottomLeftButtonLabel: TString,
  bottomLeftButtonOnClick: TFunction,
  enableBottomRightButton: TBool,
  bottomRightButtonLabel: TString,
  bottomRightButtonOnClick: TFunction,
  enableTransition: TBool,
  transition: TNumber,
  bottomLeftButtonColor: TString,
};

export default React.forwardRef((props, ref) => (
  <RewardBasicCard {...props} forwardRef={ref}/>
));
