import React, {useState, useEffect, useCallback} from 'react';
import classNames from 'classnames';
import {makeStyles, useTheme} from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import SendIcon from 'lib/Icon/Send';
import {smallIcon} from '../Icon';
import {TFunction} from 'lib/Core/prop_types';

const useStyles = makeStyles(theme => ({
  root: {
  },
  rBtn: {
    paddingLeft: 0,
    color: ({active}) => active ? theme.palette.primary[500] : theme.palette.text.faded,
    textTransform: 'none',
  },
}));

function SendCodeButton({
  send,
  interval = 60,
  className,
  style,
  title = 'Resend Code To Email',
  active = true,
  freezeImmediately = false,
}) {
  const classes = useStyles({active});
  const theme = useTheme();
  const [freeze, setFreeze] = useState(false);
  const [stopWatch, setStopWatch] = useState(interval + 1);
  const [stopWatchIndex, setStopWatchIndex] = useState(null);
  const [firstFreeze, setFirstFreeze] = useState(freezeImmediately);

  const runStopWatch = useCallback(
    () => {
      if (!freeze) {
        setFreeze(true);
        setStopWatch(stopWatch - 1);
      }
      setStopWatchIndex(
        setInterval(() => {
          setStopWatch(stopWatch => {
            if (stopWatch > 0) {
              return stopWatch - 1;
            } else {
              setFreeze(false);
              return interval + 1;
            }
          });
        }, 1000)
      );
    },
    [
      freeze,
      stopWatch,
      interval,
    ]
  );

  useEffect(
    () => {
      if (!freeze && stopWatchIndex !== null) {
        clearInterval(stopWatchIndex);
      }
    },
    [
      freeze,
      stopWatchIndex,
    ]
  );

  useEffect(
    () => {
      if (firstFreeze) {
        setFirstFreeze(false);
        runStopWatch();
      }
    },
    [
      setFirstFreeze,
      firstFreeze,
      runStopWatch,
    ]
  );

  useEffect(
    () => {
      return () => {
        if (stopWatchIndex !== null) {
          clearInterval(stopWatchIndex);
        }
      }
    },
    [
      stopWatchIndex,
    ]
  );

  function resendCode() {
    if (!freeze) {
      runStopWatch();
      send();
    }
  }

  // function runStopWatch() {
  //   if (!freeze) {
  //     setFreeze(true);
  //     setStopWatch(stopWatch - 1);
  //   }
  //   setStopWatchIndex(
  //     setInterval(() => {
  //       setStopWatch(stopWatch => {
  //         if (stopWatch > 0) {
  //           return stopWatch - 1;
  //         } else {
  //           setFreeze(false);
  //           return interval + 1;
  //         }
  //       });
  //     }, 1000)
  //   );
  // }

  function renderButton() {
    if (freeze) {
      return (
        <Button className={classes.rBtn}>
          {stopWatch}&nbsp;to resend again
        </Button>
      );
    } else {
      return (
        <Button
          className={classes.rBtn}
          onClick={active ? resendCode : null}
        >
          {smallIcon(SendIcon, active ? theme.palette.primary[500] : theme.palette.text.faded)}
          &nbsp;&nbsp;
          {title}
        </Button>
      );
    }
  }

  return (
    <div className={classNames(classes.root, className)} style={style}>
      {renderButton()}
    </div>
  );
}

SendCodeButton.propTypes = {
  send: TFunction.isRequired,
};

export default SendCodeButton;
