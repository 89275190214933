import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import registerServiceWorker from './registerServiceWorker';

const startApp = () => {
  ReactDOM.render(<App/>, document.getElementById('root'));
  // registerServiceWorker();
};

try {
  document.addEventListener(
    'DOMContentLoaded',
    function () {
      startApp();
      document.getElementById("loading").remove();
    },
    false
  );
} catch (e) {
  alert(e.message);
}
