import amber from '@material-ui/core/colors/amber';
import {getWindowInnerWidth} from './viewport';

/**
 * Set up theme
 */
const color = Object.assign(amber, {
  500: '#F6C61C',
});

// Get the display displaySize ready.
function typography() {
  const wWidth = getWindowInnerWidth();
  const SIZE = {
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
  };

  let displaySize = null;
  let fontSize = 14;
  if (wWidth <= 350) {
    displaySize = SIZE.ONE;
    fontSize = 14;
  } else if (wWidth <= 400) {
    displaySize = SIZE.TWO;
    fontSize = 14;
  } else if (wWidth <= 450) {
    displaySize = SIZE.THREE;
    fontSize = 14;
  } else {
    displaySize = SIZE.FOUR;
    fontSize = 14;
  }

  const custom = {
    h6: {
      color: '#333',
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: 18,
      color: '#333',
    },
    body2: {
      color: '#333',
    },
    caption: {
      color: '#333',
    },
  };

  return {
    SIZE,
    displaySize,
    custom,
    fontSize,
  }
}

export const {SIZE, displaySize, custom, fontSize} = typography();

export default {
  palette: {
    primary: color,
    text: {
      primary: '#333',
      secondary: '#666',
      light: '#999',
      faded: '#BBB',
    }
  },
  status: {
    danger: '#F44336',
    warning: '#FF6E40',
    success: '#21CE99',
    info: color[500],
  },
  design: {
    bottomNav: {
      inactive: '#DCDCDC',
    },
    icon: {
      light: '#DCDCDC',
    },
    layoutHeader: {
      day: {
        dark: '#F6C61C',
        light: '#FFBFAC',
      },
      night: {
        dark: '#0F4C81',
        light: '#0F4C81',
      },
    },
    mainYellow: '#F6C61C',
    textHighlight: '#F47F26',
    background: {
      dark:'#E0E0E0',
      light: '#F5F5F5',
      // light: '#F6C61C',
      topMenuItem: '#AFAFAF',
      // topMenuItem: '#F6C61C',
      // bottomNav: '#F9F9F9',
      // light: '#FFFFFF',
      bottomNav: '#F5F5F5',
    },
    textLink: '#5f97ef',
    boxShadow: '0 2px 6px rgba(0,0,0,0.1), 0 3px 8px rgba(0,0,0,0.16)',
    boxShadow2: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);',
    reward: {
      // light: '#9BE15D',
      // dark: '#00D866',
      light: '#F6C61C',
      dark: '#F6C61C',
      text: '#08CEBF',
      fab: '#A4A4FF',
      triangle: 'linear-gradient(135deg, rgba(161, 221, 60, 1) 68px, transparent 0)',
      triangle225: 'linear-gradient(225deg, rgba(161, 221, 60, 1) 68px, transparent 0)',
    },
    wealth: {
      // light: '#7CC8FE',
      // light: '#A4A4FF',
      // dark: '#A4A4FF',
      light: '#F6C61C',
      dark: '#F6C61C',
      text: '#A4A4FF',
      fab: '#A4A4FF',
      triangle: 'linear-gradient(135deg, rgba(90, 179, 243, 1) 68px, transparent 0)',
      triangle225: 'linear-gradient(225deg, rgba(90, 179, 243, 1) 68px, transparent 0)',
    },
    center: {
      // light: '#FFE168',
      // dark: '#FF952B',
      light: '#F6C61C',
      dark: '#F6C61C',
      text: '#FF952B',
      fab: '#A4A4FF',
      triangle: 'linear-gradient(135deg, rgba(255, 159, 63, 1) 68px, transparent 0)',
      triangle225: 'linear-gradient(225deg, rgba(255, 159, 63, 1) 68px, transparent 0)',
    },
    default: {
      // light: '#FDDE26',
      light: '#F6C61C',
      dark: '#F6C61C',
      text: '#A4A4FF',
      fab: '#A4A4FF',
      triangle: 'linear-gradient(135deg, rgba(255, 255, 255, 0.5) 59px, transparent 0)',
      triangle225: 'linear-gradient(225deg, #F6C61C 68px, transparent 0)',
    },
    // portals: { // Not used for now. Was used by dynamic top dock.
    //   default: {
    //     // light: '#FDDE26',
    //     // dark: '#FFBFAC',
    //     light: '#F6C61C',
    //     dark: '#F6C61C',
    //   },
    //   food: {
    //     // light: '#9BE15D',
    //     // dark: '#08CEBF',
    //     light: '#F6C61C',
    //     dark: '#F6C61C',
    //   },
    //   investment: {
    //     // light: '#7CC8FE',
    //     // light: '#A4A4FF',
    //     // dark: '#A4A4FF',
    //     light: '#F6C61C',
    //     dark: '#F6C61C',
    //   },
    //   tCenter: {
    //     // light: '#FFE168',
    //     // dark: '#FF952B',
    //     light: '#F6C61C',
    //     dark: '#F6C61C',
    //   },
    // },
    fonts: {
      // title: ''
    },
  },
  typography: {
    useNextVariants: true,
    // fontFamily: "'Noto Sans Thaana', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontFamily: "'tg-custom-font', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontSize,
  },
  overrides: {
    MuiTypography: custom,
  },
};