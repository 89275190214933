import Zoom from '@material-ui/core/Zoom';
export default Zoom;

// export default function Zoom(props) {
//   return (
//     <LibZoom {...{
//       ...props,
//       ...{
//         out: !props.in,
//         duration: props.in ? props.timeout.enter : props.timeout.exit,
//         children: props.children,
//       },
//     }}/>
//   );
// }
