import React, {useEffect} from 'react';
import {useLocation} from 'react-router';
import queryString from 'query-string';
import {makeStyles} from '@material-ui/styles';
import {useSelector, useDispatch} from 'react-redux';
import {dataStatus} from 'logic/redux';
import {actions as emailUnsubscribeActions, emailUnsubscribeLoading} from 'logic/redux/state/email_unsubscribe';
import AsyncContainer from 'component/AsyncContainer';
import InvalidRef from './InvalidRef';
import SetOptions from './SetOptions';

const useStyles = makeStyles(theme => ({
}));

function EmailUnsubscribePage() {
  // const classes = useStyles();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const emailUnsubscribe = useSelector(state => state.emailUnsubscribe);
  const dispatch = useDispatch();

  useEffect(
    () => {
      if (emailUnsubscribe.status === dataStatus.INIT && query.ref) {
        dispatch(emailUnsubscribeActions.loadOptions(query.ref));
      }
    },
    [
      emailUnsubscribe.status,
    ]
  );

  if (query && query.ref) {
    if (emailUnsubscribe.status === dataStatus.SUCCESS) {
      return (
        <AsyncContainer alias={[emailUnsubscribeLoading]}>
          <SetOptions jwtToken={query.ref} preferences={emailUnsubscribe.options}/>
        </AsyncContainer>
      );
    } else {
      return (
        <AsyncContainer alias={[emailUnsubscribeLoading]}>
          <InvalidRef/>
        </AsyncContainer>
      );
    }
  } else {
    return (
      <AsyncContainer alias={[emailUnsubscribeLoading]}>
        <InvalidRef/>
      </AsyncContainer>
    );
  }
}

export default EmailUnsubscribePage;
