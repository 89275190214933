export const URI = {
  SPLASH: '/',
  HOME: '/home',
  // INVENTORY: '/inventory',
  WORLD_GATE: '/world-gate',
  CUSTOMER_ACTIVATE: '/customer/activate',
  CUSTOMER_REGISTER: '/register',
  MERCHANT_ENQUIRY_FORM: '/merchant/enquiry',
  COFFEE_ZERO_VOUCHER_PAGE: '/coffee-zero/voucher',
  LOGIN: '/login',
  // SETTING: '/setting',
  // CAMPAIGN: '/campaign',
  // LOGIN: '/login',
  // LOGIN_USER_SESSION: '/login-user-session',
  // ACCOUNT_EDIT: '/account/edit/main',
  // AVATAR_EDIT: '/account/edit/avatar',
  // REWARD_LANDING: '/reward/landing',
  // CUSTOMER_BOOST_SINGLE_INVESTMENT: '/customer/boost/investment',
  // CUSTOMER_BOOST_INVESTMENT_RESULT: '/customer/boost/result',
  // CUSTOMER_REWARDS: '/customer/rewards',
  // CUSTOMER_CENTS_TRANSACTION: '/customer/cent-transaction',
  // CUSTOMER_STORES: '/customer/store/list',
  // // CUSTOMER_STORE_DETAIL: '/customer/store/detail',
  // CUSTOMER_FORGOT_PASSWORD: '/customer/forgot-password',
  // CUSTOMER_RESET_PASSWORD: '/customer/reset-password',
  // CUSTOMER_RISK_ASSESSMENT: '/customer/risk-assessment/assessment',
  // CUSTOMER_RISK_ASSESSMENT_RESULT: '/customer/risk-assessment/result',
  // WEALTH_GOAL_HOME: '/wealth/goal/home',
  // WEALTH_GOAL_VIEW: '/wealth/goal/View',
  // WEALTH_GOAL_CREATE: '/wealth/goal/create',
  // WEALTH_GOAL_CREATE_RESULT: '/wealth/goal/create-result',
  // WEALTH_TRANSACTION: '/wealth/transaction',
  // FAQ: '/faq',
  // PRIVACY_POLICY: '/privacy-policy',
  // TERMS_AND_CONDITIONS: '/terms-and-conditions',
  // MY_QR_CODE: '/my-qr-code',
  // // CUSTOMER_MY_GROUPS: '/customer/my-groups',
  // CUSTOMER_REFER_FRIEND: '/customer/refer-a-friend',
  // PAY_QR_CODE: '/pay-qr-code',
  // TANGGRAMMERS_QUEST_CENTER: '/tanggrammers/quest-center',
  // CUSTOMER_ID_CHECK: '/customer/id-check',
  // CUSTOMER_ID_CHECK_RESULT: '/customer/id-check-result',
  // CHANGE_PASSWORD: '/account/change-password',
  // FRIEND_LIST: '/customer/friend/home',
  // FRIEND_PENDING_LIST: '/customer/friend/pending',
  // FRIEND_ADD: '/customer/friend/add',
  // NOTIFICATION_CENTER: '/account/notification-center',
  // NOTIFICATION_DETAIL: '/account/notification-detail',
  // VOUCHER_HOME: '/customer/voucher/home',
  // REWARD_TUTORIAL: '/customer/reward/tutorial',
  // REWARD_TUTORIAL_USAGE: '/customer/reward/tutorial-usage',
  // REWARD_TUTORIAL_WITHDRAW: '/customer/reward/tutorial-two',
  // VOUCHER_DETAIL: '/customer/voucher/detail',
  // SUPPORT: '/customer/support/home',
  // TANGGRAMMERS_PUZZLE: '/tanggrammers/puzzle/home',
  // TANGGRAMMERS_PUZZLE_DETAIL: '/tanggrammers/puzzle/detail',
  // WEALTH_GOAL_EDIT: '/wealth/goal/edit',
  // WEALTH_GOAL_WITHDRAW: '/wealth/goal/withdraw',
  // WEALTH_INVESTMENT_HOME: '/wealth/investment/home',
  // WEALTH_INVESTMENT_CREATE: '/wealth/investment/create',
  // WEALTH_INVESTMENT_CREATE_RESULT: '/wealth/investment/create-result',
  // WEALTH_INVESTMENT_VIEW: '/wealth/investment/View',
  // WEALTH_INVESTMENT_EDIT: '/wealth/investment/edit',
  // WEALTH_INVESTMENT_WITHDRAW: '/wealth/investment/withdraw',
  // ABOUT_PLATFORM: '/about-platform',
  // CUSTOMER_ID_CHECK_MANUALLY: '/customer/id-check-manually',
  // CUSTOMER_TUTORIAL_HOME: '/customer/tutorial/home',
  // CUSTOMER_INVITATION_CODE: '/customer/invitation-code',
  // CUSTOMER_TUTORIAL_REWARDS: '/customer/tutorial/rewards',
  // CUSTOMER_TUTORIAL_WEALTH: '/customer/tutorial/wealth',
  // CUSTOMER_TUTORIAL_INVESTMENT_CREATE: '/customer/tutorial/investment-create',
  // // CUSTOMER_WALLET_HOME: '/customer/wallet/home',
  // TANGGRAMMERS_QUIZ_ASSESSMENT: '/tanggrammers/quiz/assessment',
  // TANGGRAMMERS_QUIZ_RESULT: '/tanggrammers/quiz/result',
  // TANGGRAMMERS_CENTER_MALL: '/tanggrammers/center-mall',
  // TANGGRAMMERS_INVESTMENT_HOME: '/tanggrammers/investment/home',
  // TANGGRAMMERS_INVESTMENT_VIEW: '/tanggrammers/investment/detail',
  // TANGGRAMMERS_INVESTMENT_CREATE: '/tanggrammers/investment/create',
  // CONTACT: '/customer/support/contact',
  // WEALTH_INVESTMENT_COMING_SOON: '/wealth/Investment/coming-soon',
  // CUSTOMER_ID_CHECK_METHOD_SELECTION: '/customer/id-check-method-selection',
  // CUSTOMER_ID_CHECK_TERMS: '/customer/id-check-terms',
  // ANIMATION_SAMPLE_PAGE: '/dev/animation-sample-page',
  // WEALTH_INVESTMENT_STATE_EXPLANATION: '/wealth/investment/state-explanation',
  // CUSTOMER_FINANCIAL_INFORMATION_READ: '/customer/financial-information-read',
  // CUSTOMER_FINANCIAL_INFORMATION_EDIT: '/customer/financial-information-edit',
  // INVESTMENT_PENDING_PAYMENT: '/wealth/investment/pending-payment',
  // REWARD_WITHDRAWAL_HOME: '/reward/withdrawal/home',
  // REWARD_WITHDRAWAL_RESULT: '/reward/withdrawal/result',
  // CUSTOMER_ID_CHECK_SECURITY_QUESTION: '/customer/id-check-security-question',
  // CUSTOMER_REWARDS_OTHER: '/customer/rewards-other',
  // CUSTOMER_PASSPORT_UPLOAD: '/customer/passport/upload',
  // SET_PIN: '/pin/set',
  // SESSION_LOGIN: '/session-login',
  // // SESSION_LOGIN_PASSWORD: '/session-login-password',
  // WEALTH_INVESTMENT_PARTIAL_WITHDRAW: '/wealth/investment/partial-withdraw',
  // WEALTH_LEGAL_DOCUMENTS_HOME: '/wealth/legal-documents/home',
  // WEALTH_INVESTMENT_SYNC_ERROR: '/wealth/investment/sync-error',
  // COMPLAINTS: '/customer/support/complaints',
  // SECURITY_SETTING: '/security/setting',
  // MOBILE_VERIFICATION: '/account/mobile-verification',
  // INVESTMENT_PAYMENT_OPTION: '/wealth/investment/payment-option',
};

