import React from 'react';
import {makeStyles} from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import {Body} from '../NavLayout';
import {BLANK_MENU_ITEM_ID, BlankSideMenu} from '../SideMenu';
import transitions from 'logic/const/transition';
import withPageControl from '../HOC/withPageControl';
import CircularBar from '../Progress/CircularBar';
import PlainCard from '../Card/PlainCard';
import TopMenuLayout from '../TopMenuLayout';

const useStyles = makeStyles({
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: 16,
    marginRight: 16,
    marginTop: 36,
  },
  text: {
    marginTop: 20,
    marginBottom: 20,
  }
});

export const LoginInProgressCard = withPageControl(({
  pageControl,
  location,
}) => {
  const classes = useStyles();
  return (
    <PlainCard
      transition={transitions.SLIDE_TO_RIGHT}
    >
      <div className={classes.body}>
        <CircularBar/>
        <Typography variant='body1' color='textSecondary'  className={classes.text}>Logging In</Typography>
      </div>
    </PlainCard>
  );
});

export function LoginInProgressLayout({
  children,
  title = 'Login In Progress',
}) {
  return (
    <TopMenuLayout
      SideMenu={(<BlankSideMenu activeItem={BLANK_MENU_ITEM_ID.LOGIN}/>)}
    >
      <Body title={title}>
        {children}
      </Body>
    </TopMenuLayout>
  );
}

export default function LoginInProgress({
  title = 'Login In Progress',
}) {
  return (
    <LoginInProgressLayout>
      <LoginInProgressCard/>
    </LoginInProgressLayout>
  );
}