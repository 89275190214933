import {put, takeLeading} from 'redux-saga/effects';
import createState from './state';
import exchangeSessionByTokenPassword from '../../service/api/method/exchange_session_by_token_password';
import {dataStatus, transformApiData} from '../transform';
import {
  getApiResponse,
  handleError,
  showLoading,
  hideLoading,
} from '../actions';
import TDataStatus from '../data_status';
import {TString, TNumber, TArray, declareObject} from 'lib/Core/prop_types';

export const TUserSessionExchangePassword = declareObject({
  status: TDataStatus.isRequired,
  data: declareObject({
    access_session_id: TString,
    is_token_valid: TNumber,
    is_pin_valid: TNumber,
    is_max_negative_attempts_exceeded: TNumber,
    is_password_valid: TNumber,
  }),
  errors: TArray,
});

export const userSessionExchanging = 'userSessionExchangePassword.1';

export default createState(
  'userSessionExchangePassword',
  {
    status: dataStatus.INIT,
    data: {
      access_session_id: null,
      is_token_valid: null,
      is_pin_valid: null,
      is_max_negative_attempts_exceeded: null,
      is_password_valid: null,
    },
    errors: [],
  },
  ({addSaga, reduce, reduceError, reduceReset}) => {
    addSaga(function* () {
      yield takeLeading('EXCHANGE_USER_SESSION_BY_PASSWORD', function* (action) {
        try {
          yield put(showLoading(userSessionExchanging));
          const apiData = yield exchangeSessionByTokenPassword(action.postData);
          yield put(getApiResponse('EXCHANGE_USER_SESSION_BY_PASSWORD_R', apiData));
          yield put(hideLoading(userSessionExchanging));
        } catch (e) {
          yield put(handleError('EXCHANGE_USER_SESSION_BY_PASSWORD_F', e));
          yield put(hideLoading(userSessionExchanging));
        }
      });
    });

    reduce('EXCHANGE_USER_SESSION_BY_PASSWORD_R', function (state, action) {
      let data = Object.assign({}, state);
      const apiData = transformApiData(action.data);
      data.status = apiData.status;
      if (data.status === dataStatus.FAILURE) {
        data.errors = apiData.errors;
      } else if (data.status === dataStatus.SUCCESS) {
        data.data = apiData.data;
      }
      return data;
    });
    reduceError('EXCHANGE_USER_SESSION_BY_PASSWORD_F');
    reduceReset('EXCHANGE_USER_SESSION_BY_PASSWORD_RESET');
  }
);

export const userSessionExchangePassword = {
  exchange: (postData) => ({type: 'EXCHANGE_USER_SESSION_BY_PASSWORD', postData}),
  reset: () => ({type: 'EXCHANGE_USER_SESSION_BY_PASSWORD_RESET'}),
};
