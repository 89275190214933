import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {makeStyles} from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import {maintenanceMode, TMaintenanceMode} from 'logic/redux/state/maintenance_mode';
import DefaultDialogCard from '../Card/DefaultDialogCard';
import Image from 'component/Image';

const useStyles = makeStyles({
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 20,
    alignItems: 'center',
    flexGrow: 1,
  },
  img: {
    marginTop: 16,
    marginBottom: 16,
  }
});

/**
 * @returns {null}
 */
function MaintenanceMode({
  hide,
  maintenanceMode,
}) {
  const classes = useStyles();

  function handleCloseDialog() {
    hide();
  }

  if (maintenanceMode.on) {
    return (
      <DefaultDialogCard
        enableTopRightButton={false}
        isOpen={true}
        onClose={handleCloseDialog}
        bottomRightButtonLabel={'OK'}
        transition={'slideUp'}
        bottomRightButtonOnClick={handleCloseDialog}
      >
        <div className={classes.column}>
          <Typography variant='h6'>Maintenance Mode</Typography>
          <div className={classes.img}>
            <Image path={maintenanceMode.image} width={235} aspectRatio={235/381} alt={'maintenance'}/>
          </div>
          <Typography variant='body1'>{maintenanceMode.message}</Typography>
        </div>
      </DefaultDialogCard>
    );
  } else {
    return null;
  }
}

MaintenanceMode.propTypes = {
  maintenanceMode: TMaintenanceMode.isRequired,
};

function mapStateToProps(state) {
  return {
    maintenanceMode: state.maintenanceMode,
  }
}

const mapDispatchToProps = {
  hide: maintenanceMode.hide,
};

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceMode);
