import userLoginState from './user_login';
import userSessionState from './user_session';
import loadingTransitionState from './loading_transition';
import connectionErrorState from './connection_error';
import customerActivationState from './customer_activation';
import customerRegistrationState from './customer_registration';
import customerResendCodeState from './customer_resend_code';
import topDockState from './top_dock';
import maintenanceModeState from './maintenance_mode';
import dayNightModeState from './day_night_mode';
import customerSendSmsCodeState from './customer_send_sms_code';
import customerMobileVerificationState from './customer_mobile_verification';
import userSessionTokenPublicDataState from './user_session_token_public_data';
import userSessionExchangePasswordState from './user_session_exchange_password';
import customerReferViaWebState from './customer_register_via_web';
import merchantEnquiryState from './merchant_enquiry';
import registerRefState from './register_ref';
import coffeeZeroVoucherState from './coffee_zero_voucher';
import emailUnsubscribe from './email_unsubscribe';
import emailUnsubscribeUpdate from './email_unsubscribe_update';

export default [
  userLoginState,
  userSessionState,
  loadingTransitionState,
  connectionErrorState,
  customerActivationState,
  customerRegistrationState,
  customerResendCodeState,
  topDockState,
  maintenanceModeState,
  dayNightModeState,
  customerSendSmsCodeState,
  customerMobileVerificationState,
  userSessionTokenPublicDataState,
  userSessionExchangePasswordState,
  customerReferViaWebState,
  merchantEnquiryState,
  registerRefState,
  coffeeZeroVoucherState,
  emailUnsubscribe,
  emailUnsubscribeUpdate,
];