import {put, takeLeading} from 'redux-saga/effects';
import createState from './state';
import loadTokenPublicData from '../../service/api/method/load_token_public_data';
import {dataStatus, transformApiData} from '../transform';
import {
  getApiResponse,
  handleError,
  showLoading,
  hideLoading,
} from '../actions';
import TDataStatus from '../data_status';
import {TNumber, TArray, declareObject} from 'lib/Core/prop_types';

export const TUserSessionTokenPublicData = declareObject({
  status: TDataStatus.isRequired,
  data: declareObject({
    is_user_pin_enabled: TNumber,
  }),
  errors: TArray,
});

export const userSessionTokenPublicDataLoading = 'userSessionTokenPublicData.1';

export default createState(
  'userSessionTokenPublicData',
  {
    status: dataStatus.INIT,
    data: {
      is_user_pin_enabled: 0,
    },
    errors: [],
  },
  ({addSaga, reduce, reduceError, reduceReset}) => {
    addSaga(function* () {
      yield takeLeading('LOAD_USER_SESSION_PUBLIC_DATA', function* (action) {
        try {
          yield put(showLoading(userSessionTokenPublicDataLoading));
          const apiData = yield loadTokenPublicData(action.postData);
          yield put(getApiResponse('LOAD_USER_SESSION_PUBLIC_DATA_R', apiData));
          yield put(hideLoading(userSessionTokenPublicDataLoading));
        } catch (e) {
          yield put(handleError('LOAD_USER_SESSION_PUBLIC_DATA_F', e));
          yield put(hideLoading(userSessionTokenPublicDataLoading));
        }
      });
    });

    reduce('LOAD_USER_SESSION_PUBLIC_DATA_R', function (state, action) {
      let data = Object.assign({}, state);
      const apiData = transformApiData(action.data);
      data.status = apiData.status;
      if (data.status === dataStatus.FAILURE) {
        data.errors = apiData.errors;
      } else if (data.status === dataStatus.SUCCESS) {
        data.data = apiData.data;
      }
      return data;
    });
    reduceError('LOAD_USER_SESSION_PUBLIC_DATA_F');
    reduceReset('LOAD_USER_SESSION_PUBLIC_DATA_RESET');
  }
);

export const userSessionTokenPublicData = {
  load: (postData) => ({type: 'LOAD_USER_SESSION_PUBLIC_DATA', postData}),
  reset: () => ({type: 'LOAD_USER_SESSION_PUBLIC_DATA_RESET'}),
};
