import React from 'react';
import classNames from 'classnames';
import {makeStyles, useTheme} from '@material-ui/styles';
import Fab from '@material-ui/core/Fab';
import useConfig from 'lib/Core/hook/useConfig';
import {TObject} from 'lib/Core/prop_types';
import {TFunction, TBool, TString} from 'lib/Core/prop_types';

const useStyles = makeStyles(theme => ({
  root: {
    right: 10,
    bottom: ({bottomPos}) => bottomPos,
    position: 'fixed',
    boxShadow: theme.design.boxShadow2,
    // backgroundImage: `linear-gradient(${theme.design.default.dark}, ${theme.design.default.light})`,
    zIndex: 1000,
  },
}));

function RightBottom({
  forwardRef,
  className,
  Icon,
  style = {},
  onClick = () => {},
  isBottomNavVisible = true,
}) {
  const theme = useTheme();
  const config = useConfig();
  const {getBottomPosition} = config;
  const bottomPos = isBottomNavVisible ? getBottomPosition(60) : getBottomPosition(10);
  const classes = useStyles({bottomPos});
  // This makes sure the button will NOT change background color once it's clicked.
  const _style = {
    ...{
      backgroundColor: theme.design.default.fab,
      color: '#FFFFFF',
    },
    ...style,
  };

  return (
    <Fab className={classNames(classes.root, className)} onClick={onClick} style={_style} ref={forwardRef}>
      {Icon}
    </Fab>
  );
}

RightBottom.propTypes = {
  style: TObject,
  className: TString,
  onClick: TFunction,
  Icon: TObject,
  isBottomNavVisible: TBool,
};

export default React.forwardRef((props, ref) => (
  <RightBottom {...props} forwardRef={ref}/>
));
