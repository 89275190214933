const auStateType = {
  ACT: 'ACT',
  NSW: 'NSW',
  NT: 'NT',
  QLD: 'QLD',
  SA: 'SA',
  TAS: 'TAS',
  VIC: 'VIC',
  WA: 'WA',
};

const auStateValues = [
  auStateType.ACT,
  auStateType.NSW,
  auStateType.NT,
  auStateType.QLD,
  auStateType.SA,
  auStateType.TAS,
  auStateType.VIC,
  auStateType.WA
];

const auStateOptions = [
  {
    label: 'Australian Capital Territory',
    value: auStateType.ACT,
  },
  {
    label: 'New South Wales',
    value: auStateType.NSW,
  },
  {
    label: 'Northern Territory',
    value: auStateType.NT,
  },
  {
    label: 'Queensland',
    value: auStateType.QLD,
  },
  {
    label: 'South Australia',
    value: auStateType.SA,
  },
  {
    label: 'Tasmania',
    value: auStateType.TAS,
  },
  {
    label: 'Victoria',
    value: auStateType.VIC,
  },
  {
    label: 'Western Australia',
    value: auStateType.WA,
  },
];

export {
  auStateValues,
  auStateOptions,
  auStateType,
}
