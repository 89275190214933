import React from 'react';
import {makeStyles} from '@material-ui/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import useConfig from 'lib/Core/hook/useConfig';
import {TFunction} from 'lib/Core/prop_types';

export const height = 50;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex: 999,
    height: ({getSafeAreaBottomHeight}) => (height + getSafeAreaBottomHeight()),
    border: 'none',
    // backgroundColor: '#FFFFFF',
    backgroundColor: theme.design.background.bottomNav,
  },
}));

function BottomBar({
  children,
  style = {},
  onChange = () => {},
}) {
  const config = useConfig();
  const {getSafeAreaBottomHeight} = config;
  const classes = useStyles({getSafeAreaBottomHeight});

  return (
    <BottomNavigation
      className={classes.root}
      onChange={onChange}
      style={style}
    >
      {children}
    </BottomNavigation>
  );
}

BottomBar.propTypes = {
  onChange: TFunction,
};

export default BottomBar;
