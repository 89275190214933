import {put, takeLeading} from 'redux-saga/effects';
import createState from './state';
import callResendCodeCustomer from '../../service/api/method/resend_code_customer';
import {dataStatus, transformApiData} from '../transform';
import {
  getApiResponse,
  handleError,
  showLoading,
  hideLoading,
} from '../actions';
import TDataStatus from '../data_status';
import {declareObject, TArray} from 'lib/Core/prop_types';

export const TCustomerResendCode = declareObject({
  status: TDataStatus.isRequired,
  errors: TArray,
});

export const customerResendCodeLoading = 'customerResendCode.1';

export default createState(
  'customerResendCode',
  {
    status: dataStatus.INIT,
    errors: [],
  },
  ({addSaga, reduce, reduceError, reduceReset}) => {
    addSaga(function* () {
      yield takeLeading('RESEND_ACCOUNT_ACTIVATION_CODE', function* (action) {
        try {
          yield put(showLoading(customerResendCodeLoading));
          const apiData = yield callResendCodeCustomer();
          yield put(getApiResponse('RESEND_ACCOUNT_ACTIVATION_CODE_R', apiData));
          yield put(hideLoading(customerResendCodeLoading));
        } catch (e) {
          yield put(handleError('RESEND_ACCOUNT_ACTIVATION_CODE_F', e));
          yield put(hideLoading(customerResendCodeLoading));
        }
      });
    });

    reduce('RESEND_ACCOUNT_ACTIVATION_CODE_R', function (state, action) {
      let data = Object.assign({}, state);
      const apiData = transformApiData(action.data);
      data.status = apiData.status;
      if (data.status === dataStatus.FAILURE) {
        data.errors = apiData.errors;
      }
      return data;
    });
    reduceError('RESEND_ACCOUNT_ACTIVATION_CODE_F');
    reduceReset('RESEND_ACCOUNT_ACTIVATION_CODE_RESET');
  }
);

export const customerResendCode = {
  resend: () => ({type: 'RESEND_ACCOUNT_ACTIVATION_CODE'}),
  reset: () => ({type: 'RESEND_ACCOUNT_ACTIVATION_CODE_RESET'}),
};
