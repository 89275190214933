import React from 'react';
import {TNumber} from 'lib/Core/prop_types';
import Standard from './Standard';
import generateSvgDefId from 'logic/utils/generateSvgDefId';

const defId = generateSvgDefId();

const Moon = ({scale, minX = 0, minY = 0}) => {
  return (
    <Standard
      scale={scale}
      viewBox={{
        minX,
        minY,
        width: 200,
        height: 200,
      }}
    >
      <linearGradient id={defId} x1="-193.814" x2="-193.814" y1="536.895" y2="535.803" gradientTransform="matrix(103.8779 59.9739 92.517 -160.2441 -29371.045 97687.29)" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#fbd729"/>
        <stop offset="1" stopColor="#ffefa2"/>
      </linearGradient>
      <path fill={`url(#${defId})`} d="M36 186.4c-8.8-5.1-16.8-11.4-23.7-18.9 10.4 2.4 21.1 3 31.7 2 10.5-1 20.8-3.6 30.5-7.8 9.8-4.2 18.8-9.9 26.8-16.9 8.3-7.3 15.3-15.9 20.8-25.4 5.5-9.5 9.4-19.9 11.6-30.7 2.1-10.4 2.5-21.1 1.2-31.7-1.2-10.5-4.1-20.7-8.5-30.3C122 17 116 8.1 108.8.3c36.6 8.3 65.6 36.2 75.2 72.4 14.5 53.9-17.5 109.3-71.3 123.7-25.8 7.1-53.4 3.5-76.7-10z"/>
    </Standard>
  );
};

Moon.propTypes = {
  scale: TNumber.isRequired,
};

export default Moon;
