import React from 'react';
import {TNumber} from 'lib/Core/prop_types';
import Standard from './Standard';
import generateSvgDefId from 'logic/utils/generateSvgDefId';

const defId = generateSvgDefId();

const StoreSale = ({scale, minX = 0, minY = 0}) => {
  return (
    <Standard
      scale={scale}
      viewBox={{
        minX,
        minY,
        width: 24,
        height: 24,
      }}
    >
      <defs>
        <linearGradient id={defId}>
          <stop offset="5%" stopColor="#9b9b9b" />
          <stop offset="95%" stopColor="#9b9b9b" />
        </linearGradient>
      </defs>
      <path fill={`url(#${defId})`} d='M18.65,2.85L19.26,6.71L22.77,8.5L21,12L22.78,15.5L19.24,17.29L18.63,21.15L14.74,20.54L11.97,23.3L9.19,20.5L5.33,21.14L4.71,17.25L1.22,15.47L3,11.97L1.23,8.5L4.74,6.69L5.35,2.86L9.22,3.5L12,0.69L14.77,3.46L18.65,2.85M9.5,7A1.5,1.5 0 0,0 8,8.5A1.5,1.5 0 0,0 9.5,10A1.5,1.5 0 0,0 11,8.5A1.5,1.5 0 0,0 9.5,7M14.5,14A1.5,1.5 0 0,0 13,15.5A1.5,1.5 0 0,0 14.5,17A1.5,1.5 0 0,0 16,15.5A1.5,1.5 0 0,0 14.5,14M8.41,17L17,8.41L15.59,7L7,15.59L8.41,17Z'
      />
    </Standard>
  );
};

StoreSale.propTypes = {
  scale: TNumber.isRequired,
};

export default StoreSale;
