import React from 'react';
import {TNumber, TObject} from 'lib/Core/prop_types';
import {updateProps} from 'lib/Core/utils/element';
import DeferredRenderer from 'lib/Core/DeferredRenderer';
import CircularBar from '../Progress/CircularBar';

/**
 * @return {*}
 */
function DeferredLoadingContainer({
  forwardRef,
  Progress = (<CircularBar/>),
  deferment = 0,
}) {
  if (deferment <= 0) {
    return updateProps(Progress, {ref: forwardRef});
  } else {
    return (
      <DeferredRenderer timeout={deferment} ref={forwardRef}>
        {Progress}
      </DeferredRenderer>
    );
  }
}

DeferredLoadingContainer.propTypes = {
  Progress: TObject,
  deferment: TNumber,
};

export default React.forwardRef((props, ref) => (
  <DeferredLoadingContainer {...props} forwardRef={ref}/>
));
