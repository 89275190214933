import config from 'lib/Core/config';

import getInnerHeight from 'ios-inner-height';
import {isIos, isIosWeb} from './tanggram';

function createViewport() {
  let windowInnerWidth = null;
  let windowInnerHeight = null;
  let windowSafeInnerHeight = null;
  let safeAreaTopHeight = null;
  let safeAreaBottomHeight = null;
  const cardMaxWidth = 768;

  // Deal with ios.
  let isIosWithNotch = false;
  // iPhone x, xs, xr, xs max.
  if (isIos() && getWindowInnerHeight() >= 812) {
    isIosWithNotch = true;
  }

  let isIosWebWithChin = false;
  if (isIosWeb() && getWindowInnerHeight() >= 812) {
    isIosWebWithChin = true;
  }

  function getWindowInnerWidth() {
    if (windowInnerWidth === null) {
      windowInnerWidth = window.innerWidth;
    }
    return windowInnerWidth;
  }

  function getWindowInnerHeight() {
    if (windowInnerHeight === null) {
      windowInnerHeight = getInnerHeight();
    }
    return windowInnerHeight;
  }

  function getWindowSafeInnerHeight() {
    if (windowSafeInnerHeight === null) {
      windowSafeInnerHeight = getWindowInnerHeight() - getSafeAreaTopHeight() - getSafeAreaBottomHeight();
    }
    return windowSafeInnerHeight;
  }

  function getSafeAreaTopHeight() {
    if (safeAreaTopHeight === null) {
      if (isIos()) {
        if (isIosWithNotch) {
          safeAreaTopHeight = 40;
        } else {
          safeAreaTopHeight = 20;
        }
      } else {
        safeAreaTopHeight = 0;
      }
    }
    return safeAreaTopHeight;
  }

  function getSafeAreaBottomHeight() {
    if (safeAreaBottomHeight === null) {
      if (isIos()) {
        if (isIosWithNotch) { // iPhone x, xs, xr, xs max.
          safeAreaBottomHeight = 20;
        } else {
          safeAreaBottomHeight = 0;
        }
      } else if (isIosWebWithChin) {
        safeAreaBottomHeight = 20;
      } else {
        safeAreaBottomHeight = 0;
      }
    }
    return safeAreaBottomHeight;
  }

  function getTopPosition(offset = 0) {
    return offset + getSafeAreaTopHeight();
  }

  function getBottomPosition(offset = 0) {
    return offset + getSafeAreaBottomHeight();
  }

  function getCardMaxWidth() {
    return cardMaxWidth;
  }

  return {
    cardMaxWidth,
    getWindowInnerWidth,
    getWindowInnerHeight,
    getWindowSafeInnerHeight,
    getSafeAreaTopHeight,
    getSafeAreaBottomHeight,
    getTopPosition,
    getBottomPosition,
    getCardMaxWidth,
  }
}

const others = {
  // topDock: {
  //   dockItemLimit: 4,
  // },
  card: {
    raised: false,
  },
};

export default {...config, ...createViewport(), ...others};