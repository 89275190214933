import React from 'react';
import {makeStyles} from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import {useTheme} from '@material-ui/styles';
import withPageControl from '../HOC/withPageControl';
import BackIcon from 'lib/Icon/Back';
import {smallIcon} from '../Icon';
import useConfig from 'lib/Core/hook/useConfig';
import useTopBarVisibility from 'logic/hook/useTopBarVisibility';

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    top: ({getTopPosition}) => (getTopPosition(0)),
    left: 0,
    height: 72,
    width: 72,
    zIndex: 99,
  },
  rootInv: {
    position: 'fixed',
    top: ({getTopPosition}) => (getTopPosition(0)),
    left: 0,
    height: 72,
    width: 72,
    zIndex: 99,
    display: 'none',
  },
  btn: {
    padding: 9,
  },
});

export const BackButton = withPageControl(({
  pageControl,
  location,
  onClick = () => {
    pageControl.goBack();
  },
  toUri = null,
  style = {},
}) => {
  const config = useConfig();
  const {getTopPosition} = config;
  const classes = useStyles({getTopPosition});

  const isVisibleFromScroll = useTopBarVisibility(true, 38);
  const rootClassName = isVisibleFromScroll ? classes.root : classes.rootInv;

  function goBack() {
    if (toUri) {
      pageControl.toPage(location, toUri);
    } else {
      onClick();
    }
  }

  return (
    <div className={rootClassName} style={style}>
      <IconButton aria-label="Go Back" onClick={goBack} className={classes.btn}>
        {smallIcon(BackIcon)}
      </IconButton>
    </div>
  );
});

export const RewardBackButton = (props) => {
  const theme = useTheme();
  const {style = {}} = props;
  const _style = {
    ...{
      background: `linear-gradient(135deg, ${theme.design.reward.dark} 51px, transparent 0)`,
      // backgroundImage: `linear-gradient(${theme.design.reward.dark}, ${theme.design.reward.light})`,
    },
    ...style,
  };
  return (
    <BackButton {...props} style={_style}/>
  );
};

export const WealthBackButton = (props) => {
  const theme = useTheme();
  const {style = {}} = props;
  const _style = {
    ...{
      background: `linear-gradient(135deg, ${theme.design.wealth.dark} 51px, transparent 0)`,
      // backgroundImage: `linear-gradient(${theme.design.reward.dark} 51px, ${theme.design.wealth.light})`,
    },
    ...style,
  };
  return (
    <BackButton {...props} style={_style}/>
  );
};

export const CenterBackButton = (props) => {
  const theme = useTheme();
  const {style = {}} = props;
  const _style = {
    ...{
      background: `linear-gradient(135deg, ${theme.design.center.dark} 51px, transparent 0)`,
      // backgroundImage: `linear-gradient(${theme.design.center.dark}, ${theme.design.center.light})`,
    },
    ...style,
  };
  return (
    <BackButton {...props} style={_style}/>
  );
};

export const DefaultBackButton = (props) => {
  const theme = useTheme();
  const {style = {}} = props;
  const _style = {
    ...{
      background: `linear-gradient(135deg, ${theme.design.default.dark} 51px, transparent 0)`,
      // backgroundImage: `linear-gradient(${theme.design.default.dark}, ${theme.design.default.light})`,
    },
    ...style,
  };
  return (
    <BackButton {...props} style={_style}/>
  );
};
