import type from './type';

function encode(userIdentity, voucherId) {
  return JSON.stringify({
    t: type.VOUCHER,
    v: {
      userIdentity: userIdentity,
      voucherId: voucherId,
    },
  })
}

function decode(value, onError = (e) => {}) {
  try {
    const json = JSON.parse(value);
    if (json.t === type.VOUCHER) {
      return json.v;
    }
    onError();
  } catch (e) {
    onError(e);
  }
}

export {
  encode,
  decode,
}