import React from 'react';
import {TString} from 'lib/Core/prop_types';
import Base from './Base';

const Warning = (props) => {
  return (
    <Base {...props}>
      <g>
        <path fill="none" d="M0 0h24v24H0z"/>
        <path d="M11 8h2v7.5h-2V8zm1 10.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm11.428 1.571c.096.286.096.667 0 .953-.191.285-.478.476-.861.476H1.505c-.383 0-.67-.19-.861-.476a.862.862 0 0 1 0-.953l10.53-18.098a.746.746 0 0 1 .383-.381c.479-.19 1.053-.095 1.34.381l10.531 18.098zm-20.295-.476h17.806l-8.903-15.24-8.903 15.24z"/>
      </g>
    </Base>
  );
};

Warning.propTypes = {
  color: TString,
};

export default Warning;
