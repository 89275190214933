// The values here have to match with the ones in the api.
export default {
  FOOD: 'food',
  DRINK: 'drink',
  BEAUTY: 'beauty',
  GROCERY: 'grocery',
  FASHION: 'fashion',
  ENTERTAINMENT: 'entertainment',
  EDUCATION: 'education',
  AGENT: 'agent',
  OTHER: 'other',
};
