import React from 'react';
import {makeStyles} from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import PortalList from './PortalList';
import withAcl from 'component/HOC/withAcl';
import Body from 'component/NavLayout/Body';
import NavLayout from 'component/NavLayout';
// import Image from 'component/Image';
// import getS3ImagePath from 'logic/utils/getS3ImagePath';

const useStyles = makeStyles(theme => ({
  rt: {
    position: 'absolute',
    right: -120,
    top: -120,
    animation: 'spin 90s linear infinite',
  },
  tb: {
    marginBottom: 28,
    color: '#FFFFFF',
  },
  row: {
    display: 'flex',
  },
  tc: {
    textAlign: 'center',
    color: '#FFFFFF',
    marginTop: 44,
  },
}));

/**
 * @returns {null}
 */
function WorldGatePage() {
  const classes = useStyles();

  return (
    <NavLayout
      Title={(
        <div>
          <Typography variant='h5' className={classes.tc}>Register Success</Typography>
        </div>
      )}
      // TopRight={(
      //   <div className={classes.rt}>
      //     <Image path={getS3ImagePath('/top_right/coin_earth.svg')} alt={'ball'} width={240}/>
      //   </div>
      // )}
      // enableFullHeight={true}
      // BottomImage={(<FullWidthImage path={getS3ImagePath('/b_img/bg-1.png')} aspectRatio={0.6}/>)}
    >
      <Body>
        <PortalList/>
      </Body>
    </NavLayout>
  );
}

export default withAcl(WorldGatePage);
// export default WorldGatePage;
