import React from 'react';
import {makeStyles} from '@material-ui/styles';
// import Typography from '@material-ui/core/Typography';
import withAcl from 'component/HOC/withAcl';
import Body from 'component/NavLayout/Body';
import TopMenuLayout from 'component/TopMenuLayout';
import {DefaultBackButton} from 'component/BackButton';
import Voucher from './Voucher';
// import AccountSideMenu, {ACCOUNT_MENU_ITEM_ID} from 'component/SideMenu/AccountSideMenu';
// import Image from 'component/Image';
// import getS3ImagePath from 'logic/utils/getS3ImagePath';

const useStyles = makeStyles(theme => ({
  rt: {
    position: 'absolute',
    right: -120,
    top: -120,
    animation: 'spin 90s linear infinite',
  },
  tb: {
    marginBottom: 28,
    color: '#FFFFFF',
  },
  row: {
    display: 'flex',
  },
  tc: {
    textAlign: 'center',
    color: '#FFFFFF',
    marginTop: 44,
  },
}));

/**
 * @returns {null}
 */
function VoucherVoucherPage() {
  const classes = useStyles();

  return (
    <TopMenuLayout
      title={'Free Coffee Voucher'}
      BackButton={(<DefaultBackButton/>)}
      // SideMenu={(<AccountSideMenu activeItem={ACCOUNT_MENU_ITEM_ID.HOME}/>)}
    >
      <Body>
        <Voucher/>
      </Body>
    </TopMenuLayout>
  );
}

export default withAcl(VoucherVoucherPage);
