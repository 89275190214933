import {createSession, destroySessionSync, saveSessionLocally, clearSessionLocally, loadSessionLocally} from './session';
import {createSessionToken, saveSessionTokenLocally, clearSessionTokenLocally, loadSessionTokenLocally, getSessionTokenSync} from './session_token';
import {_logging_} from 'logger';
import getEnv, {isIosWeb, isStandalone} from 'tanggram';

export {role, isCustomer, isMerchant, isAdmin} from './role';

const {_logger_} = _logging_('createAuth');

// Create the auth instance which is used on the client side.
function createAuth({
  isSessionPersistenceEnabled = false,
}) {
  let _session = null;
  if (isSessionPersistenceEnabled) {
    _session = loadSessionLocally();
  } else {
    clearSessionLocally();
    _session = createSession({});
  }
  let _sessionToken = loadSessionTokenLocally();
  let _isLoggedIn = false;
  const {_log_} = _logger_();

  function updateSession(session) {
    _session = session;
  }

  function getSession() {
    return _session;
  }

  function updateSessionToken(sessionToken) {
    _sessionToken = sessionToken;
  }

  function getSessionToken() {
    return _sessionToken;
  }

  function persistSessionToken() {
    saveSessionTokenLocally(getSessionToken());
  }

  function persistSession() {
    saveSessionLocally(getSession());
  }

  function loginLocally({sessionId, force = false, tokenId = null,}) {
    _log_('localLogin')('Try to login locally');
    if (!_isLoggedIn || force) {
      const _sessionLog_ = _log_('localLogin');
      _sessionLog_('Login locally.');
      // Update the flag. This is to avoid duplicated login.
      _isLoggedIn = true;

      // Update the session when there is a new one.
      if (sessionId !== getSession().getId()) {
        updateSession(createSession({id: sessionId}));
        if (isSessionPersistenceEnabled) {
          // Persist the session locally if required.
          persistSession();
        }
        if (tokenId) {
          updateSessionToken(createSessionToken({id: tokenId}));
          persistSessionToken();
        } else {
          // Always update the session token when the session id is updated.
          getSessionTokenSync().then(
            (value) => {
              _sessionLog_('got token id');
              _sessionLog_(value);
              updateSessionToken(value);
              persistSessionToken();
            },
            (error) => {
              /**
               * @todo Handle the rejection.
               */
              _sessionLog_('got token id error');
              _sessionLog_(error);
            },
          );
        }
      }
    } else {
      _log_('localLogin')('Duplicated login attempts.');
    }
  }

  function logoutLocally() {
    // Set the flag.
    _isLoggedIn = false;
    // Reset the session.
    _session = createSession({});
    // Reset session token.
    _sessionToken = createSessionToken({});

    if (isSessionPersistenceEnabled) {
      // Clear the local session if it's enabled.
      clearSessionLocally();
    }
    // Remove the session token from the local storage (cookie, localStorage, ...).
    // Once logout, the user has to use username/password to login again. Pin WILL NOT WORK.
    clearSessionTokenLocally();
  }

  async function logoutSync() {
    logoutLocally();
    await destroySessionSync();
  }

  return {
    updateSession,
    getSession,
    updateSessionToken,
    getSessionToken,
    persistSessionToken,
    loginLocally,
    logoutLocally,
    logoutSync,
  }
}

let auth = null;

if (isIosWeb() && isStandalone()) {
  // IOS PWA does not preserve Redux state between sessions (foregroud -> background). Session is persisted locally.
  auth = createAuth({isSessionPersistenceEnabled: true});
} else {
  auth = createAuth({isSessionPersistenceEnabled: getEnv().isSessionPersistenceEnabled});
}
export default auth;
