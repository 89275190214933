import React from 'react';
import {makeStyles, useTheme} from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import {declareArrayValue, TNumber, declareObject, TString} from 'lib/Core/prop_types';
import CloseIcon from 'lib/Icon/Close';
import CompleteIcon from 'lib/Icon/Complete';
import {miniIcon} from '../Icon';

const useStyles = makeStyles(theme => ({
  body: {
    marginTop: 5,
  },
  corr: {
    display: 'flex',
    alignItems: 'center',
    color: 'green',
    fontSize: 12,
  },
  err: {
    display: 'flex',
    alignItems: 'center',
    color : theme.status.danger,
    fontSize: 12,
  },
}));

function AdvancedInputValidationHelperText({constraints, violations}) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.body}>
      {constraints.map((constraint, index)=> {
        if (violations.includes(constraint.k)) {
          return (
            <Typography variant='body2' className={classes.err} key={`validate_${index}`}>
              {miniIcon(CloseIcon, theme.status.danger)}&nbsp;{constraint.v}
            </Typography>
          );
        } else {
          return (
            <Typography variant='body2' className={classes.corr} key={`validate_${index}`}>
              {miniIcon(CompleteIcon, theme.status.success)}&nbsp;{constraint.v}
            </Typography>
          );
        }
      })}
    </div>
  );
}

const TConstraints = declareArrayValue(
  declareObject({
    k: TNumber,
    v: TString,
  })
);

const TViolations = declareArrayValue(
  TNumber,
);

AdvancedInputValidationHelperText.propTypes = {
  constraints: TConstraints,
  violations: TViolations,
};

export default AdvancedInputValidationHelperText;
