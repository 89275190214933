import registry from './registry';
import transitions from '../const/transition';

const regKey = '_SCREEN_PAGE_TRANSITION';

// Save the transition.
export function saveTransition(trans) {
  registry.register(regKey, trans);
}

// Load the transition.
export function loadTransition() {
  const trans = registry.get(regKey, null);
  if (trans) {
    return parseInt(trans, 10);
  }
  return transitions.SLIDE_TO_RIGHT;
}
