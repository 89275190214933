import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {TObject} from 'lib/Core/prop_types';
import {getSafeAreaTopHeight, getWindowInnerWidth} from 'viewport';

const useStyles = makeStyles({
  root: {
    top: 0,
    width: getWindowInnerWidth(),
    backgroundColor: '#ffffff',
    // backgroundColor: 'rgb(161, 221, 60)',
    display: 'block',
    height: getSafeAreaTopHeight(),
  },
  fixed: {
    position: 'fixed',
    top: 0,
    zIndex: 999999,
    width: getWindowInnerWidth(),
    backgroundColor: '#ffffff',
    // backgroundColor: 'rgb(161, 221, 60)',
    display: 'block',
    height: getSafeAreaTopHeight(),
  },
});

/**
 * @returns {*}
 */
function TopSafeBar({
  style = {},
}) {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.root} style={style}/>
      <div className={classes.fixed} style={style}/>
    </div>
  );
}

TopSafeBar.propTypes = {
  style: TObject,
};

export default TopSafeBar;