import Cookies from 'universal-cookie';
import getEnv from 'tanggram';

const conf = getEnv();
const cookies = new Cookies();
const localStorage = window.localStorage;
const useCookie = (conf.localStorage.type === 'cookie');

function toLocalKey(name) {
  return `__gen__${name}`;
}

export function saveInLocal(name, value, options = {}) {
  if (useCookie) {
    cookies.set(name, value, {...conf.localStorage.config, ...options});
  } else {
    localStorage.setItem(toLocalKey(name), value);
  }
}

export function loadFromLocal(name) {
  if (useCookie) {
    return cookies.get(name);
  } else {
    return localStorage.getItem(toLocalKey(name));
  }
}

export function removeFromLocal(name, options = {}) {
  if (useCookie) {
    cookies.remove(name, {...conf.localStorage.config, ...options});
  } else {
    localStorage.removeItem(toLocalKey(name));
  }
}
