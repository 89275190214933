import React from 'react';
import {makeStyles} from '@material-ui/styles';
import classNames from 'classnames';
import CardBase from 'lib/Core/CardBase';
import useConfig from 'lib/Core/hook/useConfig';
import {TBool, TNode, TNumber, TObject, TString} from 'lib/Core/prop_types';
import transitions from 'logic/const/transition';
import ShowCard from 'lib/Core/Animation/ShowCard';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minWidth: 100,
    maxWidth: ({config}) => (config.cardMaxWidth),
    marginBottom: 15,
    borderRadius: 10,
    boxShadow: ({raised, config}) => (raised && config.card.raised ? theme.design.boxShadow : 'none'),
    // marginLeft: 2,
    // marginRight: 2,
  },
}));

function PlainCard({
  children,
  className = null,
  style = {},
  raised = true,
  enableTransition = true,
  transitionDelay = 0,
  transition = transitions.SLIDE_TO_RIGHT,
  forwardRef,
}) {
  const config = useConfig();
  const classes = useStyles({raised, config});

  const _Card = (
    <CardBase className={classNames(classes.root, className)} style={style} ref={forwardRef}>
      {children}
    </CardBase>
  );

  if (enableTransition) {
    if (transition === transitions.SLIDE_TO_LEFT) {
      return (
        <ShowCard direction='left' delay={transitionDelay}>
          {_Card}
        </ShowCard>
      )
    } else {
      return (
        <ShowCard direction='right' delay={transitionDelay}>
          {_Card}
        </ShowCard>
      )
    }
  }
  return _Card;
}

PlainCard.propTypes = {
  children: TNode,
  className: TString,
  style: TObject,
  raised: TBool,
  enableTransition: TBool,
  transitionDelay: TNumber,
  transition: TNumber,
  // transitionOptions: TObject,
};

export default React.forwardRef((props, ref) => (
  <PlainCard {...props} forwardRef={ref}/>
));
