import React from 'react';
import {useTheme} from '@material-ui/styles';
import Drawer from './Drawer';
import {URI} from 'page/Layout/uri';
import MenuItem from './MenuItem';
import {
  Person as PersonIcon,
  Group as GroupIcon,
  CreditCard as CreditCardIcon,
  // Help as HelpIcon,
} from '@material-ui/icons';
import AccountBlock from './AccountBlock';

export const DEFAULT_MENU_ITEM_ID = {
  PROFILE: 1,
  FRIEND: 2,
  WALLET: 3,
  // HELP: 4,
};

function DefaultSideMenu({
  style,
  location,
  onClick,
  activeItem,
}) {
  const theme = useTheme();

  const highlightColor = theme.design.default.dark;
  const defaultColor = '#FFFFFF';

  return (
    <Drawer
      themeColorDark={highlightColor}
      themeColorLight={theme.design.default.light}
      renderTop={(redirectToPage) => {
        return (
          <AccountBlock onClick={redirectToPage}/>
        );
      }}
      renderMenuList={(onMenuItemClick) => {
        return (
          <div>
            <MenuItem
              id={DEFAULT_MENU_ITEM_ID.PROFILE}
              onClick={onMenuItemClick(URI.ACCOUNT)}
              label={'Profile'}
              highlight={(activeItem === DEFAULT_MENU_ITEM_ID.PROFILE)}
              highlightColor={highlightColor}
              color={defaultColor}
              Icon={(<PersonIcon nativeColor={activeItem === DEFAULT_MENU_ITEM_ID.PROFILE ? highlightColor : defaultColor}/>)}
            />
            <MenuItem
              id={DEFAULT_MENU_ITEM_ID.FRIEND}
              onClick={onMenuItemClick(URI.FRIEND_LIST)}
              label={'Friends'}
              highlight={(activeItem === DEFAULT_MENU_ITEM_ID.FRIEND)}
              highlightColor={highlightColor}
              color={defaultColor}
              Icon={(<GroupIcon nativeColor={activeItem === DEFAULT_MENU_ITEM_ID.FRIEND ? highlightColor : defaultColor}/>)}
            />
            <MenuItem
              id={DEFAULT_MENU_ITEM_ID.WALLET}
              onClick={onMenuItemClick(URI.CUSTOMER_WALLET_HOME)}
              label={'Wallet'}
              highlight={(activeItem === DEFAULT_MENU_ITEM_ID.WALLET)}
              highlightColor={highlightColor}
              color={defaultColor}
              Icon={(<CreditCardIcon nativeColor={activeItem === DEFAULT_MENU_ITEM_ID.WALLET ? highlightColor : defaultColor}/>)}
            />
            {/*<MenuItem*/}
              {/*id={DEFAULT_MENU_ITEM_ID.HELP}*/}
              {/*onClick={onMenuItemClick(URI.CUSTOMER_TUTORIAL_WEALTH)}*/}
              {/*label={'Help'}*/}
              {/*highlight={(activeItem === DEFAULT_MENU_ITEM_ID.HELP)}*/}
              {/*highlightColor={highlightColor}*/}
              {/*color={defaultColor}*/}
              {/*Icon={(<HelpIcon nativeColor={activeItem === DEFAULT_MENU_ITEM_ID.HELP ? highlightColor : defaultColor}/>)}*/}
            {/*/>*/}
          </div>
        )
      }}
    />
  );
}

DefaultSideMenu.propTypes = {
};

export default DefaultSideMenu;
