import React, {useState} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/styles';
import useConfig from 'lib/Core/hook/useConfig';
import withPageControl from '../HOC/withPageControl';
import ConnectionError from '../ConnectionError';
import {TBool, TFunction, TNode, TObject} from 'lib/Core/prop_types';
import {TUserSession, TDayNightMode} from 'logic/redux/type';
import SafeArea from '../SafeArea';
import timeOfDay from 'logic/utils/timeOfDay';
import MaintenanceMode from '../MaintenanceMode';
// import ChangePage from 'lib/Core/Animation/ChangePage';
// import SunAnimation from './SunAnimation';
// import MoonAnimation from './MoonAnimation';

export {default as Body, FullSizeBody} from './Body';

const heightOffset = 110;
const bottomNavHeight = 50;
const useStyles = makeStyles(theme => ({
  '@keyframes topRightIn': {
    '0%': {
      opacity: 0.3,
      transform: 'translate(120px, -120px)',
    },
    '25%': {
      opacity: 0.5,
      transform: 'translate(50px, -50px)',
    },
    '75%': {
      opacity: 0.8,
      transform: 'translate(-20px, 20px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translate(0, 0)',
    },
  },
  root: {
    // minHeight: '100vh',
    minHeight: ({config}) => (config.getWindowSafeInnerHeight() + config.getSafeAreaBottomHeight()),
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    paddingBottom: ({noBottomNav}) => (noBottomNav ? 0 : bottomNavHeight),
    backgroundColor: theme.design.background.light,
  },
  inner: {
    zIndex: 1,
    marginTop: ({disableHeader}) => (disableHeader ? 0 : -heightOffset + 5),
    marginLeft: 8,
    marginRight: 8,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  tr: {
    position: 'absolute',
    top: 12,
    right: 12,
    color: '#9B9B9B',
    zIndex: 1,
    opacity: 0,
    animation: '$topRightIn 0.3s forwards linear',
    animationDelay: '100ms',
  },
  header: {
    paddingLeft: 12,
    zIndex: 1,
    // paddingTop: 83,
    minHeight: 190,
    // backgroundColor: theme.design.background.light,
    backgroundImage: ({isDay}) => (
      isDay
        ? `linear-gradient(135deg, ${theme.design.layoutHeader.day.dark}, ${theme.design.layoutHeader.day.light})`
        : `linear-gradient(135deg, ${theme.design.layoutHeader.day.dark}, ${theme.design.layoutHeader.day.light})`
    ),
    overflow: 'hidden',
    position: 'relative',
    // borderBottomLeftRadius: 25,
    // borderBottomRightRadius: 25,
  },
  triBg: {
    left: -500,
    bottom: -320,
    position: 'absolute',
    backgroundColor: theme.design.background.light,
    width: ({windowWidth}) => (windowWidth + 600),
    height: 350,
    transform: 'rotate(170deg)',
  },
  innerTWrapper: {
    marginLeft: 2,
    marginRight: 2,
    // marginBottom: 5,
    display: 'flex',
  },
}));

function NavLayout({
  children,
  style,
  dayNightMode,
  BottomImage,
  TopMenu = null,
  BackButton = null,
  TopRight = null,
  BottomNav = null,
  Title = null,
  enableFullHeight = false,
  disableHeader = false,
  disableDayNightAnimation = false,
  innerStyle = {},
  headerStyle = {},
  renderHelpCenter = () => {
    return null;
  },
  SideMenu = null,
}) {
  const config = useConfig();
  const noBottomNav = !BottomNav;
  const windowWidth = config.getWindowInnerWidth();
  const halfWindowWidth = windowWidth / 2;
  const {isMorning, isAfternoon} = timeOfDay();
  const isAutoDay = isMorning() || isAfternoon();
  const isDay = dayNightMode.isEnabled ? dayNightMode.isDay : isAutoDay;
  const classes = useStyles({config, isDay, disableHeader, noBottomNav, windowWidth, halfWindowWidth});

  const [isHelpCenterVisible, setIsHelpCenterVisible] = useState(false);

  function hideHelpCenter() {
    setIsHelpCenterVisible(false);
  }

  return (
    <SafeArea>
      <div className={classes.root} style={style}>
        {SideMenu}
        {!disableHeader && (
          <>
            <div className={classes.header} style={headerStyle}>
              {Title}
            </div>
          </>
        )}
        {BackButton}
        {TopRight && (
          <div className={classes.tr}>
            {TopRight}
          </div>
        )}
        <div className={classes.inner} style={innerStyle}>
          {disableHeader && (
            <div className={classes.innerTWrapper}>{Title}</div>
          )}
          {TopMenu}
          <ConnectionError/>
          <MaintenanceMode/>
          {children}
          {renderHelpCenter(isHelpCenterVisible, hideHelpCenter)}
        </div>
        {enableFullHeight && (
          <div style={{display: 'flex', flexGrow: 1, height: '100%'}}/>
        )}
        {BottomImage}
        {BottomNav}
      </div>
    </SafeArea>
  );
}

NavLayout.propTypes = {
  userSession: TUserSession.isRequired,
  enableFullHeight: TBool,
  BottomImage: TNode,
  TopMenu: TNode,
  TopRight: TNode,
  BottomNav: TNode,
  disableHeader: TBool,
  BackButton: TNode,
  innerStyle: TObject,
  headerStyle: TObject,
  renderHelpCenter: TFunction,
  dayNightMode: TDayNightMode,
};

function mapStateToProps(state) {
  return {
    userSession: state.userSession,
    dayNightMode: state.dayNightMode,
  }
}

export default connect(mapStateToProps)(withPageControl(NavLayout));
