import React from 'react';
import {TString, TNumber, TObject} from 'lib/Core/prop_types';
import getImagePath from 'logic/utils/getImagePath';
import getS3ImagePath from 'logic/utils/getS3ImagePath';

function ImagePlaceholder({
  width,
  height,
  aspectRatio = 1,
  style = {},
  path = getS3ImagePath('/no_image.png'),
  imageSize = null,
}) {
  const _height = height ? height : Math.round(width * aspectRatio);
  const size = imageSize ? imageSize : Math.max(Math.min(width, _height) - 64, Math.min(width, _height, 48));
  return (
    <div
      style={{...{width, height: _height, display: 'flex', justifyContent: 'center', alignItems: 'center'}, ...style}}
    >
      <img src={getImagePath(path)} width={size} height={size} alt={'X'}/>
    </div>
  );
}

ImagePlaceholder.propTypes = {
  path: TString,
  style: TObject,
  width: TNumber.isRequired,
  aspectRatio: TNumber,
};

export default ImagePlaceholder;
