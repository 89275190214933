import React from 'react';
import {TObject} from 'lib/Core/prop_types';
import {getSafeAreaTopHeight} from 'viewport';
import TopSafeBar from './TopSafeBar';

/**
 * @returns {*}
 */
function SafeArea({
  children,
  TopSafeBarProps = {},
}) {
  const safeAreaTopHeight = getSafeAreaTopHeight();
  if (safeAreaTopHeight > 0) {
    return (
      <div>
        <TopSafeBar {...TopSafeBarProps}/>
        {children}
      </div>
    );
  }
  return children;
}

SafeArea.propTypes = {
  TopSafeBarProps: TObject,
};

export default SafeArea;