import React from 'react';
import {makeStyles, useTheme} from '@material-ui/styles';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import {TNumber} from 'lib/Core/prop_types';
import transitions from 'logic/const/transition';
import MessageBar from './index';

const useStyles = makeStyles(theme => ({
  text: {
    color: theme.status.success,
  }
}));

function SuccessMessageBar({
  className = null,
  children,
  style = {},
  textStyle = {},
  direction = transitions.SLIDE_TO_RIGHT,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const _style = Object.assign({}, {backgroundColor: `${theme.status.success}33`}, style);

  function renderMessage() {
    return (
      <div className={className}>
        <MessageBar className={classes.root} style={_style}>
          <Typography variant='body2' className={classes.text} style={textStyle}>{children}</Typography>
        </MessageBar>
      </div>
    );
  }

  if (direction === transitions.SLIDE_TO_RIGHT) {
    return (
      <Slide direction='right' in={true} timeout={{enter: 300,exit: 300}}>
        {renderMessage()}
      </Slide>
    );
  } else {
    return (
      <Slide direction='left' in={true} timeout={{enter: 300,exit: 300}}>
        {renderMessage()}
      </Slide>
    );
  }
}

SuccessMessageBar.propTypes = {
  direction: TNumber,
};

export default SuccessMessageBar;
