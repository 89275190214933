import {put, takeLeading} from 'redux-saga/effects';
import createState from './state';
import {
  handleError,
} from '../actions';
import {TArray, TBool, TNumber, declareObject} from 'lib/Core/prop_types';
import {saveState} from '../persistence';

export const TTopDock = declareObject({
  isVisible: TBool,
  dockItemIds: TArray,
  newDockItemId: TNumber,
  dockItemIdToRemove: TNumber,
});

export default createState(
  'topDock',
  {
    isVisible: true,
    dockItemIds: [],
    newDockItemId: null,
  },
  ({addSaga, reduce, reduceError, reduceReset}) => {
    addSaga(function* () {
      yield takeLeading('TOP_DOCK_ADD_ITEM', function* (action) {
        try {
          yield put({
            type: 'TOP_DOCK_ADD_ITEM_R',
            dockItemId: action.dockItemId,
            dockItemLimit: action.dockItemLimit,
          });
        } catch (e) {
          yield put(handleError('TOP_DOCK_ADD_ITEM_F', e));
        }
      });
    });

    reduce('TOP_DOCK_ADD_ITEM_R', function (state, action) {
      let data = Object.assign({}, state);
      // console.log(action);
      const dockItemIdToAdd = action.dockItemId;
      if (!dockItemIdToAdd) {
        return data;
      }

      // Reset docket item ids.
      data.newDockItemId = null;
      data.dockItemIdToRemove = null;

      // If the dock item id is new to the dock, then change the dock.
      const limit = action.dockItemLimit;
      if (!data.dockItemIds.includes(dockItemIdToAdd)) {
        // The dock is full. Remove the oldest one.
        if (data.dockItemIds.length >= limit) {
          data.dockItemIdToRemove = data.dockItemIds.shift();
        }
        // Add the new dock item id.
        data.newDockItemId = dockItemIdToAdd;
        data.dockItemIds.push(dockItemIdToAdd);
      }
      if (!Object.is(state, data)) {
        saveState('topDock', data);
      }
      return data;
    });

    reduceError('TOP_DOCK_ADD_ITEM_F');
    reduceReset('TOP_DOCK_ADD_ITEM_RESET');
  }
);

export const topDock = {
  addDockItemId: (dockItemId, dockItemLimit) => ({type: 'TOP_DOCK_ADD_ITEM', dockItemId, dockItemLimit}),
  reset: () => ({type: 'TOP_DOCK_ADD_ITEM_RESET'}),
};
