import React from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {TObject, TFunction, declareObject} from 'lib/Core/prop_types';
import {TUserSession} from 'logic/redux/state/user_session';
import transitions from 'logic/const/transition';
import {saveTransition} from 'logic/service/page_transition';

// Page control.
function createPageControl(history) {
  const toPage = (location, uri, trans = transitions.SLIDE_TO_RIGHT) => {
    saveTransition(trans);
    if (uri === location.pathname) {
      history.replace(uri);
    } else {
      history.push(uri);
    }
  };

  const goBack = (trans = transitions.SLIDE_TO_RIGHT) => {
    saveTransition(trans);
    history.goBack();
  };

  return {
    toPage,
    goBack,
  }
}

const TPageControl = declareObject({
  toPage: TFunction.isRequired,
  goBack: TFunction.isRequired,
});

function withPageControl(Component) {
  class WithPageControl extends React.Component {
    render() {
      const pageControl = createPageControl(this.props.history);
      // Add in pageControl object.
      let props = Object.assign({}, this.props, {pageControl});
      return (
        <Component {...props} />
      );
    }
  }

  WithPageControl.propTypes = {
    history: TObject.isRequired,
    userSession: TUserSession.isRequired,
  };

  function mapStateToProps(state) {
    return {
      userSession: state.userSession,
    }
  }

  return connect(mapStateToProps)(withRouter(WithPageControl));
}

export default withPageControl;

export {
  TPageControl
};
