export default function() {
  const hours = (new Date()).getHours();

  function isMorning() {
    return hours >= 7 && hours <= 12;
  }

  function isAfternoon() {
    return hours >= 13 && hours <= 16;
  }

  function isEvening() {
    return hours >= 17 && hours <= 21;
  }

  function isBedTime() {
    return (hours >= 22 && hours <= 23) || (hours >= 0 && hours <= 6);
  }

  return {
    hours,
    isMorning,
    isAfternoon,
    isEvening,
    isBedTime,
  };
}
