import env from './env';
export {isIOS as isIosWeb, isAndroid as isAndroidWeb, isStandalone, isChrome, isSafari} from 'logic/utils/platform';

export default function getEnv() {
  return env;
}

export function isIos() {
  return env.platform === 'ios';
}

export function isAndroid() {
  return env.platform === 'android';
}

export function isWeb() {
  return env.platform === 'web';
}

export function isDevEnvironment() {
  return env.env === 'dev';
}

export function isDebugMode() {
  return env.debug;
}

export function isVerbose() {
  return env.verbose;
}
